import { GET_DOCUMENT_OVERVIEW_DATA } from "common/constants/data";
import { getDocumentOverviewDataQueries } from "./queries";
import { getData } from "functions/fetchApi";
import moment from 'moment';

const mapFetchResults = (results) => {    
  const data = [];
  for (const result of results) {
    const row = {
    id:result.id,
    file: result.file.substring(
      result.file.lastIndexOf("/") + 1
    ),
    company_name:result.company_name,
    entity__name: result.entity__name,
    document_species__value: result.document_species__value,
    upload_by: result.upload_by,
    status__value: result.status__value,
    ts_upload: moment(result.ts_upload).format("DD/MM/YYYY HH:mm:ss"),
    ts_change: moment(result.ts_change).format("DD/MM/YYYY HH:mm:ss"),
    company_id:result.company_id
    }
    data.push(row);
  }
  return data;
};

export const loaddocumentoverviewdata = (filters) => async (dispatch) => {
  let queries = getDocumentOverviewDataQueries(filters);
  await getData(queries).then((resultsets) => {
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          let documentOverviewArray = mapFetchResults(resultset.result)          
          dispatch({
            type: GET_DOCUMENT_OVERVIEW_DATA,
            payload: { documentOverviewArray },
          });
        }
      }
    }
  });
};
