const validateUserForm = (values) => {
  const errors = {};

  
  const emailRexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  if (!values.email) {
    errors.email = 'Campo obligatorio';
  } else if (!emailRexp.test(values.email)) {
    errors.email = 'La dirección de correo no tiene formato correcto';
  }
  
  return errors;
};

export default validateUserForm;
