import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const FileDrop = props => {  
  const { className, handleupload } = props;  
  const rest = {};
  for (const key of Object.keys(props)) {
    if (!['className'].includes(key)) {
      rest[key] = props[key];
    }
  }

  function handleOpen(e) {
    handleupload(e)
  
}
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body1">
              Subir Documentos
            </Typography>
          </Grid>
        </Grid>
        <DropzoneArea 
          acceptedFiles={props.acceptedFiles}
          clearOnUnmount
          dropzoneText={'Arrastra y suelta los documentos aquí o haz click'}
          filesLimit={30}
          maxFileSize={100000000}
          multiple    
          showAlerts={false}    
          onDrop={handleOpen.bind(this)}
          inputProps={{'data-testid':'drop_zone-input'}}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input  {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                
              </div>
            </section>
          )}
        </DropzoneArea>
      </CardContent>
    </Card>
  );
};

FileDrop.propTypes = {
  acceptedFiles: PropTypes.array,
  className: PropTypes.string,
  handleupload: PropTypes.func.isRequired
};

export default FileDrop;
