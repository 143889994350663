import { CHANGE_ADDITIONAL_DOCUMENT_DESCRIPTION } from "common/constants/components";

import { loadState, saveState } from "functions/stateHelper";
import { STATE_KEY_ADDITIONAL_DOC } from "common/constants/states";

const init = {
  additionalDocumentDescription: null,
  checkSelectedCategories: false,
};

let initialState = loadState(STATE_KEY_ADDITIONAL_DOC, init);

const checkSelectedCategories = (state) => {
  return state.additionalDocumentDescription !== null;
};

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case CHANGE_ADDITIONAL_DOCUMENT_DESCRIPTION:
      updatedState = {
        ...state,
        additionalDocumentDescription: action.payload.text,
      };
      break;
    default:
      return state;
  }

  Object.assign(updatedState, {
    checkSelectedCategories: checkSelectedCategories(updatedState),
  });

  saveState(updatedState, STATE_KEY_ADDITIONAL_DOC);
  return updatedState;
}
