import React from 'react';
import {Card, CardContent, Grid, Box, CardHeader } from "@material-ui/core";
import { BarChartHor } from 'components/Charts';

const LitrosYear = ({datasets, labels}) => {

    return (
        <Box style = {{height: '100%'}}>
            <Card elevation={12} alignContent="center" style={{height: "inherit"}}>
                <CardHeader title={"Evolución anual de litros declarados"} />
                <CardContent style={{marginTop: -20}}>
                    <Grid container spacing={1}>
                        <Grid item xs={11}>
                            <BarChartHor
                                datasets={datasets}
                                labels={labels}
                                type = { 'bar' }
                                title="Documentos Subidos a la plataforma"
                                colors={['#009677', '#ff9338', '#f95951', '#ff0093', "#0089af", "#008772", "#69c2b0", "#69c2b0", "#69c2b0"]}
                                timeXAxe={{
                                unit: 'year',
                                displayFormats: {
                                    year: 'YYYY',
                                },
                                }}
                            />
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </Card>
        </Box>
	);
};

export default LitrosYear;