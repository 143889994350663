import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
    textAlign:"center",
    //width: '100%',
    height: '24px',
    margin: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute', //Here is the trick
    //bottom: 40, //Here is the trick
  }
}));

const Footer = props => {
  const { className,} = props;
  const rest = {};
  for (const key of Object.keys(props)) {
    if (!['className'].includes(key)) {
      rest[key] = props[key];
    }
  }

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1" >
        &copy;{' '} afectadosporlaspetroleras.es, 2020
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
