import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import logoutIcon from '@iconify/icons-mdi/logout';
import { Icon } from '@iconify/react';
import {
  Grid,
  AppBar,
  Toolbar,
  Button,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Link,
} from '@material-ui/core';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import MenuIcon from '@material-ui/icons/Menu';
import CachedIcon from '@material-ui/icons/Cached';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import BarChartIcon from '@material-ui/icons/BarChart';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useLocation } from 'react-router-dom';
import ArrowTooltip from 'components/Tooltip';
import { logout } from 'common/actions/auth';
import { showhelp, hidehelp } from 'common/actions/components';
import {
  loadcompanyarray,
  loadcompanytypearray,
  loaddocumentaggregateddata,
  loaddocumentformatarray,
  loaddocumentoverviewdata,
  loaddocumentspeciesarray,
  loadentityarray,
  loadidtypearray,
  loadinvoicedetailsdata,
  loadfunnelabbyy,
  loadinvoicetypearray,
  loadliterdetailsdata,
  loadsupplierarray,
  downloaddemovideo,
} from 'common/actions/data';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  helpButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  helpButtonToggled: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  alignItemsAndJustifyContent: {
    width: 610,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Roboto',
  },
}));

function Topbar(props) {
  const [anchorPositionSettings, setAnchorPositionSettings] = React.useState(null);
  const [anchorPositionHelp, setAnchorPositionHelp] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const headerTitles = new Map([
    ['/documentspage', 'Documentos subidos'],
    ['/landingpage', 'Página principal'],
    ['/informationpage', 'Gestión de cuenta de usuario'],
    ['/terms', 'Términos y Condiciones'],
    ['/faqpage', 'Preguntas Frecuentes'],
    ['/reportpage', 'Informes'],
    ['/companyspage', 'Gestión de clientes'],
  ]);

  let location = useLocation();
  const { className } = props;
  const rest = {};
  for (const key of Object.keys(props)) {
    if (!['className', 'onSidebarOpen'].includes(key)) {
      rest[key] = props[key];
    }
  }

  const handleClickOpen = async () => {
    if (props.demovideo !== typeof (Blob)) {
      props.downloaddemovideo();
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    props.logout();
  };
  const handleToggleHelp = () => {
    if (props.ishelpdisplayed) {
      props.hidehelp();
    } else {
      props.showhelp();
    }
  };
  const renderuserinfo = () => {
    if (props.userinfo) {
      return props.userinfo.email;
    }
    return 'Unknown';
  };
  const handleSettingsMenuOpen = (event) => {
    setAnchorPositionSettings({
      top: event.currentTarget.offsetTop + 50,
      left: event.currentTarget.offsetLeft + 30,
    });
  };

  const handleSettingsMenuClose = () => {
    setAnchorPositionSettings(null);
  };

  const handleHelpMenuOpen = (event) => {
    setAnchorPositionHelp({
      top: event.currentTarget.offsetTop + 50,
      left: event.currentTarget.offsetLeft + 30,
    });
  };

  const handleHelpMenuClose = () => {
    setAnchorPositionHelp(null);
  };

  const handleReload = () => {
    props.loadcompanytypearray();
    props.loadidtypearray();
    props.loadentityarray();
    props.loadcompanyarray();
    props.loadinvoicetypearray();
    props.loadsupplierarray();
    props.loaddocumentformatarray();
    props.loaddocumentspeciesarray();
    var filters={};
    filters.delete_flag= false; 
    props.loaddocumentoverviewdata(filters);
    props.loaddocumentaggregateddata(filters);
    props.loadinvoicedetailsdata();
    props.loadfunnelabbyy();
    
    props.loadliterdetailsdata();
  };

  const Header = () => {
    if (props.auth && props.auth.isAuthenticated && props.auth.isloggedIn) {
      return (
        <Toolbar id="testss">
           <ArrowTooltip
            id="home"
            title="Home"
            placement="top"
          >
            <Button id="btn_home" variant="text" color="inherit" href="/">
             <HomeIcon fontSize="default" />  
            </Button>
          </ArrowTooltip>
           
         <ArrowTooltip title="Menu" placement="top">
            <Button
              id="btn_help"
              className={
                props.ishelpdisplayed == false
                  ? classes.helpButton
                  : classes.helpButtonToggled
              }
              variant="text"
              color="inherit"
              onClick={handleHelpMenuOpen}//handleToggleHelp}
            >
              <MenuIcon fontSize="default" />Menu
            </Button>
          </ArrowTooltip>
          <Menu
            id="settings_menu"
            anchorReference="anchorPosition"
            anchorPosition={anchorPositionHelp}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorPositionHelp)}
            onClose={handleHelpMenuClose}
          //onOpen={}
          >
            
            
            <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
              style={{ textDecoration: 'none', display: 'block' }}
            >
              <MenuItem> 
                 <Button id="btn_home" variant="text" color="inherit" >
                   <OndemandVideoIcon fontSize="default" />  Ver Tutorial
                  </Button>
            </MenuItem>
             
               
            </Link>
            

             
             
            <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
              style={{ textDecoration: 'none', display: 'block' }}
            >
              <MenuItem> 
              
                  <Button id="btn_home" variant="text" color="inherit" href="/informationpage">
                 <SettingsIcon fontSize="default" />  Configuración de cuenta
                  </Button>
               
              </MenuItem>
            </Link>
            <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
              style={{ textDecoration: 'none', display: 'block' }}
            >
              <MenuItem>
              
                <Button id="btn_home" variant="text" color="inherit" href="/documentspage">
                   <DescriptionIcon /> Documentos
                 </Button>
                 
              </MenuItem>
            </Link>
            {!props.userinfo.view_only &&
                <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
                    style={{ textDecoration: 'none', display: 'block' }}
                >
                    <MenuItem> 
                        <Button id="btn_home" variant="text" color="inherit" href="/reportpage">
                        <BarChartIcon  /> Informes
                        </Button>
                    </MenuItem>
                </Link>
            }
            <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
              style={{ textDecoration: 'none', display: 'block' }}
            >
              <MenuItem>
               
                  <Button id="btn_home" variant="text" color="inherit" href="/faqpage">
                    <HelpIcon  /> Preguntas Frecuentes
                  </Button>
              
              </MenuItem>
            </Link>
            <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
              style={{ textDecoration: 'none', display: 'block' }}
            >
              <MenuItem> 
              
                <Button id="btn_home" variant="text" color="inherit" href="/companyspage">
                <PeopleIcon   /> Gestión Clientes
                </Button>
              
              </MenuItem>
            </Link>
            {!props.userinfo.is_local && !props.userinfo.view_only &&
                <Link onClick={(e) => { handleClickOpen(e); handleHelpMenuClose() }}
                    style={{ textDecoration: 'none', display: 'block' }}
                >
                    <MenuItem> 
                
                        <Button id="btn_home" variant="text" color="inherit" href="/validatedocspage">
                            <FindInPageIcon  />Validar Documentos
                        </Button>
                    </MenuItem>
                </Link>
          
            }
            
            
          
            {/* <Link
              href="/request-password"
              style={{ textDecoration: 'none', display: 'block' }}
              
            > */}
            {/* <MenuItem>Reestablece tu Contraseña</MenuItem> */}
            {/* </Link> */}
          </Menu>
          <Grid container direction="row" justify="center" alignItems="center"> 
           
          </Grid>
          <div className={classes.flexGrow} />
          <ArrowTooltip
            id="ttp_reload"
            title="Actualizar datos"
            placement="top"
          >
            <Button
              id="btn_reload"
              className={classes.signOutButton}
              variant="text"
              color="inherit"
              onClick={handleReload}
            >
              <CachedIcon fontSize="default" />
            </Button>
          </ArrowTooltip>
          
  

          <ArrowTooltip title="Usuario conectado" placement="top">
            <Button
              className={classes.signOutButton}
              variant="text"
              color="inherit"
              href="/informationpage"
            >
              <Typography
                color="inherit"
                variant="caption"
                data-testid="logged_user-text"
              >
                {renderuserinfo()}
              </Typography>
            </Button>
          </ArrowTooltip>
          <ArrowTooltip title="Cerrar la sesión" placement="top">
            <IconButton
              id="btn_logout"
              className={classes.signOutButton}
              variant="text"
              color="inherit"
              href="/login"
              onClick={handleLogout}
              data-testid="loggout-button"
            >
              <Icon icon={logoutIcon} size="default" />
            </IconButton>
          </ArrowTooltip>

           
        </Toolbar>

      );
    } else {
      return <Toolbar />;
    }
  };
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ background: '#7E79B0' }}
    >
      <Header />
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userinfo: state.data.userDataArray,
  demovideo: state.data.demovideo,
  ishelpdisplayed: state.valuesHelp.showhelp,
});

Topbar = connect(mapStateToProps, {
  logout,
  showhelp,
  hidehelp,
  loadcompanyarray,
  loadcompanytypearray,
  loaddocumentaggregateddata,
  loaddocumentformatarray,
  loaddocumentoverviewdata,
  loaddocumentspeciesarray,
  loadentityarray,
  loadidtypearray,
  loadinvoicedetailsdata,
  loadfunnelabbyy,
  loadinvoicetypearray,
  loadliterdetailsdata,
  loadsupplierarray,
  downloaddemovideo,
})(Topbar);

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
