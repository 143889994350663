import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  Grid,
  Button,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { login } from 'common/actions/auth/auth';
import validateUserForm from './validator';
import { fetchApiActivate } from 'functions/fetchApi';

class ActivateAccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserID: props.match.params.uid,
      UserKey: props.match.params.key,
      RedirectUser: false,
    };
  }
  renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );

  renderSelectField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => {
    return (
      <FormControl error={error}>
        <InputLabel id={'form_label_idType'}>{label}</InputLabel>
        <Select
          label={label}
          placeholder={label}
          error={touched && invalid}
          helperText={touched && error}
          {...input}
          {...custom}
        >
          <MenuItem value={'CIF'} id={'form_select_item_idType_cif'}>
            CIF
          </MenuItem>
          <MenuItem value={'NIF'} id={'form_select_item_idType_cif'}>
            NIF
          </MenuItem>
          <MenuItem value={'NIE'} id={'form_select_item_idType_cif'}>
            NIE
          </MenuItem>
        </Select>
        {error && (
          <FormHelperText id={'form_select_helper_idType'}>
            Seleccione una opción
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  renderCheckbox = ({ input, label,meta: { touched, invalid, error } }) => (
    <FormControl required error={error}>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            color="primary"
          />
        }
        label={label}
      />
      {error && (
      <FormHelperText>{error}</FormHelperText>)}
    </FormControl>
  );

  handleChangeItType = (e) => {
    this.setState({
      selectedIdTypevalue: e.target.value,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  async handleSubmit(formValues) {
    formValues.preventDefault();
    var formSubmit = {
      id_type: formValues.target.national_id_type.value,
      id_number: formValues.target.national_id.value,
      first_name: formValues.target.name.value,
      last_name_1: formValues.target.firstSurname.value,
      last_name_2: formValues.target.secondSurname.value,
      password: formValues.target.password.value,
    };
    fetchApiActivate(this.state.UserID, this.state.UserKey, formSubmit);
    this.setState({ RedirectUser: true });
  }

  render() {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        {this.state.RedirectUser && <Redirect to="/login" />}
        <Paper style={{ width: 500, marginTop: 30, padding: 15 }}>
          <form onSubmit={this.handleSubmit.bind(this)} className="ui form">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12}>
                <Field
                  name="national_id_type"
                  type="select"
                  component={this.renderSelectField}
                  label="NIF/NIE"
                  style={{ width: 250 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="national_id"
                  type="text"
                  component={this.renderTextField}
                  label="NIF/NIE"
                  style={{ width: 250 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="name"
                  type="text"
                  component={this.renderTextField}
                  label="Nombre"
                  style={{ width: 250 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="firstSurname"
                  type="text"
                  component={this.renderTextField}
                  label="Primer Apellido"
                  style={{ width: 250 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="secondSurname"
                  type="text"
                  component={this.renderTextField}
                  label="Segundo Apellido"
                  style={{ width: 250 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="password"
                  type="password"
                  component={this.renderTextField}
                  label="Contraseña"
                  style={{ width: 250 }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="confirmpassword"
                  type="password"
                  component={this.renderTextField}
                  label="Repetir contraseña"
                  style={{ width: 250 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="terms"
                  component={this.renderCheckbox}
                  label={
                    <Typography>
                      Aceptar <Link href="/terms">Términos y Condiciones</Link>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{ marginTop: 20 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Activar cuenta
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

ActivateAccountPage = connect(mapStateToProps, { login })(ActivateAccountPage);

export default reduxForm({
  form: 'activateAccountForm',
  validate: validateUserForm,
})(ActivateAccountPage);
