import {
  CHANGE_ENTITY,
  CHANGE_IDENTITY_TYPE,
  CHANGE_COMPANY_TYPE,
} from "common/constants/components";

import { loadState, saveState } from "functions/stateHelper";
import { STATE_KEY_COMPANY } from "common/constants/states";

const init = {
  entityId: null,
  idTypeId: null,
  companyTypeId: null,
};


let initialState = loadState(STATE_KEY_COMPANY, init);

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case CHANGE_ENTITY:
      updatedState = {
        ...state,
        entityId: action.payload.entityId,
      };
      break;
    case CHANGE_IDENTITY_TYPE:
      updatedState = {
        ...state,
        idTypeId: action.payload.idTypeId,
      };
      break;
    case CHANGE_COMPANY_TYPE:
      updatedState = {
        ...state,
        companyTypeId: action.payload.companyTypeId,
      };
      break;
    default:
      return state;
  }
  saveState(updatedState, STATE_KEY_COMPANY);
  return updatedState;
}
