import React, { Component } from 'react';
import { connect } from "react-redux";
import { login, refreshtoken } from "../../common/actions/auth/auth";
import { Grid } from '@material-ui/core';
import TableCompany from './components/TableCompany/';

export default class CompanyPage extends Component {
  constructor() {
    super();
    this.state = {};
    this.references = {};
    this.handleUserStatus = this.handleUserStatus.bind(this);
  }

  getOrCreateRef(id) {
    if (!Object.keys(this.references.indexOf(id))) {
      this.references[id] = React.createRef();
    }
    return this.references[id];
  }

  async handleUserStatus() {
    await this.props.getUser();
  }

  render() {
    // this.handleUserStatus()
    return (
      <div style={{ padding: 24 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableCompany 
                title="Look at all your companies!" 
                useActionIcon = {this.props.userinfo.view_only ? false : true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userinfo: state.data.userDataArray
});
 
CompanyPage = connect(mapStateToProps, {
  login,
  refreshtoken
})(CompanyPage);