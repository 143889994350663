import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { FormControlSelector } from 'components';

const Selector = ({listaAsociacion, listaEntidad, listaCompany, filter, setFilter}) => {
    return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Paper style={{ padding: 15 }}>
					<FormControlSelector
						value={filter.asociacion}
						onChange={(e) => {
							setFilter({
							...filter,
							asociacion: e.target.value,
							entity: 0,
							company: 0
							});
						}}
						data={listaAsociacion}
						label="Asociación"
						id_key="asociacion"
					/>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper style={{ padding: 15 }}>
					<FormControlSelector
						value={filter.entity}
						onChange={(e) => {
							setFilter({
							...filter,
							entity: e.target.value,
							company: 0
							});
						}}
						data={listaEntidad}
						label="Entidad"
						id_key="entidad"
					/>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper style={{ padding: 15 }}>
				{' '}
				<FormControlSelector
					value={filter.company}
					onChange={(e) => {
						setFilter({
						...filter,
						company: e.target.value,
						});
					}}
					data={listaCompany}
					label="Cliente"
					id_key="cliente"
				/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default Selector;