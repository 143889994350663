import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login, refreshtoken } from "../../common/actions/auth/auth";
import ArrowTooltip from "components/Tooltip";
import { Grid, Card, CardActionArea, CardHeader } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import BarChartIcon from '@material-ui/icons/BarChart';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import FindInPageIcon from '@material-ui/icons/FindInPage';

class LandingPage extends Component {

  render() {
    // this.handleUserStatus()
    this.props.refreshtoken();
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="ui container" style={{ marginTop: 30 }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={10}
          style={{ width: '100%' }}
        >

          <Grid item>
            <ArrowTooltip title="Subida y gestión de documentos" placement="top" open={this.props.ishelpdisplayed}>
              <Card style={{ width: '15em' }}>
                <CardActionArea href="/documentspage" data-testid="documents-button">
                  <CardHeader title="Documentos" />
                  <DescriptionIcon style={{ fontSize: '15em', color: '#64608c' }} />
                </CardActionArea>
              </Card>
            </ArrowTooltip>
          </Grid>
            {!this.props.userinfo.view_only &&
            <Grid item>
                <ArrowTooltip title="Visualización y generación de informes" placement="top" open={this.props.ishelpdisplayed}>
                <Card style={{ width: '15em' }}>
                    <CardActionArea href="/reportpage">
                    <CardHeader title="Informes" />
                    <BarChartIcon style={{ fontSize: '15em', color: "#4b4869" }} />
                    </CardActionArea>
                </Card>
                </ArrowTooltip>
            </Grid>
            }

            {this.props.userinfo.view_only &&
            <Grid item>
                <ArrowTooltip title="Visualización y generación de informes" placement="top" open={this.props.ishelpdisplayed}>
                <Card style={{ width: '15em' }}>
                    <CardActionArea href="/reportpageGLV">
                    <CardHeader title="Informes" />
                    <BarChartIcon style={{ fontSize: '15em', color: "#4b4869" }} />
                    </CardActionArea>
                </Card>
                </ArrowTooltip>
            </Grid>
            }
          <Grid item>
            <ArrowTooltip title="Respuestas a preguntas frecuentes y pasos a seguir" placement="top" open={this.props.ishelpdisplayed}>
              <Card style={{ width: '15em' }}>
                <CardActionArea href="/faqpage">
                  <CardHeader title="Preguntas Frecuentes" />
                  <HelpIcon style={{ fontSize: '15em', color: '#7E79B0' }} />
                </CardActionArea>
              </Card>
            </ArrowTooltip>
          </Grid>
          <Grid item>
            <ArrowTooltip title="Gestión de usuarios" placement="top" open={this.props.ishelpdisplayed}>
              <Card style={{ width: '15em' }}>
                <CardActionArea href="/companyspage">
                  <CardHeader title="Gestión Clientes" />
                  <PeopleIcon style={{ fontSize: '15em', color: '#252434' }} />
                </CardActionArea>
              </Card>
            </ArrowTooltip>
          </Grid>

          {!this.props.userinfo.is_local && !this.props.userinfo.view_only &&
            <Grid item>
              <ArrowTooltip title="Gestión de usuarios" placement="top" open={this.props.ishelpdisplayed}>
                <Card style={{ width: '15em' }}>
                  <CardActionArea href="/validatedocspage">
                    <CardHeader title="Validar documentos" />
                    <FindInPageIcon style={{ fontSize: '15em', color: '#9793bf' }} />
                  </CardActionArea>
                </Card>
              </ArrowTooltip>
            </Grid>}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userinfo: state.data.userDataArray,
  ishelpdisplayed: state.valuesHelp.showhelp
});

LandingPage = connect(mapStateToProps, {
  login,
  refreshtoken
})(LandingPage);

export default LandingPage;
