import { SUMMARY } from 'common/constants/model/values/documentSpecies'

export const categoryCompanyQueries = (filter) => {
  return [
    {
      name: "CompanyArray",
      type: "list",
      table: "entitycompany",
      categories: ["id", "entityId", "value", "is_closed"],
      expressions: [
        {
          name: "value",
          definition: 'F("name")',
        },
        {
          name: "entityId",
          definition: 'F("entity_id")',
        },
      ],
      filters: filter,
      orderby: ["value"],
    },
  ];
};

export const categoryEntityQueriesAbby = () => {
  return [
    {
      name: "entityfileabbybatch",
      type: "list",
      table: "entityfileabbybatch",
      categories: ["value" , "entityfile__entity__id"],
      expressions: [  {
        name: "value",
        definition: 'F("entityfile__entity__name")',
      }],
      filters: {},
      orderby: ["entityfile__entity__id"],
    }
  ];
};
export const categoryCompanyQueriesAbby = () => {
  return [
    {
      name: "entityfileabbybatch",
      type: "list",
      table: "entityfileabbybatch",
      categories: ["value" , "entityfile__company__id"],
      expressions: [  {
        name: "value",
        definition: 'F("entityfile__company__name")',
      }],
      filters: {},
      orderby: ["entityfile__company__id"],
    }
  ];
};

export const categorySupplierQueriesAbby = () => {
  return [
    {
      name: "entityfileabbydocumentresultsgeneric",
      type: "list",
      table: "entityfileabbydocumentresultsgeneric",
      categories: ["value" , "vendor_alias"],
      expressions: [  {
        name: "value",
        definition: 'F("vendor_alias")',
      }],
      filters: {},
      orderby: ["vendor_alias"],
    }
  ];
};

export const categoryDateQueriesAbby = () => {
  return [
    {
      name: "entityfileabbydocumentresultsgeneric",
      type: "list",
      table: "entityfileabbydocumentresultsgeneric",
      categories: ["value" , "year"],
      expressions: [  {
        name: "value",
        definition: 'F("year")',
      }],
      filters: {},
      orderby: ["year"],
    }
  ];
};

export const categoryProvinceQueriesAbby = () => {
  return [
    {
      name: "entityfileabbydocumentresultsgeneric",
      type: "list",
      table: "entityfileabbydocumentresultsgeneric",
      categories: ["value" , "client_province"],
      expressions: [  {
        name: "value", 
        definition: 'F("client_province")',
      }],
      filters: {},
      orderby: ["client_province"],
    }
  ];
};
export const categoryStateQueriesAbby = () => {
  return [
    {
      name: "entityfileStatus",
      type: "list",
      table: "entityfile",
      categories: ["value" , "status__value"],
      expressions: [  {
        name: "value", 
        definition: 'F("status__value")',
      }],
      filters: {},
      orderby: ["status__value"],
    }
  ];
};

export const callgraphQueriesAbby = (filtergraph) => {
  return [
    {
      name: "entityfileabbydocumentresultsgeneric",
      type: "list",
      table: "entityfileabbydocumentresultsgeneric",
      categories: ["abbydocument" , "year", "vendor_alias", "client_province", "pagesnumber", "total_liters", "total_price", "total_taxes", "invoice_status" , "abbydocument__abbybatch__entityfile__status__value"],
      expressions: [],
      filters: filtergraph,
      orderby: ["abbydocument"],
    }
  ];
};


export const statusInvoiceAbbyy = (filtergraph) => {
  return [
    {
      name: "entityfileabbydocumentstatus",
      type: "list",
      table: "entityfileabbydocumentstatus",
      categories: ["processingstagetype"],
      expressions: [],
      filters: filtergraph,
      orderby: ["abbydocument"],
    }
  ];
};

export const calltotaldocuments = (filtergraph) => {
  return [
    {
      name: "entityfileabbydocumentresultsgeneric",
      type: "list",
      table: "entityfileabbydocumentresultsgeneric",
      categories: ["abbydocument__abbybatch__entityfile" ],
      expressions: [],
      filters: filtergraph,
      orderby: ["id"],
    }
  ];
};

export const categoryEntityQueries = (filter) => {
  return [
    {
      name: "EntityArray",
      type: "list",
      table: "entity",
      categories: ["id", "value"],
      expressions: [
        {
          name: "value",
          definition: 'F("name")',
        },
      ],
      filters: filter,
      orderby: ["value"],
    },
  ];
};

export const categoryInvoiceTypeQueries = () => {
  return [
    {
      name: "InvoiceTypeArray",
      type: "list",
      table: "invoicetype",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["value"],
    },
  ];
};

export const categorySupplierQueries = () => {
  return [
    {
      name: "SupplierArray",
      type: "list",
      table: "supplier",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["id"],
    },
  ];
};

export const categoryDocumentFormatQueries = () => {
  return [
    {
      name: "DocumentFormatArray",
      type: "list",
      table: "documentformat",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["value"],
    },
  ];
};

export const categoryDocumentYearQueries = () => {
  return [
    {
      name: "DocumentYearArray",
      type: "list",
      table: "documentyear",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["value"],
    },
  ];
};

export const categoryDocumentSpeciesQueries = () => {
  return [
    {
      name: "DocumentSpeciesArray",
      type: "list",
      table: "documentspecies",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["value"],
    },
  ];
};

export const categoryIdTypeQueries = () => {
  return [
    {
      name: "IdTypeArray",
      type: "list",
      table: "idtype",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["value"],
    },
  ];
};

export const categoryCompanyTypeQueries = () => {
  return [
    {
      name: "CompanyTypesArray",
      type: "list",
      table: "companytype",
      categories: ["id", "value"],
      expressions: [],
      filters: {},
      orderby: ["value"],
    },
  ];
};

export const getDocumentOverviewDataQueries = (filters) => {
  return [
    {
      name: "DocumentOverview",
      type: "multiset",
      table: "entityfile",
      top:500,
      //skip: 10 * NPag,
      //top: 15,
      expressions: [
        {
          name: "company_name",
          definition: 'F("company__name")',
        },
      ],
      categories: [
        "id",
        "file",
        "upload_by",
        "entity__name",
        "status",
        "status__value",
        "document_species__value",
        "company_name",
        "ts_upload",
        "ts_change",
        "company_id"
      ],
      aggfields: [],
      filters,
      orderby: ["-id"],
    },
  ];
};

export const getDocumentAggregatedDataQueries = (filters) => {
  return [
    {
      name: "DocumentOverview",
      type: "multiset",
      table: "entityfile",
      top:500,
      expressions: [
        {
          name: "company_name",
          definition: 'F("company__name")',
        },
      ],
      categories: ["entity__name", "status__value", "status" , "document_species__value"],
      aggfields: [
        {
          annotation: "count_files",
          aggfunc: "count",
          fieldname: "id",
        },
      ],
      filters,
      orderby: ["document_species__value"],
    },
  ];
};

export const getInvoiceDataQueries = () => {
  return [
    {
      name: "InvoiceData",
      type: "multiset",
      table: "entityinvoicedetails",
      expressions: [{
        name: "company_id",
        definition: 'F("file__company__id")',
      }],
      categories: [
        "entity_id",
        "supplier_id",
        "supplier__value",
        "invoice_type__value",
        "invoice_type_id",
        "year",
        "company_id"
      ],
      aggfields: [
        {
          annotation: "count_invoice",
          aggfunc: "count",
          fieldname: "file__company__id",
        },
      ],
      filters: {
        file__delete_flag: false,
        file__document_species__value: 'Factura',
      },
      orderby: ["year"],
    },
  ];
};

export const getSummaryDetailsDataQueries = () => {
  return [
    {
      name: "InvoiceData",
      type: "multiset",
      table: "entitysummarydetails",
      expressions: [
        {
          name: "company_id",
          definition: 'F("file__company__id")',
        }],
      categories: ["entity_id", "supplier_id", "supplier__value", "year", "file_id","company_id"],
      aggfields: [
        {
          annotation: "sum_liter_service_station",
          aggfunc: "sum",
          fieldname: "liter_service_station",
        },
        {
          annotation: "sum_liter_own_deposits",
          aggfunc: "sum",
          fieldname: "liter_own_deposits",
        },
      ],
      filters: {},
      orderby: ["year"],
    },
  ];
};

//ABBYY
export const getSummaryDetailsDataQueriesAbbyy = () => {
  return [
    {
      name: "",
      type: "",
      table: "EntityFileAbbyDocumentResultsGeneric",
      expressions: [
        {
          name: "",
          definition: '',
        }],
      categories: [""],
      aggfields: [
        {
          annotation: "",
          aggfunc: "",
          fieldname: "",
        },
        {
          annotation: "",
          aggfunc: "",
          fieldname: "",
        },
      ],
      filters: {},
      orderby: [""],
    },
  ];
};

export const getCompanyDataQueries = (filters) => {
  return [
    {
      name: "CheckList",
      type: "multiset",
      table: "entitycompany",
      expressions: [
        {
          name: "value",
          definition: 'F("name")',
        },
        {
          name: "entityId",
          definition: 'F("entity_id")',
        },
        {
          name: "is_liter_summary",
          definition: `Case(When(entityfile__document_species__value='${SUMMARY}', then=Value(1)),default=Value(0),output_field=IntegerField())`
        }

      ],
      categories: [
        "id",
        "value",
        "entityId",
        "is_closed",
        "is_verified",
        "has_uploaded_all_invoices",
        "entity",
        "type",
        "id_type",
        "id_number",
        "comercial_name",
        "first_name",
        "last_name_1",
        "last_name_2",
        "street_type",
        "street_name",
        "floor_apt_no",
        "postal_code",
        "city",
        "province",
        "country",
        "description",
        "comments",
        "entity__superentity__name",
        "entity__name"
      ],
      aggfields: [
        {
          annotation: "has_questionnaire",
          fieldname: "entityfile__entityquestionnairedetails__id",
          aggfunc: "count",
        },
        {
          annotation: "has_liter_summary",
          fieldname: "is_liter_summary",
          aggfunc: "sum",
        },
        {
          annotation: "has_invoices",
          fieldname: "entityfile__entityinvoicedetails__id",
          aggfunc: "count",
        },
        {
          annotation: "has_additional_doc",
          fieldname: "entityfile__entityadditionaldocumentdetails__id",
          aggfunc: "count",
        },
        {
          annotation: "has_contract",
          fieldname: "entityfile__entitycontractdetails__id",
          aggfunc: "count",
        },
        {
          annotation: "has_paid",
          fieldname: "entityfile__entityeuropaymentdetails__id",
          aggfunc: "count",
        },
      ],
      filters,
      orderby: ["name"],
    },
  ];
};

export const updateCompanyStatusQuery = (formValues) => {
  return [
    {
      name: "CompanyUpdate",
      type: "update",
      parents: [
        {
          identifiers: { id: formValues.entity },
          table: "entity",
          fieldname: "entity",
        }
      ],
      table: "entitycompany",
      identifiers: {id: formValues.id},
      defaults: formValues,
    },
  ];
};

export const createEntityCompanyQuery = (formValues) => {
  return [
    {
      name: "ProjectOverviewCreate",
      parents: [
        {
          identifiers: { id: formValues.entity },
          table: "entity",
          fieldname: "entity",
        },
        {
          identifiers: { id: formValues.id_type },
          table: "idtype",
          fieldname: "id_type",
        },
        {
          identifiers: { id: formValues.type },
          table: "companytype",
          fieldname: "type",
        },
      ],
      type: "update",
      table: "entitycompany",
      identifiers: formValues,
      defaults: formValues,
    },
  ];
};

export const downloadFileQuery = (fileId)=>{
  return [
    {
      name: "DownloadFile",
      type: "download",
      table: "entityfile",
      identifiers: { id: fileId }
    },
  ];
}

export const entityquestionnairedetails = (questionnaire)=>{
  return [
    {
      name: "entityquestionnairedetails",
      type: "update",
      table: "entityquestionnairedetails",
      defaults: questionnaire,
      
    },
  ];
}

export const getupdateStatusFiles = (  fileId, status ) => {    
  return [
    {
      name: "update_entityfile_status",
      type: "update",
      fieldname: "entityfile",
      table: "entityfile",
      identifiers: {   id: fileId },
      defaults: {status__id: status }, 
    },
  ];
}; 


export const queryClientsBySuperentity = (filters) => {
  return [
    {
      name: "clients_by_super",
      type: "group",
      table: "entitycompany",
      categories: ["entity__superentity__name"],
      aggfields: [
        {
          annotation: "count",
          fieldname: "entity__superentity__name",
          aggfunc: "count",
        }
      ],
      filters: { },
      orderby: ["entity__superentity__name"],
    }
  ];
};

export const queryDocumentsBySuperentityAndProvider = (filters) => {
  return [
    {
      name: "docs_by_super",
      type: "group",
      table: "entityinvoicedetails",
      categories: ["entity__superentity__name", "supplier__value"],
      aggfields: [
        {
          annotation: "count",
          fieldname: "entity__superentity__name",
          aggfunc: "count",
        },
        {
          annotation: "count",
          fieldname: "supplier__value",
          aggfunc: "count",
        }
      ],
      filters: { },
      orderby: ["entity__superentity__name"],
    }
  ];
};

export const queryLitersBySuperentity = (filters) => {
  return [
    {
      name: "liters_by_super",
      type: "group",
      table: "entitysummarydetails",
      categories: ["entity__superentity__name"],
      aggfields: [
        {
          annotation: "total_liters",
          fieldname: "liter_service_station",
          aggfunc: "sum",
        }
      ],
      filters: { },
      orderby: ["entity__superentity__name"],
    }
  ];
};

export const queryLitersByProvider = (filters) => {
  return [
    {
      name: "liters_by_supplier",
      type: "group",
      table: "entitysummarydetails",
      categories: ["supplier__value"],
      aggfields: [
        {
          annotation: "total_liters",
          fieldname: "liter_service_station",
          aggfunc: "sum",
        }
      ],
      filters: { },
      orderby: ["supplier__value"],
    }
  ];
};

export const queryLitersByYearAndProvider = (filters) => {
  return [
    {
      name: "liters_by_year",
      type: "group",
      table: "entitysummarydetails",
      categories: ["year", "supplier__value"],
      aggfields: [
        {
          annotation: "total_liters",
          fieldname: "liter_service_station",
          aggfunc: "sum",
        }
      ],
      filters: { },
      orderby: ["year"]
    }
  ];
};

export const queryAsociaciones = (filters) => {
  return [
    {
      name: "asociaciones",
      type: "list",
      table: "superentity",
      categories: ["id" , "name"],
      expressions: [],
      filters: {id__in: [1,2,3]},
      orderby: ["name"],
    }
  ];
};

export const queryEntities = (filters) => {
  return [
    {
      name: "entities",
      type: "list",
      table: "entity",
      categories: ["id" , "name"],
      expressions: [],
      filters: {},
      orderby: ["name"],
    }
  ];
};

export const queryCompanies = (filters) => {
  return [
    {
      name: "companies",
      type: "list",
      table: "entitycompany",
      categories: ["id" , "name"],
      expressions: [],
      filters: {},
      orderby: ["name"],
    }
  ];
};

export const getFunnelAbbyy = () => {
  return [
    {
      name: "total_document",
      type: "count_multiset",
      table: "entityinvoicedetails",
      expressions: [],
      categories: ["file__id"],
      filters: {
        file__delete_flag: false,
      },
      distinct:{}
    },
    {
      name: "total_closed",
      type: "count_multiset",
      table: "entityinvoicedetails",
      expressions: [],
      categories: ["file__id"],
      filters: {
        file__company__is_closed: true,
        file__delete_flag: false,
      },
      distinct:{}
    },
    {
      name: "total_verified",
      type: "count_multiset",
      table: "entityinvoicedetails",
      expressions: [],
      categories: ["file__id"],
      filters: {
        file__company__is_verified: true,
        file__company__is_closed: true,
        file__delete_flag: false,
      },
      distinct:{}
    },
    {
      name: "total_sent",
      type: "count_multiset",
      table: "entityinvoicedetails",
      expressions: [],
      categories: ["file__id"],
      filters: {
        file__status__in: [4, 5],
        file__company__is_verified: true,
        file__company__is_closed: true,
        file__delete_flag: false,
      },
      distinct:{}
    },
    {
      name: "total_export",
      type: "count_multiset",
      table: "entityinvoicedetails",
      expressions: [],
      categories: ["file__id"],
      filters: {
        file__status__in: [5],
        file__company__is_verified: true,
        file__company__is_closed: true,
        file__delete_flag: false,
      },
      distinct:{}
    },
    {
      name: "total_inv_verification",
      type: "count_multiset",
      table: "entityfileabbydocument",
      expressions: [],
      categories: ["id"],
      filters: {
        abbybatch__entityfile__status__id: 4,
      },
      distinct:{}
    },
    {
      name: "total_inv_exported",
      type: "count_multiset",
      table: "entityfileabbydocument",
      expressions: [],
      categories: ["id"],
      filters: {
        abbybatch__entityfile__status__id: 5,
      },
      distinct:{}
    },
    {
      name: "Subido a la plataforma",
      type: "count_multiset",
      table: "entityfile",
      expressions: [],
      categories: ["id"],
      filters: {
        status__id: 2,
      },
      distinct:{}
    },
    {
      name: "Documentación validada",
      type: "count_multiset",
      table: "entityfile",
      expressions: [],
      categories: ["id"],
      filters: {
        status__id: 3,
      },
      
      distinct:{},
    },
    {
      name: "Procesado inicial",
      type: "count_multiset",
      table: "entityfile",
      expressions: [],
      categories: ["id"],
      filters: {
        status__id: 4,
      },
      distinct:{}
    },
    {
      name: "Procesado por Abbyy",
      type: "count_multiset",
      table: "entityfile",
      expressions: [],
      categories: ["id"],
      filters: {
        status__id: 5,
      },
      distinct:{}
    },{
      name: "Docs_Rejected",
      type: "abby_count",
      table: "whatever",
      filters: {
      invoice_status: "Rejected",
      abbydocument__abbybatch__entityfile__status__id: 3 
      }
    },
      {
      name: "Docs_valid",
      type: "abby_count",
      table: "whatever",
      filters: {
        invoice_status: "Valid",
        abbydocument__abbybatch__entityfile__status__id: 3
       }
    }
    
  ];
};


export const getDocumentInvoice = () => {    
  return [
    {
    name: "Docs_Rejected",
    type: "abby_count",
    table: "whatever",
    filters: {
    invoice_status: "Rejected",
    abbydocument__abbybatch__entityfile__status__id: 5 
    }
  },
    {
    name: "Docs_valid",
    type: "abby_count",
    table: "whatever",
    filters: {
      invoice_status: "Valid",
      abbydocument__abbybatch__entityfile__status__id: 5
     }
  },
    ];
};  

export const updateFilesStatusToAbbyy = ( companyId ) => {    
    return [
        {
            name: "UpdateInvoices",
            type: "multi_update",
            table: "entityfile",
            parents: [
                {
                    "table": "entitycompany",
                    "fieldname": "company",
                    "identifiers": {
                        "id": companyId
                    }
                } 
            ],
            identifiers:{"status__id":2},
            defaults: {"status_id":3}
        },
    ];
};

