import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  Grid,  
} from "@material-ui/core";
import validateCompanyForm from "../validator";
import { renderTextField } from './helper'

export class CompanyAddressDataSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streetTypeArray: [{ value: "calle" }, { value: "vía" }],
      selectedCompanyType: "",
    };
  }
  render() {
    return (
        <Fragment>
        <Grid item xs={12}>
          <Field
            name="street_type"
            type="text"
            component={renderTextField}
            label="Tipo de vía"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            name="street_name"
            type="text"
            component={renderTextField}
            label="Nombre vía"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="floor_apt_no"
            type="text"
            component={renderTextField}
            label="Piso"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="postal_code"
            type="text"
            component={renderTextField}
            label="Código Postal"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="province"
            type="text"
            component={renderTextField}
            label="Provincia"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="city"
            type="text"
            component={renderTextField}
            label="Localidad"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="country"
            type="text"
            component={renderTextField}
            label="País"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  data: state.data,  
  valuesEntityCompany: state.valuesEntityCompany,  
});

CompanyAddressDataSection = connect(mapStateToProps, {  
})(CompanyAddressDataSection);

export default reduxForm({
  form: "companyForm",
  validate: validateCompanyForm,
  enableReinitialize: true,
})(CompanyAddressDataSection);
