import { GET_DATA_INVOICE, GET_FUNNEL_ABBYY } from "common/constants/data";

import { getInvoiceDataQueries, getFunnelAbbyy } from "./queries";
import { getData } from "functions/fetchApi";

export const loadinvoicedetailsdata = () => async (dispatch) => {
  let queries = getInvoiceDataQueries();
  await getData(queries).then((resultsets) => {
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          let invoiceDetailsArray = resultset.result
          dispatch({
            type: GET_DATA_INVOICE,
            payload: { invoiceDetailsArray },
          });
        }
      }
    }
  });
};


export const loadfunnelabbyy = () => async (dispatch) => {
  let queries = getFunnelAbbyy();
  await getData(queries).then((resultsets) => {
    if (resultsets) {
   
       
          let funnelAbbyy = resultsets
          dispatch({
            type: GET_FUNNEL_ABBYY,
            payload: { funnelAbbyy },
          });
       
      
    }
  });
};

