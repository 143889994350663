import {
    CHANGE_ENTITY,
    CHANGE_IDENTITY_TYPE,
    CHANGE_COMPANY_TYPE,
  } from "common/constants/components";
  
  export const changeEntity = (entityId) => async (dispatch) => {    
    dispatch({
      type: CHANGE_ENTITY,
      payload: { entityId },
    });
  };
  
  export const changeIdType = (idTypeId) => async (dispatch) => {
    dispatch({
      type: CHANGE_IDENTITY_TYPE,
      payload: { idTypeId },
    });
  };
  
  export const changeCompanyType = (companyTypeId) => async (dispatch) => {
    dispatch({
      type: CHANGE_COMPANY_TYPE,
      payload: { companyTypeId },
    });
  };
  