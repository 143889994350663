const validateLoginForm = (values) => {
  const errors = {};  
  if (!values.email) {
    errors.email = 'Campo obligatorio';
  }
  if (!values.password) {
    errors.password = 'Campo obligatorio';
  }  

  return errors;
};

export default validateLoginForm;
