import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { login, refreshtoken } from "../../common/actions/auth/auth";

import { Grid, Typography } from "@material-ui/core";
import Radio from "./components/radioForm"

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      entity: ""
    };
  }  


  render() {

    var entityID=new URLSearchParams(this.props.location.search).get("entity")
    this.state.entity=entityID
    
    return (
      <div className="ui container" style={{ marginTop: 100 }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={10}
          style={{ width: '100%' }}
        > 
        {this.state.entity &&
         <Radio />} 
         {!this.state.entity &&
          <Typography variant="h3">
        No se puede visualizar el contenido
        </Typography>
        }  
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userinfo: state.data.userDataArray,
  ishelpdisplayed: state.valuesHelp.showhelp
});

Questionnaire = connect(mapStateToProps, {
  login,
  refreshtoken
})(Questionnaire);

export default Questionnaire;
