import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { auth } from "./auth";
import {
  valuesAdditionalDocumentDetails,
  valuesEntityCompany,
  valuesEntityFile,
  valuesHelp,
  valuesInvoiceDetails,
} from "./components";
import { data, slicer } from './data'
import {notifications} from "./notifications";
import { LOGOUT_SUCCESS } from "common/constants/auth";

const appReducer = combineReducers({
  form: formReducer,
  auth,
  valuesAdditionalDocumentDetails,
  valuesEntityCompany,
  valuesEntityFile,
  valuesHelp,
  valuesInvoiceDetails,
  data,
  slicer,
  notifications,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
