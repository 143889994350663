export const getPovincias = () => {
    return [
        "Albacete",
        "Alicante/Alacant",
        "Almería",
        "Araba/Álava",
        "Asturias",
        "Ávila",
        "Badajoz",
        "Balears, Illes",
        "Barcelona",
        "Bizkaia",
        "Burgos",
        "Cáceres",
        "Cádiz",
        "Cantabria",
        "Castellón/Castelló",
        "Ceuta",
        "Ciudad Real",
        "Córdoba",
        "Coruña, A",
        "Cuenca",
        "Gipuzkoa",
        "Girona",
        "Granada",
        "Guadalajara",
        "Huelva",
        "Huesca",
        "Jaén",
        "León",
        "Lleida",
        "Lugo",
        "Madrid",
        "Málaga",
        "Melilla",
        "Murcia",
        "Navarra",
        "Ourense",
        "Palencia",
        "Palmas, Las",
        "Pontevedra",
        "Rioja, La",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "Valencia/València",
        "Valladolid",
        "Zamora",
        "Zaragoza"
    ];
};