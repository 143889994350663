import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import {  
  changeAdditionalDocumentDescription,
} from "common/actions/components";

import {  
  FormControlTextInput,
} from "components";

export default class AdditionalDocumentFormSection extends Component {
  constructor(props) {
    super(props);
    this.state = {  
    };
  }  
  render() {
    return (
      <Fragment>
        <Grid item>
        <FormControlTextInput
            value={this.props.valuesAdditionalDocumentDetails.description}
            onChange={(e) => {
              return this.props.changeAdditionalDocumentDescription(e.target.value);
            }}
            label="Descripción"
            multiline={true}
            id_key="additionalDocumentDescription"
            meta= {{ touched:true, error:false }}
          ></FormControlTextInput>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({  
  valuesAdditionalDocumentDetails: state.valuesAdditionalDocumentDetails,  
});

AdditionalDocumentFormSection = connect(mapStateToProps, {  
  changeAdditionalDocumentDescription,  
})(AdditionalDocumentFormSection);
