import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  IS_LOGGED_IN,
  LOGIN_SUCCESS_OLD_PASSWORD,
  RENEWPASS_SUCCESS
} from "common/constants/auth";
import { loadState, saveState } from "functions/stateHelper";
import {
  STATE_KEY_ADDITIONAL_DOC,
  STATE_KEY_AUTH,
  STATE_KEY_COMPANY,
  STATE_KEY_DATA,
  STATE_KEY_FILE,
  STATE_KEY_HELP,
  STATE_KEY_INVOICE,
  STATE_KEY_SLICER,
} from "common/constants/states";

const init = {
  isLoading: false,
  isAuthenticated: null,
  isloggedIn: null,
  user: null,
  redirectTo: null,
  isPasswordTooOld: false,
  msgToShow:''
};

let initialState = loadState(STATE_KEY_AUTH, init);

export default function (state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("token_access", action.payload.access);
      localStorage.setItem("token_refresh", action.payload.refresh);
      updatedState = {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        isPasswordTooOld: false,
        redirectTo: 'landingpage',
        ...action.payload,
      };
      break;
    case IS_LOGGED_IN:
      updatedState = {
        ...state,
        isloggedIn: true,
      };
      break;
    case LOGIN_FAIL:
      localStorage.removeItem("token_access");
      updatedState = {
        ...state,
        isLoading: false,
        isloggedIn: false,
        isAuthenticated: false,
        user: null,
        token: null,
      };
      break;
    case UPDATE_USER_SUCCESS:
      updatedState = {
        ...state,
        user: action.payload,
      };
      break;
    case RENEWPASS_SUCCESS:
      updatedState = {
        ...state,
        redirectTo: 'login',
      };
      break;
    case UPDATE_USER_FAIL:
      break;
    case LOGIN_SUCCESS_OLD_PASSWORD:
      updatedState = {
        ...state,
        redirectTo: 'reset-password',
        user: action.payload.user,
        isPasswordTooOld: action.payload.isPasswordTooOld,
      };
      break;
    case LOGOUT:
      localStorage.removeItem(STATE_KEY_ADDITIONAL_DOC);
      localStorage.removeItem(STATE_KEY_AUTH);
      localStorage.removeItem(STATE_KEY_COMPANY);
      localStorage.removeItem(STATE_KEY_DATA);
      localStorage.removeItem(STATE_KEY_FILE);
      localStorage.removeItem(STATE_KEY_HELP);
      localStorage.removeItem(STATE_KEY_SLICER);
      localStorage.removeItem(STATE_KEY_INVOICE);
      updatedState = {
        ...state,
        isLoading: false,
        isloggedIn: false,
        isAuthenticated: false,
        user: null,
        token: null,
        redirectTo: null,
        isPasswordTooOld: false,
      };
      break;
    default:
      return state;
  }
  saveState(updatedState, STATE_KEY_AUTH);
  return updatedState;
}
