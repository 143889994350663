import {
  CHANGE_COMPANY,
  CHANGE_ENTITY,
  CHANGE_DOCUMENT_FORMAT,
  CHANGE_DOCUMENT_SPECIES,
} from "common/constants/components";

import { loadState, saveState } from "functions/stateHelper";
import {STATE_KEY_FILE } from "common/constants/states";

const init = {
  entityId: null,
  companyId: null,
  documentFormatId: null,
  documentSpeciesId: 1,
  checkSelectedCategories: false,
};


let initialState = loadState(STATE_KEY_FILE, init);

const checkSelectedCategories = (state) => {
  return (
    state.entityId !== null &&
    state.companyId !== null// &&
  //  state.documentFormatId !== null //&&
    // state.documentSpeciesId !== null
  );
};

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case CHANGE_ENTITY:
      updatedState = {
        ...state,
        entityId: action.payload.entityId,
      };
      break;
    case CHANGE_COMPANY:
      updatedState = {
        ...state,
        companyId: action.payload.companyId,
      };
      break;
    case CHANGE_DOCUMENT_FORMAT:
      updatedState = {
        ...state,
        documentFormatId: action.payload.documentFormatId,
      };
      break;
    case CHANGE_DOCUMENT_SPECIES:
      updatedState = {
        ...state,
        documentSpeciesId: action.payload.documentSpeciesId,
      };
      break;
    default:
      return state;
  }

  Object.assign(updatedState, {
    checkSelectedCategories: checkSelectedCategories(updatedState),
  });
  
  saveState(updatedState, STATE_KEY_FILE);
  return updatedState;
}
