export function filterData(data, filter) {
  if (filter) {
    return data.filter((o) => {
      let include = true;
      for (let f in filter) {
        if (filter[f])
          include = include && filter[f].includes(o[f]);
      }
      return include;
    });
  }  
  return data
}

