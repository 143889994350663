import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Link,
  Button,
} from "@material-ui/core";
import { fetchApiRequestPassword } from "functions/fetchApi";
import { logout } from "common/actions/auth/auth";
import validateUserForm from './validator'

class RequestPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RedirectUser: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    formValues.preventDefault();
    var formSubmit = {
      email: formValues.target.email.value,
    };
    fetchApiRequestPassword(formSubmit);
    this.props.logout()
    this.setState({ RedirectUser: true });

  }

  renderField = ({ input, label, type, meta: { touched, error }, style }) => {
    return (
      <div className={`field ${touched && error ? "error" : ""}`}>
        <TextField
          id="standard-basic"
          {...input}
          type={type}
          label={label}
          style={style}
          variant="outlined"
        />
        {touched && error && (
          <span className="ui pointing red basic label">{error}</span>
        )}
      </div>
    );
  };

  hiddenField = ({ type, meta: { error } }) => {
    return (
      <div className="field">
        <input type={type} />
        {error && <div className="ui red message">{error}</div>}
      </div>
    );
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ padding: 96 }}
      >
        {this.state.RedirectUser && <Redirect to="/request-password-confirmation" />}
        <Grid item>
          <Paper
            style={{
              width: 300,
              minHeight: "42vh",
              padding: 16,
              marginTop: 100,
            }}
          >
            <form onSubmit={this.handleSubmit} className="ui form">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid xs={12}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{ marginTop: 24 }}
                  >
                    Solicitar la contraseña
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    type="text"
                    component={this.renderField}
                    label="E-Mail"
                    style={{ width: 250, marginTop: 24 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ marginTop: 20 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    enviar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    gutterBottomhy
                    display="block"
                    align="center"
                  >
                    Una vez enviado, le enviaremos un correo electrónico con más
                    instrucciones para reestablecer su contraseña. Por favor,
                    póngase en contacto con el
                    <Link href={"mailto:info@afectadosporlaspetroleras.es"}>
                      {" administrador "}
                    </Link>
                    para más información.
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,  
});

RequestPasswordPage = connect(mapStateToProps, {
  logout,
})(RequestPasswordPage);

export default reduxForm({
  form: "loginForm",
  validate: validateUserForm,
})(RequestPasswordPage);
