export const USER_LOADING = 'USER_LOADING'; 
export const USER_LOADED = 'USER_LOADED'; 
export const AUTH_ERROR = 'AUTH_ERROR'; 
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'; 
export const REGISTER_FAIL = 'REGISTER_FAIL'; 
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'; 
export const LOGIN_FAIL = 'LOGIN_FAIL'; 
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const LOGIN_SUCCESS_OLD_PASSWORD = 'LOGIN_SUCCESS_OLD_PASSWORD';
export const RENEWPASS_SUCCESS = 'RENEWPASS_SUCCESS';

