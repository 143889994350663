export const loadState = (key, init) => {
    try {
      const serializedState = localStorage.getItem(key);      
      if(serializedState === null) {
        return init;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return init;
    }
  };


export const saveState = (state, key) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (e) {
      // Ignore write errors;
    }
  };