import {
  GET_ENTITY_ARRAY,
  GET_INVOICE_TYPE_ARRAY,
  GET_SUPPLIER_ARRAY,
  GET_DOCUMENT_FORMAT_ARRAY,
  GET_DOCUMENT_SPECIES_ARRAY,
  GET_COMPANY_TYPE_ARRAY,
  GET_IDENTITY_TYPE_ARRAY,
  GET_ENTITY_ARRAY_ABBYY,
  GET_COMPANY_ARRAY_ABBYY,
  GET_SUPPLIER_ARRAY_ABBYY,
  GET_DATE_ARRAY_ABBYY,
  GET_PROVINCE_ARRAY_ABBYY,
  GET_STATE_ARRAY_ABBYY,
  GET_GRAPH_QUERIES_ABBY,
  GET_STATUS_INVOICE_ABBYY,
  GET_TOTAL_DOCUMENTS_ABBYY

} from "common/constants/data";

import {
  categorySupplierQueries,
  categoryInvoiceTypeQueries,
  categoryDocumentFormatQueries, 
  categoryDocumentSpeciesQueries,
  categoryEntityQueriesAbby,
  categoryCompanyQueriesAbby,
  categorySupplierQueriesAbby,
  categoryDateQueriesAbby,
  categoryProvinceQueriesAbby, 
  categoryStateQueriesAbby,
  callgraphQueriesAbby,
  statusInvoiceAbbyy,
  calltotaldocuments,
  categoryEntityQueries,
  categoryIdTypeQueries,
  categoryCompanyTypeQueries,
} from "./queries";

import { getData } from "functions/fetchApi";
import { array } from "prop-types";

export const loadentityarray = (companyId) => async (dispatch) => {
  let filter = {};
  if (companyId) {
    filter["entitycompany__id"] = companyId;
  }
  const resultsets = await getData(categoryEntityQueries(filter));
  const entityArray = resultsets[0].result;
  dispatch({
    type: GET_ENTITY_ARRAY,
    payload: { entityArray },
  });
};

export const loadsupplierarray = () => async (dispatch) => {
  const resultsets = await getData(categorySupplierQueries());
  const supplierArray = resultsets[0].result;
  dispatch({
    type: GET_SUPPLIER_ARRAY,
    payload: { supplierArray },
  });
};

export const loadinvoicetypearray = () => async (dispatch) => {
  const resultsets = await getData(categoryInvoiceTypeQueries());
  const invoiceTypeArray = resultsets[0].result;
  dispatch({
    type: GET_INVOICE_TYPE_ARRAY,
    payload: { invoiceTypeArray },
  });
};

export const loaddocumentformatarray = () => async (dispatch) => {
  const resultsets = await getData(categoryDocumentFormatQueries());
  const documentFormatArray = resultsets[0].result;
  dispatch({
    type: GET_DOCUMENT_FORMAT_ARRAY,
    payload: { documentFormatArray },
  });
};

export const loaddocumentspeciesarray = () => async (dispatch) => {
  const resultsets = await getData(categoryDocumentSpeciesQueries());
  const documentSpeciesArray = resultsets[0].result;
  dispatch({
    type: GET_DOCUMENT_SPECIES_ARRAY,
    payload: { documentSpeciesArray },
  });
};

export const loadidtypearray = () => async (dispatch) => {
  const resultsets = await getData(categoryIdTypeQueries());
  const idTypeArray = resultsets[0].result;
  dispatch({
    type: GET_IDENTITY_TYPE_ARRAY,
    payload: { idTypeArray },
  });
};

export const loadcompanytypearray = () => async (dispatch) => {
  const resultsets = await getData(categoryCompanyTypeQueries());
  const companyTypeArray = resultsets[0].result;
  dispatch({
    type: GET_COMPANY_TYPE_ARRAY,
    payload: { companyTypeArray },
  });
}; 


export const loadentityarrayabbby = () => async (dispatch) => {
 
  const resultsets = await getData(categoryEntityQueriesAbby());
  
  const entityArrayAbbyy = resultsets[0].result;

  
  dispatch({
    type: GET_ENTITY_ARRAY_ABBYY,
    payload: { entityArrayAbbyy },
  });
};


export const loadcompanyarrayabbby = () => async (dispatch) => {
 
  const resultsets = await getData(categoryCompanyQueriesAbby());
  
  const companyArrayAbbyy = resultsets[0].result;
 
  
  dispatch({
    type: GET_COMPANY_ARRAY_ABBYY,
    payload: { companyArrayAbbyy },
  });
};


export const loadsupplierarrayabbby = () => async (dispatch) => {
 
  const resultsets = await getData(categorySupplierQueriesAbby());
  
  const supplierArrayAbbyy = resultsets[0].result;
 
 
  dispatch({
    type: GET_SUPPLIER_ARRAY_ABBYY,
    payload: { supplierArrayAbbyy },
  });
};


export const loaddatearrayabbby = () => async (dispatch) => {
 
  const resultsets = await getData(categoryDateQueriesAbby());
  
  const dateArrayAbbyy  = resultsets[0].result;
 
  
  dispatch({
    type: GET_DATE_ARRAY_ABBYY,
    payload: { dateArrayAbbyy },
  });
};

export const loadprovincearrayabbby = () => async (dispatch) => {
 
  const resultsets = await getData(categoryProvinceQueriesAbby());
  
  const provinceArrayAbbyy  = resultsets[0].result;
 
  
  dispatch({
    type: GET_PROVINCE_ARRAY_ABBYY,
    payload: { provinceArrayAbbyy },
  });
};

export const loadstatearrayabbby = () => async (dispatch) => {
 
  const resultsets = await getData(categoryStateQueriesAbby());
  
  const stateArrayAbbyy  = resultsets[0].result;
 
  
  dispatch({
    type: GET_STATE_ARRAY_ABBYY,
    payload: { stateArrayAbbyy },
  });
};

export const loadgraphQueriesAbby = (filter) => async (dispatch) => {

  const queries = callgraphQueriesAbby(filter);
  await getData(queries).then((resultsets) => {
 
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          const graphQueriesAbby = resultsets[0].result;
          dispatch({
            type: GET_GRAPH_QUERIES_ABBY,
            payload: { graphQueriesAbby },
          });
        }else{
          const graphQueriesAbby = [];
          dispatch({
            type: GET_GRAPH_QUERIES_ABBY,
            payload: { graphQueriesAbby },
          });
        }
      }
    }
  }); 
};

export const loadstatusInvoiceAbbyy = (filter) => async (dispatch) => {

  const queries = statusInvoiceAbbyy(filter);
  await getData(queries).then((resultsets) => {
 
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          const statusInvoiceAbbyy = resultsets[0].result;
          dispatch({
            type: GET_STATUS_INVOICE_ABBYY,
            payload: { statusInvoiceAbbyy },
          });
        }else{
          const statusInvoiceAbbyy = [];
          dispatch({
            type: GET_STATUS_INVOICE_ABBYY,
            payload: { statusInvoiceAbbyy },
          });
        }
      }
    }
  }); 
};


  
export const loadtotaldocuments = (filter) => async (dispatch) => {
 
  const resultsets = await getData(calltotaldocuments(filter));
   const totaldocumentsabbyy   = resultsets[0].result;
 
  dispatch({
    type: GET_TOTAL_DOCUMENTS_ABBYY ,
    payload: { totaldocumentsabbyy },
  });
};
  