export const SHOW_HELP = 'SHOW_HELP'; 
export const HIDE_HELP = 'HIDE_HELP'; 

export const CHANGE_ADDITIONAL_DOCUMENT_DESCRIPTION = "CHANGE_ADDITIONAL_DOCUMENT_DESCRIPTION";
export const CHANGE_ENTITY = "CHANGE_ENTITY";
export const CHANGE_COMPANY = "CHANGE_COMPANY";
export const CHANGE_SUPPLIER = "CHANGE_SUPPLIER";
export const CHANGE_INVOICE_TYPE = "CHANGE_INVOICE_TYPE";
export const CHANGE_DOCUMENT_FORMAT = "CHANGE_DOCUMENT_FORMAT";
export const CHANGE_DOCUMENT_YEAR = "CHANGE_DOCUMENT_YEAR";
export const CHANGE_DOCUMENT_SPECIES = "CHANGE_DOCUMENT_SPECIES";
export const CHANGE_IDENTITY_TYPE = "CHANGE_IDENTITY_TYPE";
export const CHANGE_COMPANY_TYPE = "CHANGE_COMPANY_TYPE";