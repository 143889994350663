const validateUserForm = (values) => {
  const errors = {};
  const strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;  

  if (!values.securitypassword) {
    errors.securitypassword = 'Campo obligatorio';
  } 

  if (!values.password) {
    errors.password = 'Campo obligatorio';
  } else if (!strongRegex.test(values.password)) {
    errors.password = 'Utilice una contraseña de al menos 8 simbolos [a-z A-Z 0-9]';
  }
  else if (values.securitypassword===values.password){
    errors.password = 'La contraseña antigua y la nueva no pueden ser la misma';
  }
  if (!values.corfirmpassword) {
    errors.corfirmpassword = 'Campo obligatorio';
  } else if (values.corfirmpassword !== values.password) {
    errors.corfirmpassword = 'Las contraseñas no coinciden';
  }
  return errors;
};

export default validateUserForm;
