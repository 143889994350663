import React, {useState, Fragment, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import { Grid, MenuItem, TextField, Box } from '@material-ui/core';
import { notifySuccess, notifyError } from 'common/actions/notifications';
import { makeStyles } from '@material-ui/core/styles';
import { createDataQuestionnare } from './queries';
import { getPovincias } from './provincias';
import { sendWithdrawal } from 'functions/fetchApi';
import { loaddocumentoverviewdata, loadcompanyarray } from 'common/actions/data';
import { connect } from "react-redux";
import If, { Else } from 'if-else-react';

export default function RadioButtonsGroup(props) {
    const [elemento, setElemento] = useState({ nombre: '', idNumber: '', mail: '', telefono: '', direccion: '', representante: '', representanteIdNumber: '' });
    const [provincia, setProvincia] = useState('');
    const [tipo, setTipo] = useState('');

    const uuid = props.uuid;
    const provincias = getPovincias();

    const {nombre, idNumber, mail, telefono, direccion, representante, representanteIdNumber} = elemento;

    useEffect(() => {
        if (tipo == 'autonomo' || tipo == 'particular') {
            setElemento({
                ...elemento,
                representante: '',
                representanteIdNumber: ''
            })
        }
        
    }, [tipo])

    const updateState = e => {
        setElemento({
            ...elemento,
            [e.target.name]: e.target.value 
        })
    }

    function handleChangeProvincia(event) { setProvincia(event.target.value) }
    function handleChangeTipo(event) { setTipo(event.target.value) }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const questionnare =  createDataQuestionnare(nombre, idNumber, representante, representanteIdNumber, mail, telefono, direccion, provincia, tipo, uuid);
        let status = await sendWithdrawal(questionnare);
        status > 200 
            ? props.notifyError("La URL no es válida, contacte con su organización")
            : props.notifySuccess("Gracias por responder al formulario");
    };

    const useStyles = makeStyles((theme) => ({
        root: {
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '35ch',
          },
        },
    }));

    const classes = useStyles();
    
    return (
        <Fragment>
            <form className={classes.root} onSubmit={handleSubmit}>
                <div> 
                    <Grid item xs={12}>
                        <TextField
                            id="standard-select"
                            select
                            label="Particular, autónomo o empresa *"
                            value={tipo}
                            onChange={handleChangeTipo}
                        >
                            <MenuItem value="particular">Particular</MenuItem>
                            <MenuItem value="autonomo">Autónomo</MenuItem>
                            <MenuItem value="empresa">Empresa</MenuItem>
                        </TextField>
                    </Grid>

                    {
                        tipo != '' &&
                        <Fragment>
                            <Grid item xs={12}>
                        <TextField
                            type="text" 
                            name="nombre" 
                            value={nombre} 
                            onChange={updateState}
                            label = { tipo == 'empresa' ? 'Denominación social *' : 'Nombre y apellidos *' }
                        ></TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            type="text" 
                            name="idNumber" 
                            value={idNumber} 
                            onChange={updateState}  
                            label  ={ tipo == 'empresa' ? 'CIF *' : 'NIF *' }
                        ></TextField>
                    </Grid>

                    {
                        tipo == 'empresa' &&
                            <Fragment>
                                <Grid item xs={14}>
                                <TextField 
                                    type="text" 
                                    name="representante" 
                                    value={representante} 
                                    onChange={updateState}
                                    label = "Nombre y apellidos del representante *"
                                ></TextField>
                            </Grid>

                            <Grid item xs={12}>
                            <TextField 
                                type="text" 
                                name="representanteIdNumber" 
                                value={representanteIdNumber} 
                                onChange={updateState}
                                label = "NIF del representante *"
                            ></TextField>
                            </Grid>
                            </Fragment>
                            
                    }

                    <Grid item xs={12}>
                        <TextField
                            id="standard-select-2"
                            select
                            label="Provincia *"
                            value={provincia}
                            onChange={handleChangeProvincia}
                        >
                            {provincias.map((value) => (
                                <MenuItem value={value}>{value}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            type="text" 
                            name="direccion" 
                            value={direccion} 
                            onChange={updateState}
                            label = "Domicilio social *"
                        ></TextField>                          
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            type="text" 
                            name="mail" 
                            value={mail} 
                            onChange={updateState}
                            label  = 'Email contacto *'
                        ></TextField>                         
                    </Grid>

                    <Grid item xs={12}>
                        <TextField 
                            type="text" 
                            name="telefono" 
                            value={telefono} 
                            onChange={updateState}
                            label  ='Teléfono contacto *'
                        ></TextField>                         
                    </Grid>
                        </Fragment>
                    }
                    
                    <Grid item xs={12}>
                        <small>* Campos obligatorios</small>                     
                    </Grid>

                    <Box textAlign='center' mt={2}>
                        <If condition={nombre && mail && idNumber && provincia && telefono && ((tipo == 'empresa' && representante && representanteIdNumber) || (tipo == 'autonomo') || (tipo == 'particular'))}>
                            <Button type="submit" variant="contained">
                                Enviar
                            </Button>
                        <Else />
                            <Button type="submit" variant="contained"  disabled>
                                Enviar
                            </Button>
                        </If>
                    </Box>
                </div>
            </form>

            <div style={{ textAlign: 'left', marginTop: 50, marginLeft: 20, marginRight:20 }}>
                <p>
                    <small>El PODERDANTE manifiesta que ha realizado compras de vehículos afectados por la infracción del derecho de la competencia a
                            que se refieren la resolución de la CNMC julio de 2015 (expediente S/0482/13), y en consecuencia autoriza y apodera a CLASS ACCELERATOR, SL
                        <sup>1</sup> para que (sin que CLASS ACCELERATOR asuma ninguna responsabilidad) reclame extrajudicialmente una indemnización a las marcas 
                        infractoras y a las demás entidades que puedan ser responsables, en representación y por cuenta del firmante.
                    </small>
                </p>
                <br />
                <p>
                    <small>De conformidad con las leyes y los reglamentos relativos a la protección y privacidad de los datos (las «Leyes de Protección de Datos»), incluyendo, sin 
                    limitación, la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante, LOPDGDD), el Reglamento
                    (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de
                    datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (el «RGPD»); 
                    le informamos que clicando en el botón SI AUTORIZO sus datos personales (NOMBRE Y APELLIDOS-DENOMINACIÓN SOCIAL, DNI/CIF, DOMICILIO COMPLETO, PROVINCIA)) serán 
                    transferidos a la mercantil CLASS ACCELERATOR, SL y a sus empresas relacionadas, siendo ésta el responsable de los datos en relación con dichos datos personales.
                    En consecuencia, CLASS ACCELERATOR y sus empresas relacionadas usarán los datos personales proporcionados por Vd. para: iniciar una reclamación extrajudicial en su 
                    nombre contra las marcas infractoras y cualquier otra entidad relacionada con el denominado Cartel de los coches.
                    Respecto a CLASS ACCELERATOR podrá ejercer en cualquier momento sus derechos reconocidos en las Leyes de Protección de Datos (acceso, rectificación,
                    cancelación/supresión, oposición, limitación y portabilidad) en la siguiente dirección: Calle Islas de Cabo Verde número 90, Madrid (28035)
                    </small>
                </p>
                <br />
                <p>
                    <small> <sup>1</sup> Inscrita en el Registro Mercantil de Madrid en el volumen 42.750, página 120, hoja M-755.976, con domicilio social en la calle Islas de Cabo 
                    Verde número 90, Madrid (28035), y con CIF B67745778</small>
                </p>
            </div>
            
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    valuesEntityFile: state.valuesEntityFile,
    valuesAdditionalDocumentDetails: state.valuesAdditionalDocumentDetails,
});

RadioButtonsGroup = connect(mapStateToProps, {
    loaddocumentoverviewdata,
    loadcompanyarray,
    notifySuccess,
    notifyError
})(RadioButtonsGroup);
