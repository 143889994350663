import React from 'react';
import {Card, CardContent, Box, CardHeader } from "@material-ui/core";
import { PieChart } from 'components/Charts';

const ClientesRegistrados = ({numeroClientes, datosClientes}) => {
    return (
        <Box style = {{height: '100%'}}>
            <Card elevation={12} alignContent="center" style={{height: "inherit"}}>
                <CardHeader title={"Clientes registrados en la plataforma: " +  numeroClientes.toLocaleString('de-De', { minimumFractionDigits: 0 }) } />
                <CardContent style={{marginTop: -20}}>
                    <Box sx={{ height: 300, position: 'relative' }} >
                        <PieChart
                            data={ datosClientes }
                            title=""
                            colors={['#7E79B0', '#b1aecf', '#4b4869', '#252434']}
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>
	);
};

export default ClientesRegistrados;