import React from 'react';
import {Card, CardHeader, CardContent, Box } from "@material-ui/core";
import { BarChartHor } from 'components/Charts';

const DocumentosPlataforma = ({numeroDocumentos, labels, datasets}) => {
    return (
		
            <Box style = {{height: '100%'}}>
                <Card elevation={12} alignContent="center" style={{height: "inherit"}}>
                    <CardHeader title={"Documentos subidos a la plataforma: " +  numeroDocumentos.toLocaleString('de-De', { minimumFractionDigits: 0 }) } />
                    <CardContent>
                        <BarChartHor
                            datasets={datasets}
                            labels={labels}
                            type = { 'horizontalBar' }
                            title="Documentos Subidos a la plataforma"
                            colors={['#009677', '#ff9338', '#f95951', '#ff0093', "#0089af", "#008772", "#69c2b0", "#69c2b0", "#69c2b0"]}
                            timeXAxe={{
                            unit: 'year',
                            displayFormats: {
                                year: 'YYYY',
                            },
                            }}
                        />
                    </CardContent>
                </Card>
            </Box>
		
	);
};

export default DocumentosPlataforma;