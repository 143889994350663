import { GET_DATA_LITER } from "common/constants/data";

import { getSummaryDetailsDataQueries, getSummaryDetailsDataQueriesAbbyy } from "./queries";

import { getData } from "functions/fetchApi";

export const loadliterdetailsdata = () => async (dispatch) => {
  let queries = getSummaryDetailsDataQueries();
  await getData(queries).then((resultsets) => {
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          let literSummaryArray = resultset.result;
          dispatch({
            type: GET_DATA_LITER,
            payload: { literSummaryArray },
          });
        }
      }
    }
  });
};


