import { isValidDNI } from 'common/validators';

const validateCompanyForm = (values) => {
  const errors = {};
  const onlyLettersRexp = /^[A-Za-zÀ-ÖØ-öø-ÿ -]{1,50}$/i;

  if (!values.entity) {
    errors.entity = 'Campo obligatorio';
  }
  if (!values.id_type) {
    errors.id_type = 'Campo obligatorio';
  }

  //TODO Check id_type
  if (!values.id_number) {
    errors.id_number = 'Campo obligatorio';
  // } else if (!isValidDNI(values.id_number)) {
  //   errors.id_number = 'Documento no válido';
  }

  if (!values.type) {
    errors.type = 'Campo obligatorio';
  }
  if (values.type === 1) {
    //Entity data
    if (!values.name) {
      errors.name = 'Campo obligatorio';
    }
  }

  // comercial_name
  //Representant
  //TODO  check if type Autónomo or Particular

  if (values.companyTypeId === 2 & !values.comercial_name) {
    errors.comercial_name = 'Campo obligatorio';
  }

  if (!values.first_name) {
    errors.first_name = 'Campo obligatorio';
  } else if (!onlyLettersRexp.test(values.first_name)) {
    errors.first_name = 'El nombre no tiene un formato correcto';
  }
  
  if (!values.last_name1) {
    errors.last_name1 = 'Campo obligatorio';
  } else if (!onlyLettersRexp.test(values.last_name1)) {
    errors.last_name1 = 'El nombre no tiene un formato correcto';
  }

  if (!values.last_name2) {
    errors.last_name2 = 'Campo obligatorio';
  } else if (!onlyLettersRexp.test(values.last_name2)) {
    errors.last_name2 = 'El nombre no tiene un formato correcto';
  }
  //TODO  check if type Autónomo or Particular

  //Direction
  if (!values.city) {
    errors.city = 'Campo obligatorio';
  }

  if (!values.province) {
    errors.province = 'Campo obligatorio';
  }

  if (!values.postal_code) {
    errors.postal_code = 'Campo obligatorio';
  }

  if (!values.street_name) {
    errors.street_name = 'Campo obligatorio';
  }

  if (!values.street_type) {
    errors.street_type = 'Campo obligatorio';
  }
  // floor_apt_no

  return errors;
};

export default validateCompanyForm;
