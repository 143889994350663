import {queryClientsBySuperentity, queryDocumentsBySuperentityAndProvider, queryLitersBySuperentity,queryLitersByProvider
    , queryLitersByYearAndProvider, queryAsociaciones, queryEntities, queryCompanies } from "./queries";
import { getData } from "functions/fetchApi";

const getClientsBySuperentity = async function (filter = '') {
    let query = queryClientsBySuperentity()

    query[0].filters = filter
        ? filter
        : {}

    const response = await getData(query)

    const map = new Map();
    map.set('GLV EUROPA INVERSIONES E INTERMEDIACION, S.L.', 'GLV')
        .set('FENADISMER - Federación Nacional de Asociaciones de Transporte de España', 'FENADISMER')
        .set('Estudio jurídico EJASO, S.L.', 'EJASO');

    let total = 0;
    let data = []

    for (let i = 0; i < response[0].result.length; i++) {
        let obj = {}

        if (map.has(response[0].result[i].entity__superentity__name)) {
            total += response[0].result[i].count;
            obj.label = map.get(response[0].result[i].entity__superentity__name)
            obj.value = response[0].result[i].count
            data.push(obj);
        }
    }

    return {data: data, total: total};
};

const getDocumentsBySuperentityAndProvider = async (filter = '') => {
    let query = queryDocumentsBySuperentityAndProvider();

    query[0].filters = filter
        ? filter
        : {}
    const response = await getData(query);
    let providers = ["Repsol/Solred", "Cepsa", "BP", "Otro"]

    let total = 0;
    let datasets = [
        {
            data: [],
            label: "Repsol/Solred",
            backgroundColor: "#ff8200",
        }, { 
            data: [],
            label: "Cepsa",
            backgroundColor: "#da291c",
        }, { 
            data: [],
            label: "BP",
            backgroundColor:"#009a00",
        }, { 
            data: [],
            label: "Otro",
            backgroundColor:"black",
        }
    ];
    let labels = [];
    let abvLables = [];

    for (let i = 0; i < response[0].result.length; i++) {
        total += response[0].result[i].count;
        if (! labels.includes(response[0].result[i].entity__superentity__name)) {
            labels.push(response[0].result[i].entity__superentity__name)
            var words = response[0].result[i].entity__superentity__name.split(" ");
            abvLables.push(words[0])
        }

        let indexSuper = labels.indexOf(response[0].result[i].entity__superentity__name)
        let indexProvider = providers.indexOf(response[0].result[i].supplier__value) 

        datasets[indexProvider].data[indexSuper] = response[0].result[i].count;
    }

    let data = {labels: abvLables, datasets: datasets}

    return {data: data, total: total};
};

const getLitersBySuperentity = async (filter = '') => {
    let query = queryLitersBySuperentity();

    query[0].filters = filter
        ? filter
        : {}
    
    const response = await getData(query)

    const map = new Map();
    map.set('GLV EUROPA INVERSIONES E INTERMEDIACION, S.L.', 'GLV')
        .set('FENADISMER - Federación Nacional de Asociaciones de Transporte de España', 'FENADISMER')
        .set('Estudio jurídico EJASO, S.L.', 'EJASO');

    let total = 0;
    let data = [];

    for (let i = 0; i < response[0].result.length; i++) {
        let obj = {}
        if (map.has(response[0].result[i].entity__superentity__name)) {
            total += response[0].result[i].total_liters;
            obj.label = map.get(response[0].result[i].entity__superentity__name)
            obj.value = response[0].result[i].total_liters
            data.push(obj);
        }
    }

    return {data: data, total: total};
};

const getLitersByProvider = async (filter = '') => {

    let colors = [];
    let map = new Map()
    map.set("Repsol/Solred", "#ff8200")
        .set("Cepsa", "#da291c")
        .set("BP", "#009a00")
        .set("Otro", "black");

    let query = queryLitersByProvider()
    query[0].filters = filter
        ? filter
        : {}

    const response = await getData(query)
    let data = [];

    for (let i = 0; i < response[0].result.length; i++) {
        let obj = {}
        obj.label = response[0].result[i].supplier__value
        obj.value = response[0].result[i].total_liters
        colors.push(map.get(response[0].result[i].supplier__value))
        data.push(obj);
    }

    return {data: data, colors: colors};
};

const getLitersByYearAndProvider = async (filter = '') => {
    let query = queryLitersByYearAndProvider()
    query[0].filters = filter
        ? filter
        : {}

    const response = await getData(query);
    let providers = ["Repsol/Solred", "Cepsa", "BP", "Otro"]

    let datasets = [
        {
            data: [],
            label: "Repsol/Solred",
            backgroundColor: "#ff8200",
        }, { 
            data: [],
            label: "Cepsa",
            backgroundColor: "#da291c",
        }, { 
            data: [],
            label: "BP",
            backgroundColor:"#009a00",
        }, { 
            data: [],
            label: "Otro",
            backgroundColor:"black",
        }
    ];

    let labels = [];

    for (let i = 0; i < response[0].result.length; i++) {
        if (! labels.includes(response[0].result[i].year)) {
            labels.push(response[0].result[i].year)
        }

        let indexYear = labels.indexOf(response[0].result[i].year)
        let indexProvider = providers.indexOf(response[0].result[i].supplier__value) 

        datasets[indexProvider].data[indexYear] = response[0].result[i].total_liters;
    }

    return {labels: labels, datasets: datasets};
};

const getAsociaciones = async (isAdmin) => {

    let data;
    if (isAdmin) {
        let query = queryAsociaciones()
        const response = await getData(query)
        data = [{id: 0, value: 'TODOS'}]
    
        for (let i = 0; i < response[0].result.length; i++) {
            data.push({id: response[0].result[i].id, value: response[0].result[i].name})
        }
    } else {
        data = [{id: 3, value: 'GLV EUROPA INVERSIONES E INTERMEDIACION, S.L.'}]
        //data = [{id: 2, value: 'FENADISMER - Federación Nacional de Asociaciones de Transporte de España'}]
    }

    return data;
};

const getEntities = async (filter = 0) => {
    let query = queryEntities()
    query[0].filters = filter > 0
        ? {superentity__id: filter}
        : {}

    const response = await getData(query)
    let data = [{id: 0, value: 'TODOS'}]

    for (let i = 0; i < response[0].result.length; i++) {
        data.push({id: response[0].result[i].id, value: response[0].result[i].name})
    }

    return data;
};


const getCompanies = async (filter = 0) => {
    let query = queryCompanies()
    query[0].filters = filter > 0
        ? {entity__id: filter}
        : {}
    const response = await getData(query)

    let data = [{id: 0, value: 'TODOS'}]

    for (let i = 0; i < response[0].result.length; i++) {
        data.push({id: response[0].result[i].id, value: response[0].result[i].name})
    }

    return data;
};


export { getClientsBySuperentity, getDocumentsBySuperentityAndProvider, getCompanies, getEntities, getAsociaciones, getLitersByYearAndProvider, getLitersByProvider, getLitersBySuperentity }