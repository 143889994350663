import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import PrivateRoute from './components/PrivateRoute/';
import { Main as MainLayout } from './layouts';
import { Secondary as SecondaryLayout } from './layouts';
 
import {
  WithdrawalPage as WithdrawalPageView,  
  QuestionnairePage as QuestionnairePageView,
  LandingPage as LandingPageView,
  RegisterPage as RegisterPageView,
  ActivateAccountPage as ActivateAccountPageView,
  LoginPage as LoginPageView,
  DocumentsPage as DocumentsPageView,
  InformationPage as InformationPageView,
  RequestPasswordPage as RequestPasswordPageView,
  RequestPasswordConfirmationPage as RequestPasswordConfirmationPageView,
  ResetPasswordPage as ResetPasswordPageView,
  TermsPage as TermsPageView,
  FAQPage as FAQPageView,
  ReportPage as ReportPageView,
  ReportPageAbby as ReportPageAbbyView,
  CompanyPage as CompanyPageView,
  ValidateDocsPage as ValidateDocsPageView,
  ReportPageNew as ReportPageNew
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/landingpage" />
      <PrivateRoute exact path="/" layout={MainLayout} component={LandingPageView} />
      <RouteWithLayout
        component={QuestionnairePageView}
        exact
        layout={MainLayout}
        path="/questionnaire"
      />

      <RouteWithLayout
        component={ WithdrawalPageView }
        exact
        layout={SecondaryLayout}
        path="/autorizacion/:id"
      />

      <RouteWithLayout
        component={ WithdrawalPageView }
        exact
        layout={SecondaryLayout}
        path="/prescripcion_coches/:id"
      />

      <RouteWithLayout
        component={LoginPageView}
        exact
        layout={MainLayout}
        path="/login"
      />
      <RouteWithLayout
        component={RequestPasswordPageView}
        exact
        layout={MainLayout}
        path="/request-password"
      />
      <RouteWithLayout
        component={RequestPasswordConfirmationPageView}
        exact
        layout={MainLayout}
        path="/request-password-confirmation"
      />
      <RouteWithLayout
        component={ResetPasswordPageView}
        exact
        layout={MainLayout}
        path="/reset-password/:uid/:key"
      />
      <RouteWithLayout
        component={ResetPasswordPageView}
        exact
        layout={MainLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={TermsPageView}
        exact
        layout={MainLayout}
        path="/terms"
      />
      <PrivateRoute
        component={LandingPageView}
        exact
        layout={MainLayout}
        path="/landingpage"
      />
      <RouteWithLayout
        component={RegisterPageView}
        exact
        layout={MainLayout}
        path="/registerpage"
      />
      <RouteWithLayout
        component={ActivateAccountPageView}
        exact
        layout={MainLayout}
        path="/activate-account/:uid/:key"
      />
      <PrivateRoute
        exact
        path="/documentspage"
        layout={MainLayout}
        component={DocumentsPageView}
      />
      <PrivateRoute
        exact
        path="/informationpage"
        layout={MainLayout}
        component={InformationPageView}
      />
      <PrivateRoute
        exact
        path="/faqpage"
        layout={MainLayout}
        component={FAQPageView}
      />
      <PrivateRoute
        exact
        user_access_level={{view_only:false}}
        path="/reportpage"
        layout={MainLayout}
        component={ReportPageView}
      />

      <PrivateRoute
        exact
        user_access_level={{is_admin:true, is_local:false}}
        path="/reportpage2"
        layout={MainLayout}
        component={ReportPageNew}
      />

      <PrivateRoute
        exact
        user_access_level={{is_admin:false, view_only: true}}
        path="/reportpageGLV"
        layout={MainLayout}
        component={ReportPageNew}
      />


      <PrivateRoute
        exact
        path="/companyspage"
        layout={MainLayout}
        component={CompanyPageView}
      />
      <PrivateRoute
        exact
        user_access_level={{is_admin:true, is_global:true, is_local:false, view_only: false}}
        path="/validatedocspage"
        layout={MainLayout}
        component={ValidateDocsPageView}
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
