import {
  CHANGE_SUPPLIER,
  CHANGE_INVOICE_TYPE,
  CHANGE_DOCUMENT_YEAR,
} from "common/constants/components";

export const changeSupplier = (supplierId) => async (dispatch) => {
  dispatch({
    type: CHANGE_SUPPLIER,
    payload: { supplierId },
  });
};

export const changeInvoiceType = (invoiceTypeId) => async (dispatch) => {
  dispatch({
    type: CHANGE_INVOICE_TYPE,
    payload: { invoiceTypeId },
  });
};


export const changeDocumentYear = (documentYear) => async (dispatch) => {
  dispatch({
    type: CHANGE_DOCUMENT_YEAR,
    payload: { documentYear },
  });
};
