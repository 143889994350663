import { downloadFiles, apiDownloadStaticFile } from 'functions/fetchApi';
import { downloadFileQuery } from './queries';
import {
  GET_DEMO_VIDEO,
} from 'common/constants/data';

export const downloadFile = ({ fileId }) => async (
  dispatch
) => {
  let qry = downloadFileQuery(fileId);

  await downloadFiles(qry).then((resultsets) => {
  })
};

export const downloaddemovideo = (fileName) => async (dispatch) => {
  await apiDownloadStaticFile('Tutorial_afectadosporlaspetroleras.mp4').then((resultset) => {
    if (resultset) {
      if (resultset.result) {
        dispatch({
          type: GET_DEMO_VIDEO,
          payload: { video: URL.createObjectURL(resultset.result) },
        });
      }
    }
  });
}

const downloadStaticFile = (fileName, actionName) => async (
  dispatch
) => {
  await apiDownloadStaticFile(fileName).then((resultset) => {
    if (resultset) {
      if (resultset.result) {
        dispatch({
          type: actionName,
          payload: { video: resultset.result },
        });
      }
    }
  });
}