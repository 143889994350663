import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import TableCompany from './components/TableCompany/';

export default class ValidateDocsPage extends Component {
  constructor() {
    super();
    this.state = {};
    this.references = {};
    this.handleUserStatus = this.handleUserStatus.bind(this);
  }

  getOrCreateRef(id) {
    if (!Object.keys(this.references.indexOf(id))) {
      this.references[id] = React.createRef();
    }
    return this.references[id];
  }

  async handleUserStatus() {
    await this.props.getUser();
  }

  render() {
    // this.handleUserStatus()
    return (
      <div style={{ padding: 24 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableCompany title="Look at all you companies!!!" />
          </Grid>
        </Grid>
      </div>
    );
  }
}
