import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Grid, Button, Paper, Dialog, AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { loadcompanyarray, createCompany, updateCompany } from "common/actions/data";
import { CompanyAddressDataSection, CompanyDataSection, RepresentantDataSection } from "./components";
import validateCompanyForm from "./validator";
import { notifySuccess, enqueueSnackbar } from "common/actions/notifications";
import CloseIcon from "@material-ui/icons/Close";

class CompanyDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async handleSubmit(formValues) {

        formValues.name = formValues.name ? formValues.name : '';
        formValues.last_name_2 = formValues.last_name_2 ? formValues.last_name_2 : '';
        formValues.last_name_1 = formValues.last_name_1 ? formValues.last_name_1 : '';
        formValues.demanda = 2;

        if (formValues.type === 2) {
            formValues.comercial_name = formValues.comercial_name != undefined && formValues.comercial_name != "" ? formValues.comercial_name : formValues.first_name  + " " + formValues.last_name_1 + " " + formValues.last_name_2;
            formValues.name =  formValues.comercial_name;
     
        } else if (formValues.type === 3) {
            formValues.name = formValues.name !== '' ? formValues.name : formValues.first_name + " " + formValues.last_name_1 + " " + formValues.last_name_2;
        }

        formValues.id_number = formValues.id_number.toUpperCase().replaceAll(" ", "");;
        console.log("datos" + JSON.stringify(formValues) );
        delete formValues.has_additional_doc;
        delete formValues.has_contract;
        delete formValues.has_paid;
        delete formValues.has_invoices;
        delete formValues.has_liter_summary;
        delete formValues.has_questionnaire;

        let notifyMsg = "";
        let createData= "";
        switch (this.props.mode) {
            case "edit":
                notifyMsg = "Se han editado el cliente correctamente";
                createData= await this.props.updateCompany(formValues);
                break;
            case "create":
                notifyMsg = "Se ha creado el cliente correctamente";
                createData= await this.props.createCompany(formValues);
                break;
            default:
                break;
        }

        if (this.props.mode == "create") {
            if (createData[0].result[0] != undefined) {
            this.props.notifySuccess(notifyMsg);
            } else {
                var notify={};
                notify.message="Ha ocurrido un error";
                this.props.enqueueSnackbar(notify); 
            }
        } else {
            this.props.notifySuccess(notifyMsg);
        }    
        this.props.loadcompanyarray();
    }

    render() {
        return (
            <Fragment>
                <Dialog
                    fullScreen
                    onClose={() => { this.props.onCloseDialog(); }}
                    open={this.props.open}
                >

                    <AppBar style={{ position: "relative", background: "#7E79B0" }}>
                        <Toolbar>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                edge="start"
                                onClick={() => { this.props.onCloseDialog(); }}
                            >
                            <CloseIcon />
                            </IconButton>
                            <Typography style={{ marginLeft: 2, flex: 1 }} variant="h6">
                                { this.props.mode === "edit" ? "Editar Cliente" : "Añadir Cliente" }
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Paper style={{ width: 500, marginTop: 30, marginBottom: 30, padding: 15 }}>
                            <form autocomplete="off"
                                onSubmit={ this.props.handleSubmit(this.handleSubmit.bind(this)) }
                                className="ui form">
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    spacing={3} >
                                    <CompanyDataSection
                                        initialValues={this.props.initialValues}
                                        mode={this.props.mode}
                                    />
                                    <RepresentantDataSection
                                        initialValues={this.props.initialValues}
                                        mode={this.props.mode}
                                    />
                                    <CompanyAddressDataSection
                                        initialValues={this.props.initialValues}
                                        mode={this.props.mode}
                                    />

                                    <Grid item xs={12}>
                                        <Button
                                            style={{ marginTop: 20 }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            > {"Guardar Datos"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Dialog>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

CompanyDialog.propTypes = {
  mode: PropTypes.string.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  initialValues: PropTypes.object,
};

CompanyDialog = connect(mapStateToProps, {
  notifySuccess,
  updateCompany,
  enqueueSnackbar,
  createCompany,
  loadcompanyarray,
})(CompanyDialog);

CompanyDialog = connect()(CompanyDialog);

export default reduxForm({
  form: "companyForm",
  validate: validateCompanyForm,
  enableReinitialize: true,
})(CompanyDialog);
