import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
  Grid,  
} from "@material-ui/core";
import validateCompanyForm from "../validator";
import { renderTextField } from './helper'
const maxLength = max => value =>
  value && value.length > max ? `Máximo ${max} caracteres o menos` : undefined
const maxLength15 = maxLength(60)
export class RepresentantDataSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      streetTypeArray: [{ value: "calle" }, { value: "vía" }],
      selectedCompanyType: "",
    };
    
  }  
  render() {
    return (
      <Fragment>
        <Grid item xs={12}>
          <Field
           validate={[ maxLength15]}
            name="first_name"
            type="text"
            disabled={this.props.initialValues.is_closed}
            component={renderTextField}
            label="Nombre Representante"
            style={{ width: 250 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
           validate={[ maxLength15]}
            name="last_name_1"
            type="text"
            component={renderTextField}
            label="Primer Apellido Representante"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
           validate={[ maxLength15]}
            name="last_name_2"
            type="text"
            component={renderTextField}
            label="Segundo Apellido Representante"
            style={{ width: 250 }}
            disabled={this.props.initialValues.is_closed}
          />
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  data: state.data,  
  valuesEntityCompany: state.valuesEntityCompany,  
});

RepresentantDataSection = connect(mapStateToProps, {    
})(RepresentantDataSection);

export default reduxForm({
  form: "companyForm",
  validate: validateCompanyForm,
})(RepresentantDataSection);
