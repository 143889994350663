import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { FormControlSelector, KpiCard, KpiCardAbbyy, FormControlTextInput, Funnel } from 'components';
import { LineChart, PieChart, BarChart, Doughnut, BarChartHor, StackedBar } from 'components/Charts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import If, { Else } from 'if-else-react';
import D3Funnel from 'd3-funnel';
 
import { loaddocumentoverviewdata, loadinvoicedetailsdata, loadfunnelabbyy, loadliterdetailsdata,  loadentityarrayabbby, loadcompanyarrayabbby, loadsupplierarrayabbby, loaddatearrayabbby, loadprovincearrayabbby, loadstatearrayabbby, loadgraphQueriesAbby, loadtotaldocuments, loadstatusInvoiceAbbyy } from 'common/actions/data';
import {
  calculatePageNumber,
  composeStateDocumentstDataAbbyy,
  composeStateInvoiceAbbyy,
  composeFuelChartDatasets,
  composeFuelChartDatasetsAbby,
  composelitersChartDatasetsAbby,
  composeToltalpriceChartDatasetsAbby,
  composeFuelChartDatasetsDateAbby,
  composeLiterProvinceDataAbby,
  composeSuppliersChartData,
  composeLitreChartDataAbbyy,
  composeInvoicesChartDataAbbyy,
  graphAbbyyCompose,
  composeInvoicesChartDataset,
  calculateLitersSummary,
  calculateCompanysSummary,
  calculateInvoicesSummary,
  calculateSummaryDetailsAbby
  // composeFuelPieChartData
} from './data';

import { filterData } from 'functions/filterData';
import {
  sliceLiterSummaryDetails,
  sliceCompany,
  sliceInvoiceDetails,
  loadcompanyarray
} from 'common/actions/data';
import { autofill } from 'redux-form';

import {
  changeEntity,
  changeCompany 
} from "common/actions/components";
import { CLEAR_ASYNC_ERROR } from 'redux-form/lib/actionTypes';


class ReportPage extends Component {
  constructor() {
    super();
    
    this.state = {
      filters: {
        default_all_selected: 'default',
        entity_selected: 'default', //default value,
        company_selected: 'default',
        supplier_selected: 'default',
        invoice_type_selected: 'default',
        year_selected: 'default',
        deposit_owner_field: 'total',
        deposit_owner_field_abby: 'total',
        province_abby_selected:'default',
        company_abby_selected:'default',
        entity_abby_selected:'default',
        supplier_abby_selected:'default',
        date_abby_selected:'default',
      },
      selectors: {
        entity_options: [],
        company_options: [],
        supplier_options: [],
        invoice_type_options: [],
        year_options: [],
        entity_abby_options: [],
        province_abby_options:[],
        company_abby_options:[],
        supplier_abby_options:[],
        date_abby_options: []
      },
      data: {
        fuelBarChartDataset: [],
        graphAbbyy: [],
        fuelBarChartDatasetAbby: [],
        litersBarChartDatasetAbby:[],
        totapriceBarChartDatasetAbby: [],
        fuelBarChartDatasetDateAbby: [], 
        fuelBarChartDatasetprovinceAbby:[],
        summaryDetailsAbby: {
          total_pagesnumber_rejected: 0,
          total_pagesnumber_valid: 0,
          total_documents: 0,
          total_invoices_rejected: 0,
          total_invoices_valid: 0,
          total_liters: 0,
          total_price: 0,
          total_valid: 0,
          total_invalid: 0,
        },
        summaryDetails: {
          total: 0,
          sum_liter_service_station: 0,
          sum_liter_own_deposits: 0,
        },
        companysDetails: {
          total: 0,
          total_closed: 0,
          total_opened: 0,
        },
        invoicesDetails: {
          total: 0,
          total_inspected: 0,
          total_processed: 0,
        },
        invoicesAbbyDetails: {
          total: 0,
          total_inspected: 0,
          total_processed: 0,
        },
        invoicesLineChartDataset: [],
        invoicesPieChartDataset: [],
        invoicesPieChartDatasetAbbyy: [],
        litrePieChartDatasetAbbyy: [],
        stateDocuments:[],
        stateInvoiceAbbyy:[],
        visibilityPage: true,
        visibilityPageAbby: false,
      },

    };

  }
 async callGraph(filters){ 

   var filter={};
    if( filters.entity_abby_selected != 'default'){
      filter.abbydocument__abbybatch__entityfile__entity= filters.entity_abby_selected;
    }
    if( filters.company_abby_selected != 'default'){
      filter.abbydocument__abbybatch__entityfile__company= filters.company_abby_selected;
    }
    if( filters.date_abby_selected != 'default'){
      filter.year= filters.date_abby_selected;
    }
   
    if( filters.supplier_abby_selected != 'default') {
        let name = filters.supplier_abby_selected;

        if (name == "REPSOL/SOLRED") {
            filter.vendor_alias__in = ["REPSOL", "SOLRED"];
        } else if (name == 'CEPSA/RESSA') {
            filter.vendor_alias__in = ["CEPSA", "RESSA"];
        } else if (name == 'BP') {
            filter.vendor_alias__in = ["BP"];
        } else {
            filter.vendor_alias__in = ["OTROS"];
        }
    }

    if( filters.province_abby_selected != 'default'){
      filter.client_province= filters.province_abby_selected;
    }
    
    await this.props.loadtotaldocuments(filter);
    await this.props.loadgraphQueriesAbby(filter);
    let data = this.applyFilters(this.state.filters);
    this.setState({ data: data });
  }
  updateFilters(filters) {
   
    let entityIds =
      filters.entity_selected === this.state.filters.default_all_selected
        ? undefined
        : [filters.entity_selected];
    let companyIds =
      filters.company_selected === this.state.filters.default_all_selected
        ? undefined
        : [filters.company_selected];
    let entityAbbyIds =
        filters.entity_abby_selected === this.state.filters.default_all_selected
          ? undefined
          : [filters.entity_abby_selected];
    let companyAbbyIds =
        filters.company_abby_selected === this.state.filters.default_all_selected
          ? undefined
          : [filters.company_abby_selected];
    let provinceIds =
    filters.province_abby_selected === this.state.filters.default_all_selected
      ? undefined
      : [filters.province_abby_selected];
    let supplierIds =
      filters.supplier_selected === this.state.filters.default_all_selected
        ? undefined
        : [filters.supplier_selected];
    let supplierAbbyIds =
      filters.supplier_abby_selected === this.state.filters.default_all_selected
        ? undefined
        : [filters.supplier_abby_selected];
    let years =
      filters.year_selected === this.state.filters.default_all_selected
        ? undefined
        : [filters.year_selected];
     let yearsAbby =
        filters.date_abby_selected === this.state.filters.default_all_selected
          ? undefined
          : [filters.date_abby_selected];
    let invoiceTypeIds =
      filters.invoice_type_selected === this.state.filters.default_all_selected
        ? undefined
        : [filters.invoice_type_selected];
       
    this.props.sliceLiterSummaryDetails({
      companyIds,
      entityIds,
      supplierIds,
      years,
    });

    this.props.sliceCompany({
      companyIds,
      entityIds,
    });
  
    this.props.sliceInvoiceDetails({
      companyIds,
      entityIds,
      supplierIds,
      invoiceTypeIds,
      years,
    });

    this.setState({
      filters: filters,
    });
  }
 
 
 
  
   applyFilters(prev_local_state) {
     
    let data = {
      fuelBarChartDataset: [],
      graphAbbyy: [],
      fuelBarChartDatasetAbby: [],
      litersBarChartDatasetAbby:[],
      totapriceBarChartDatasetAbby: [],
      fuelBarChartDatasetDateAbby: [], 
      fuelBarChartDatasetprovinceAbby:[],
      summaryDetailsAbby: {
        total_pagesnumber_rejected: 0,
        total_pagesnumber_valid: 0,
        total_documents: 0,
        total_invoices_rejected: 0,
        total_invoices_valid: 0,
        total_liters: 0,
        total_price: 0,
        total_valid: 0,
        total_invalid: 0,
      },
      summaryDetails: {
        total: 0,
        sum_liter_service_station: 0,
        sum_liter_own_deposits: 0,
      },
      companysDetails: {
        total: 0,
        total_closed: 0,
        total_opened: 0,
      },
      invoicesLineChartDataset: [],
      invoicesPieChartDataset: [],
      invoicesPieChartDatasetAbbyy:[],
      litrePieChartDatasetAbbyy:[],
      stateDocuments:[],
      stateInvoiceAbbyy:[]
    };

    let literSummaryArrayFiltered = this.props.literSummaryArray
  
    let companyArrayFiltered = this.props.companyArray
    let invoiceDetailsArrayFiltered = this.props.invoiceDetailsArray
    if (prev_local_state) {
      literSummaryArrayFiltered = filterData(
        this.props.literSummaryArray,
        this.props.literSummaryDetailsSlicer
      );
      
      companyArrayFiltered = filterData(
        this.props.companyArray,
        this.props.companySlicer
      );
      invoiceDetailsArrayFiltered = filterData(
        this.props.invoiceDetailsArray,
        this.props.invoiceDetailsSlicer
      );
    }
     data.summaryDetailsAbby = calculateSummaryDetailsAbby(this.props.graphQueriesAbby, this.props.totaldocumentsabbyy, this.props.funnelAbbyy);
    data.summaryDetails = calculateLitersSummary(literSummaryArrayFiltered);
    data.companysDetails = calculateCompanysSummary(companyArrayFiltered);
    data.invoicesDetails = calculateInvoicesSummary(invoiceDetailsArrayFiltered);

    data.invoicesLineChartDataset = composeInvoicesChartDataset(
      invoiceDetailsArrayFiltered
    );
    data.fuelBarChartDataset = composeFuelChartDatasets(
      literSummaryArrayFiltered,
      this.state.filters.deposit_owner_field
    );
   

    data.invoicesPieChartDataset = composeSuppliersChartData(
      invoiceDetailsArrayFiltered
    );
  
     
   
      data.graphAbbyy = graphAbbyyCompose( this.props.graphQueriesAbby);

      
      data.invoicesPieChartDatasetAbbyy = composeInvoicesChartDataAbbyy(
        this.props.graphQueriesAbby, 
        this.props.provinceArrayAbbyy
      );
      data.litrePieChartDatasetAbbyy = composeLitreChartDataAbbyy(
        this.props.graphQueriesAbby, 
        this.props.provinceArrayAbbyy
      );
      data.stateDocuments=  composeStateDocumentstDataAbbyy(
        this.props.funnelAbbyy, 
      
      );
      data.stateInvoiceAbbyy=  composeStateInvoiceAbbyy(
      this.props.funnelAbbyy
      );
      
      data.fuelBarChartDatasetDateAbby= composeFuelChartDatasetsDateAbby( 
        this.props.graphQueriesAbby
      );
      data.fuelBarChartDatasetprovinceAbby= composeLiterProvinceDataAbby( 
        this.props.graphQueriesAbby
      );
      
      
   
      data.totapriceBarChartDatasetAbby = composeToltalpriceChartDatasetsAbby( 
        this.props.graphQueriesAbby, 
        this.props.supplierArrayAbbyy,
        data.fuelBarChartDatasetDateAbby
        );
      data.fuelBarChartDatasetAbby = composeFuelChartDatasetsAbby( 
        this.props.graphQueriesAbby, 
        this.props.supplierArrayAbbyy,
        data.fuelBarChartDatasetDateAbby
        );
        data.litersBarChartDatasetAbby = composelitersChartDatasetsAbby( 
          this.props.graphQueriesAbby, 
          this.props.provinceArrayAbbyy,
          data.fuelBarChartDatasetprovinceAbby
          );
      

   
        console.log("1 " + JSON.stringify(data.fuelBarChartDatasetAbby) )
        console.log("2" + JSON.stringify(data.fuelBarChartDatasetDateAbby) )
        console.log("--------------");
      
  
        console.log("1 " + JSON.stringify(data.litersBarChartDatasetAbby) )
        console.log("2" + JSON.stringify(data.fuelBarChartDatasetprovinceAbby) )
        
    return data;
  }


 
 
  composeSelectorsData() {
 
    let selectors = {
      entity_options: [{ value: 'Todos', id: 'default' }],
      company_options: [{ value: 'Todos', id: 'default' }],
      supplier_options: [{ value: 'Todos', id: 'default' }],
      invoice_type_options: [{ value: 'Todos', id: 'default' }],
      year_options: [{ value: 'Todos', id: 'default' }],
      entity_abby_options: [{ value: 'Todos', id: 'default' }],
      province_abby_options: [{ value: 'Todos', id: 'default' }],
      company_abby_options: [{ value: 'Todos', id: 'default' }],
      supplier_abby_options: [{ value: 'Todos', id: 'default' }],
      date_abby_options: [{ value: 'Todos', id: 'default' }],

    };

    selectors.entity_options.push(
      ...this.props.entityArray.map((entity) => {
        return { value: entity.value, id: entity.id };
      })
    );
    selectors.entity_abby_options.push( 
      ...this.props.entityArrayAbbyy.map((entityAbby) => {
     
        return { value: entityAbby.value, id: entityAbby.entityfile__entity__id };
      })
    );
    selectors.company_options.push(
      ...this.props.companyArray.map((company) => {
        return { value: company.value, id: company.id };
      })
    );
    
    selectors.province_abby_options.push(
      ...this.props.provinceArrayAbbyy.map((year) => {
        return { value: year.client_province, id: year.client_province };
      })
    );
     selectors.company_abby_options.push(
      ...this.props.companyArrayAbbyy.map((company) => {
        return { value: company.value, id: company.entityfile__company__id };
      })
    );

    selectors.supplier_abby_options.push({value: "REPSOL/SOLRED", id: "REPSOL/SOLRED"});
    selectors.supplier_abby_options.push({value: "CEPSA/RESSA", id: "CEPSA/RESSA"});
    selectors.supplier_abby_options.push({value: "BP", id: "BP"});
    selectors.supplier_abby_options.push({value: "OTROS", id: "OTROS"});
  
    selectors.date_abby_options.push(
      ...this.props.dateArrayAbbyy.map((year) => {
        return { value: year.value, id: year.year };
      })
    );

    selectors.supplier_options.push(
      ...this.props.supplierArray.map((supplier) => {
        return { value: supplier.value, id: supplier.id };
      })
    );

    selectors.invoice_type_options.push(
      ...this.props.invoiceTypeArray.map((invoice_type) => {
        return { value: invoice_type.value, id: invoice_type.id };
      })
    );
    const uniqueById = this.uniqueBy('id');

    selectors.year_options.push(
      ...this.props.literSummaryArray.map((item) => {
        return { value: item.year, id: item.year };
      })
    );

    selectors.year_options.push(
      ...this.props.invoiceDetailsArray.map((item) => {
        return { value: item.year, id: item.year };
      })
    );

    selectors.year_options = uniqueById(selectors.year_options).sort(function (
      a,
      b
    ) {
      return a.value - b.value;
    });
    return selectors;
  }
  
  uniqueBy = (prop) => (list) => {
    const uniques = {};
    return list.reduce((result, item) => {
      if (uniques[item[prop]]) return result;
      uniques[item[prop]] = item;
      return [...result, item];
    }, []);
  };

  componentDidUpdate(prev_global_state, prev_local_state) {
   
    if (this.state.filters !== prev_local_state.filters) {
      let data = this.applyFilters(prev_local_state.filters);
      this.setState({ data: data });
    }
  }
async  componentDidMount() {
   
    var filter={};
   // filter.year= 2019;  
    
   await this.props.loadinvoicedetailsdata();
   await this.props.loadfunnelabbyy();
   
   await this.props.loadliterdetailsdata();  
   await  this.props.loadtotaldocuments(filter);
   await this.props.loadentityarrayabbby();
   await  this.props.loadcompanyarrayabbby();
   await  this.props.loadsupplierarrayabbby();
   await  this.props.loaddatearrayabbby();
   await  this.props.loadprovincearrayabbby();
 
   await  this.props.loadstatusInvoiceAbbyy(filter);

   
    
   await  this.props.loadgraphQueriesAbby(filter);
   
 
  
   await   this.props.sliceLiterSummaryDetails({});
   await  this.props.sliceCompany({});
   await  this.props.sliceInvoiceDetails({});
    
    let selectors = this.composeSelectorsData();
    let data = this.applyFilters();
 console.log("va")
    this.setState({ data: data, selectors: selectors }); 


  }


  render() {
    

     

    return (
    
    
     
      <Grid container direction="row" justify="center" alignItems="center">
       
        <div style={{ width: '90%', marginTop: 30, padding: 15 }}>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Tabs aria-label="simple tabs example">
              <TabList>
                <Tab label="Informe">Informe</Tab>
                {!this.props.userinfo.is_local &&
                <Tab label="Abbyy">Informes de ABBYY</Tab>
                }
              </TabList>

              <TabPanel>
               <Grid
              container
              direction="row"
              spacing={3}
              style={{ marginBottom: 15 }}
            >
              {/* <ArrowTooltip
                title="Filtros"
                placement="top"
              > */}
              <Grid item xs>
                <Paper style={{ padding: 15 }}>
                  <FormControlSelector
                    value={this.state.filters.entity_selected}
                    onChange={(e) => {
                      this.updateFilters({
                        ...this.state.filters,
                        entity_selected: e.target.value,
                      });
                    }}
                    data={this.state.selectors.entity_options}
                    label="Entidad"
                    id_key="supplier"
                  />
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper style={{ padding: 15 }}>
                  {' '}
                  <FormControlSelector
                    value={this.state.filters.company_selected}
                    onChange={(e) => {
                      this.updateFilters({
                        ...this.state.filters,
                        company_selected: e.target.value,
                      });
                    }}
                    data={this.state.selectors.company_options}
                    label="Cliente"
                    id_key="supplier"
                  />
                </Paper>
              </Grid>
              <Grid item xs>
                <Paper style={{ padding: 15 }}>
                  {' '}
                  <FormControlSelector
                    value={this.state.filters.supplier_selected}
                    onChange={(e) => {
                      this.updateFilters({
                        ...this.state.filters,
                        supplier_selected: e.target.value,
                      });
                    }}
                    data={this.state.selectors.supplier_options}
                    label="Proveedor"
                    id_key="supplier"
                  />
                </Paper>
              </Grid>
              {/* <Grid item xs>
                <Paper style={{ padding: 15 }}>
                  {' '}
                  <FormControlSelector
                    value={this.state.filters.invoice_type_selected}
                    onChange={(e) => {
                      this.updateFilters({
                        ...this.state.filters,
                        invoice_type_selected: e.target.value,
                      });
                    }}
                    data={this.state.selectors.invoice_type_options}
                    label="Tipo de Factura"
                    id_key="supplier"
                  />
                </Paper>
              </Grid> */}
              <Grid item xs>
                <Paper style={{ padding: 15 }}>
                  {' '}
                  <FormControlSelector
                    value={this.state.filters.year_selected}
                    onChange={(e) => {
                      this.updateFilters({
                        ...this.state.filters,
                        year_selected: e.target.value,
                      });
                    }}
                    data={this.state.selectors.year_options}
                    label="Año"
                    id_key="supplier"
                  />
                </Paper>
              </Grid>
              {/* </ArrowTooltip> */}
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={3}
                  alignItems="center"
                >
                
                  <Grid item xs>
                    <KpiCard
                      kpiTitle="Facturas subidas"
                      kpiMainTitle="Total"
                      kpiMain={this.state.data.invoicesDetails.total}
                      kpiSub1Title="Revisadas"
                      kpiSub1={this.state.data.invoicesDetails.total_inspected}
                      kpiSub2Title="Procesadas"
                      kpiSub2={this.state.data.invoicesDetails.total_processed}
                      decimalScale={0}
                    />
                  </Grid>
                  <Grid item xs>
                    <KpiCard
                      kpiTitle="Clientes"
                      kpiMainTitle="Total"
                      kpiMain={this.state.data.companysDetails.total}
                      kpiSub1Title="Abiertos"
                      kpiSub1={this.state.data.companysDetails.total_opened}
                      kpiSub2Title="Cerrados"
                      kpiSub2={this.state.data.companysDetails.total_closed}
                      decimalScale={0}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            ></Grid>
            <Grid item xs={12} style={{ paddingTop: 30 }}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={8}>
                  <Paper style={{ padding: 15, height: 300 }}>
                    <Typography variant="h5" style={{ textAlign: 'center' }}>
                      Facturas subidas
                    </Typography>
                    <LineChart
                      datasets={this.state.data.invoicesLineChartDataset}
                      title="Facturas Subidas a la plataforma"
                      color="#7E79B0"
                      timeXAxe={{
                        unit: 'year',
                        displayFormats: {
                          day: 'YYYY',
                        },
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper style={{ padding: 15, height: 300 }}>
                    {' '}
                    <Typography variant="h5" style={{ textAlign: 'center' }}>
                      Distribución de las facturas según su proveedor
                    </Typography>
                    <PieChart
                      data={this.state.data.invoicesPieChartDataset}
                      title="test 2"
                      colors={['#7E79B0', '#b1aecf', '#4b4869', '#252434']}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
              </TabPanel>
              {/* ABBYY
              =================================== */}
              <TabPanel>

            
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ marginBottom: 15 }}
                >
                  <Grid item xs>
                    <Paper style={{ padding: 15 }}>
                      <FormControlSelector
                        value={this.state.filters.entity_abby_selected}
                        onChange={(e) => {
                         
                          
                          this.updateFilters({
                            ...this.state.filters,
                            entity_abby_selected: e.target.value,
                          });
                          this.callGraph({
                            ...this.state.filters,
                            entity_abby_selected: e.target.value,
                          });
                     
                        }}
                        data={this.state.selectors.entity_abby_options}
                        label="Organización territorial"
                        id_key="supplier"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper style={{ padding: 15 }}>
                      {' '}
                      <FormControlSelector
                        value={this.state.filters.company_abby_selected}
                      
                        onChange={(e) => {
                         this.updateFilters({
                            ...this.state.filters,
                            company_abby_selected: e.target.value,
                          });
                          this.callGraph({
                            ...this.state.filters,
                            company_abby_selected: e.target.value,
                          });
                        }}
                        data={this.state.selectors.company_abby_options}
                        label="Cliente"
                        id_key="supplier"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper style={{ padding: 15 }}>
                      {' '}
                      <FormControlSelector
                        value={this.state.filters.supplier_abby_selected}
                        onChange={(e) => {
                        
                          this.updateFilters({
                            ...this.state.filters,
                            supplier_abby_selected: e.target.value,
                          });
                          this.callGraph({
                            ...this.state.filters,
                            supplier_abby_selected: e.target.value,
                          });
                        }}
                        data={this.state.selectors.supplier_abby_options}
                        label="Proveedor"
                        id_key="supplier"
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs>
                    <Paper style={{ padding: 15 }}>
                      {' '}
                      <FormControlSelector
                        value={this.state.filters.date_abby_selected}
                        onChange={(e) => {
                        
                          this.updateFilters({
                            ...this.state.filters,
                            date_abby_selected: e.target.value,
                          });
                          this.callGraph({
                            ...this.state.filters,
                            date_abby_selected: e.target.value,
                          });
                        }}
                        data={this.state.selectors.date_abby_options}
                        label="Años"
                        id_key="supplier"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs>
                    <Paper style={{ padding: 15 }}>
                      {' '}
                      <FormControlSelector
                        value={this.state.filters.province_abby_selected}
                        
                        onChange={(e) => {
                          this.updateFilters({
                            ...this.state.filters,
                            province_abby_selected: e.target.value,
                          });
                          this.callGraph({
                            ...this.state.filters,
                            province_abby_selected: e.target.value,
                          });
                        }}
                        data={this.state.selectors.province_abby_options}
                        label="Provincia"
                        id_key="supplier"
                      />
                    </Paper>
                  </Grid>

                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      spacing={3}
                      alignItems="center"
                    >
                        <Grid  item xs={6}>
                     

                     <Paper style={{ padding: 15, height: 330 , width: '100%'}}>
                     {' '}
                     <Typography variant="h5" style={{ textAlign: 'center' }}>
                       Visión Global documentos PDF 
                </Typography>
              
                       <Funnel/>
                       <Typography variant="h6" style={{ textAlign: 'left', fontWeight: 'normal', fontSize: '10px'}}>
                       * Porcentaje respecto al anterior bloque de datos
                   </Typography>
                        
                     </Paper>
                      
                     </Grid> 
        
                      <Grid item xs={6}>
                        <KpiCardAbbyy
                          kpiTitle="Información exportada de ABBYY"
                          kpiMain={this.state.data.summaryDetailsAbby.total_documents}
                          kpiSub1Title="Facturas validas"
                          kpiSub1={this.state.data.summaryDetailsAbby.total_invoices_valid}
                          kpiSub1_2Title="Facturas con error"
                          kpiSub1_2={this.state.data.summaryDetailsAbby.total_invoices_rejected}
                          kpiSub2Title="Páginas validas"
                          kpiSub2={this.state.data.summaryDetailsAbby.total_pagesnumber_valid}
                          kpiSub2_2Title="Páginas con error"
                          kpiSub2_2={this.state.data.summaryDetailsAbby.total_pagesnumber_rejected}
                          kpiSub3Title="Clientes validados"
                          kpiSub3={this.state.data.summaryDetailsAbby.total_valid}
                          kpiSub4Title="Clientes cerrados"
                          kpiSub4={this.state.data.summaryDetailsAbby.total_invalid}
                          kpiSub5Title="Total litros"
                          kpiSub5={this.state.data.summaryDetailsAbby.total_liters}
                          kpiSub6Title="Importe total"
                          kpiSub6={this.state.data.summaryDetailsAbby.total_price }
                          
                         
                          
                         // unittype={'Documentos'}
                        />
                       
                      </Grid>
 
 

                     
                      <Grid item xs={6}>
                      <Paper style={{ padding: 15, height: 376 }}>
                        {' '}
                        <Typography variant="h5" style={{ textAlign: 'center' }}>
                          Estado de documentos PDF
                   </Typography>
                        <Doughnut
                         data={this.state.data.stateDocuments}
                          title="test 2"
                          colors={['#0085c3', '#7ab800', '#f2af00', '#dc5034', "#6e2585", "#444444", "#eeeeee"]}
                          
                        />
                      </Paper>
                    </Grid>
                     
                      <Grid item xs={6}>
                      <Paper style={{ padding: 15, height: 376 }}>
                        {' '}
                        <Typography variant="h5" style={{ textAlign: 'center' }}>
                          Estado de facturas en Abbyy
                   </Typography>
                        <Doughnut
                         data={this.state.data.stateInvoiceAbbyy}
                          title="test 2"
                           colors={['#007dc3', '#003f69', '#954010', '#445c6e', "#005238", "#97824b", "#2d72d9"]}
                          
                        />
                      </Paper>
                    </Grid>  
                      
                    </Grid>
                  </Grid>
                 
         
                  {/* <Grid item xs={6}>
                      <Paper style={{ padding: 15, height: 310 }}>
                        {' '}
                        <Typography variant="h5" style={{ textAlign: 'center' }}>
                        Distribución de importe por Provincia
                  </Typography>
                  <PieChart
                         data={this.state.data.invoicesPieChartDatasetAbbyy}
                          title="test 2"
                          colors={['#74d2e7', '#48a9c5', '#0085ad', '#8db9ca', "#4298b5", "#005670", "#00205b", "#009f4d", "#84bd00", "#efdf00", "#fe5000", "#e4002b", "#da1884", "#a51890", "#a51890", "#0077c8", "#008eaa"]}

                          
                        />
                      </Paper>
                    </Grid>  */}



                  <Grid item xs={12}>
                      <Paper style={{ padding: 15 }}>
                        {' '}
                        <Typography variant="h5" style={{ textAlign: 'center' }}>
                          Distribución de litros por Provincia
                  </Typography>

                   <BarChart
                            datasets={this.state.data.litersBarChartDatasetAbby}
                            labels={this.state.data.fuelBarChartDatasetprovinceAbby}
                            title="Documentos Subidos a la plataforma"
                            colors={['#009677', '#ff9338', '#f95951', '#ff0093', "#0089af", "#008772", "#69c2b0", "#69c2b0", "#69c2b0"]}
                            timeXAxe={{
                              unit: 'year',
                              displayFormats: {
                                year: 'YYYY',
                              },
                            }}
                          />  
{/* 
                        <PieChart
                         data={this.state.data.litrePieChartDatasetAbbyy}
                          title="test 2"
                          colors={['#74d2e7', '#48a9c5', '#0085ad', '#8db9ca', "#4298b5", "#005670", "#00205b", "#009f4d", "#84bd00", "#efdf00", "#fe5000", "#e4002b", "#da1884", "#a51890", "#a51890", "#0077c8", "#008eaa"]}                          
                        /> */}
                      </Paper>
                    </Grid>          


                    {/* <Grid item xs={12}>
                    <Paper style={{ padding: 15 }}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >

                        <Grid item xs={11}>
                          <Typography variant="h5" style={{ textAlign: 'center' }}>
                          Importe anual por Proveedor 
                          </Typography>
                          <BarChart
                            datasets={this.state.data.totapriceBarChartDatasetAbby}
                            labels={this.state.data.fuelBarChartDatasetDateAbby}
                            title="Documentos Subidos a la plataforma"
                            colors={['#009677', '#ff9338', '#f95951', '#ff0093', "#0089af", "#008772", "#69c2b0", "#69c2b0", "#69c2b0"]}
                            timeXAxe={{
                              unit: 'year',
                              displayFormats: {
                                year: 'YYYY',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid> */}


                  <Grid item xs={12}>
                    <Paper style={{ padding: 15 }}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >

                        <Grid item xs={11}>
                          <Typography variant="h5" style={{ textAlign: 'center' }}>
                            Combustible anual por Proveedor
                          </Typography>
                         <BarChart
                            datasets={this.state.data.fuelBarChartDatasetAbby}
                            labels={this.state.data.fuelBarChartDatasetDateAbby}
                            title="Documentos Subidos a la plataforma"
                            colors={['#009677', '#ff9338', '#f95951', '#ff0093', "#0089af", "#008772", "#69c2b0", "#69c2b0", "#69c2b0"]}
                            timeXAxe={{
                              unit: 'year',
                              displayFormats: {
                                year: 'YYYY',
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>


           
  
                </Grid> 
               
              </TabPanel>
            </Tabs>
          </Grid>
        </div>
      </Grid>
      
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data.documentOverviewArray,
  documentAggregatedArray: state.data.documentAggregatedArray,
  invoiceDetailsArray: state.data.invoiceDetailsArray,
  funnelAbbyy:  state.data.funnelAbbyy,
  literSummaryArray: state.data.literSummaryArray,
  supplierArray: state.data.supplierArray,
  entityArray: state.data.entityArray,
  entityArrayAbbyy: state.data.entityArrayAbbyy,
  companyArrayAbbyy: state.data.companyArrayAbbyy,
  supplierArrayAbbyy: state.data.supplierArrayAbbyy,
  dateArrayAbbyy: state.data.dateArrayAbbyy,
  provinceArrayAbbyy: state.data.provinceArrayAbbyy,
  stateArrayAbbyy: state.data.stateArrayAbbyy,
  statusInvoiceAbbyy: state.data.statusInvoiceAbbyy,
  graphQueriesAbby: state.data.graphQueriesAbby,
  totaldocumentsabbyy: state.data.totaldocumentsabbyy,
  companyArray: state.data.companyArray,
  invoiceTypeArray: state.data.invoiceTypeArray, 
  literSummaryDetailsSlicer: state.slicer.literSummaryDetailsSlicer,
  userinfo: state.data.userDataArray,
  invoiceDetailsSlicer: state.slicer.invoiceDetailsSlicer,
  companySlicer: state.slicer.companySlicer,
});

ReportPage = connect(mapStateToProps, {
  changeEntity,
  changeCompany,
  loaddocumentoverviewdata,
  sliceLiterSummaryDetails,
  loadentityarrayabbby,
  loadcompanyarrayabbby,
  loadsupplierarrayabbby,
  loaddatearrayabbby,
  loadprovincearrayabbby,
  loadstatearrayabbby,
  loadstatusInvoiceAbbyy,
  loadgraphQueriesAbby,
  loadtotaldocuments,
  loadinvoicedetailsdata,
  loadfunnelabbyy,
   loadliterdetailsdata,
  sliceCompany,
  sliceInvoiceDetails,
  loadcompanyarray
})(ReportPage);

export default ReportPage;
