import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SimpleTableToolbar from './components/simpleToolbar';
import ConfirmDialog from '../../components/ConfirmDialog';
import DialogMsg from '../../components/DialogMsg';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowTooltip from 'components/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import GetAppIcon from '@material-ui/icons/GetApp';
import Chip from '@material-ui/core/Chip';
import { Backdrop, CircularProgress } from '@material-ui/core';
import {
  useTable,
  // useGroupBy,
  useFilters,
  // useSortBy,
  // useExpanded,
  usePagination,
} from 'react-table';
import {  loaddocumentaggregateddata,
  loaddocumentoverviewdata
} from "common/actions/data";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 650,
  },
});

let SimpleTable = (props) => {
  const classes = useStyles();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openEditRowDialog, setOpenEditRowDialog] = useState(false);
  const [actionSelectedRow, setActionSelectedRow] = useState(null);

  const [openMsgDialog, setOpenMsgDialog] = useState(false);
  const [titleMsgDialog, setTitleMsgDialog] = useState('');
  const [textMsgDialog, setTextMsgDialog] = useState('');
  const [openLoadingScreen, setLoadingScreen] = useState(true);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  var headName="";
  //Filters
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    return (
      <TextField
        value={filterValue }
        onChange={(e) => {
         calldocument(e, headName );
         setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
       
       
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <FormControl style={{ width: 100 }}>
        <Select
          value={filterValue}
          onChange={(e) => {
           
            setFilter(e.target.value || undefined);
          }}
        >
          <MenuItem value="">All</MenuItem>
          {options.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };
 const calldocument= (e, d)=>{
  var filters={};
  var campo= e.target.parentElement.parentElement.parentElement.parentElement.innerText;
  filters.delete_flag= false;
  
    if(campo == "Empresa" || campo == "Nombre"){
      filters.company__name__icontains=e.target.value;
    }else if(campo == "Organización" || campo == "Entidad"){
      filters.entity__name__icontains=e.target.value;
    }else if(campo == "Tipo de documento"){
      filters.document_species__value__icontains=e.target.value;
    }else if(campo == "Nombre de archivo"){
      filters.file__icontains=e.target.value;
    }else if(campo == "Estado"){
      filters.status__value__icontains=e.target.value;
    }else if(campo == "Subido por"){
      filters.upload_by__icontains=e.target.value;
    }
   if(e.target.value.length > 2){
     
    props.loaddocumentaggregateddata(filters);
  props.loaddocumentoverviewdata(filters);
  }
  if(e.target.value.length == 0){
     
    props.loaddocumentaggregateddata(filters);
  props.loaddocumentoverviewdata(filters);
  }
  
 }
  //Standar compoments render
  const renderStandardAction = (action, row) => {
    switch (action.name) {
      case 'delete':
        return renderDeleteAction(row);
      case 'edit':
        return renderEditAction(row);
      case 'download':
        return renderDownloadAction(row, action.callback);
      default:
        break;
    }
  };
  const renderDeleteAction = (row) => {
    return (

        props.viewOnly == false &&
        <ArrowTooltip
            title="Eliminar fila"
            placement="bottom"
            open={props.ishelpdisplayed}
        >
        <IconButton
          aria-label="delete row"
          onClick={() => {
            setOpenConfirmDialog(true);
            setActionSelectedRow(row.values);
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </ArrowTooltip>
    );
  };
  const renderEditAction = (row) => {
    return (
        props.viewOnly == false &&
        <ArrowTooltip
            title="Editar fila"
            placement="bottom"
            open={props.ishelpdisplayed}
        >
        <IconButton
          aria-label="edit row"
          onClick={() => {
            let completedData = props.data.find((d) => d.id === row.values.id);
            setActionSelectedRow(completedData);
            setOpenEditRowDialog(true);
          }}
        >
          <CreateIcon />
        </IconButton>
      </ArrowTooltip>
    );
  };

  const renderDownloadAction = (row, callback) => {
    return (
      <ArrowTooltip
        title="Descargar fichero"
        placement="bottom"
        open={props.ishelpdisplayed}
      >
        <IconButton
          onClick={() => {
            let completedData = props.data.find((d) => d.id === row.values.id);
            setActionSelectedRow(completedData);
            callback(completedData);
          }}
        >
          <GetAppIcon />
        </IconButton>
      </ArrowTooltip>
    );
  };

  const renderCellManager = (cell) => {
    if (!cell.column.customRender) {
      return cell.render('Cell');
    }

    switch (cell.column.customRender) {
      case 'Checkbox':
        return renderCheckbox(cell);
      case 'Button':
        return renderButton(cell);
      case 'Tagg':
        return renderTagg(cell);
      default:
        break;
    }
  };

  const renderCheckbox = (cell) => {
    return (
      <Checkbox
        disabled={cell.column.disabled}
        defaultChecked={cell.value}
        onChange={(event) => onCheckboxChange(event, cell)}
      />
    );
  };

  const onCheckboxChange = (event, cell) => {
    cell.value = !cell.value;

    cell.row.values[cell.column.id] = cell.value;
    cell.row.original[cell.column.id] = cell.value;
    event.target.checked = cell.value;
    event.target.value = cell.value;
  };

  const renderButton = (cell) => {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={(event) => onClick(event, cell)}
      >
        {cell.column.textCallback(cell.value)}
      </Button>
    );
  };

  const renderTagg = (cell) => {
    return (
      <Chip
        size="small"
        style={{ backgroundColor: cell.column.tagStyle[cell.value].color }}
        label={cell.column.tagStyle[cell.value].text}
      />
    );
  };

  const onClick = (event, cell) => {
    let result = cell.column.onChangeCallback(cell.row.values, cell.value);

    if (result.title && result.text) showMsgDialog(result.title, result.text);
  };

  const showMsgDialog = (title, text) => {
    setOpenMsgDialog(true);
    setTitleMsgDialog(title);
    setTextMsgDialog(text);
  };

  // const handleCloseMsgDialog = () => {
  //   setOpenMsgDialog(false);
  // };

  //Standar actions

  const columns = React.useMemo(() => {
    var keys = ['No hay datos disponibles'];
    if (props.data.length > 0) {
      keys = Object.keys(props.data[0]);
    }

    var columns = [];
    for (const name of keys) {
      let column = {
        Header: name,
        accessor: name,
      };
      if (props.columns && props.columns.get(name)) {
        headName=props.columns.get(name);
        column.Header = props.columns.get(name).name;
        if (
          props.columns.get(name).filter &&
          !props.columns.get(name).filter === 'default'
        ) {
          let filter = {};
          switch (props.filters.get(name)) {
            case 'includes':
              filter.Filter = filter.Filter || SelectColumnFilter;
              filter.type = props.filters.get(name);
              break;
            default:
          }

          if (filter) {
            column.Filter = filter.Filter;
            column.filter = filter.type;
          }
        }

        if (props.columns.get(name).onChangeCallback) {
          column.onChangeCallback = props.columns.get(name).onChangeCallback;
        }

        column.disabled = props.columns.get(name).disabled;
        if (props.filters && props.filters.get(name)) {
          let filter = {};
          switch (props.filters.get(name)) {
            case 'includes':
              filter.Filter = filter.Filter || SelectColumnFilter;
              filter.type = props.filters.get(name);
              break;
            default:
          }
        }

        if (props.columns.get(name).action) {
          if (props.columns.get(name).textCallback)
            column.textCallback = props.columns.get(name).textCallback;
        }

        column.customRender = props.columns.get(name).customRender;
        column.tagStyle = props.columns.get(name).tagStyle;
      }

      if (!props.skipOtherColumns) {
        columns.push(column);
      } else {
        if (props.columns && props.columns.get(name)) {
          columns.push(column);
        }
      }
    }
    return columns;
  }, [props]);

  const data = React.useMemo(() => {
    return props.data;
  }, [props]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, defaultColumn }, useFilters, usePagination);

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
  });

  return (

    <Paper className={classes.root}>
      <SimpleTableToolbar
        actionIcon={props.actionIcon}
        actionIconTooltip={props.actionIconTooltip}
        onClick={props.onClick}
        title={props.title}
      />
      <TableContainer className={classes.container}>
        
          <Table aria-label="sticky table" stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <ArrowTooltip
                  title="Filtros"
                  placement="top"
                  open={props.ishelpdisplayed}
                >
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell {...column.getHeaderProps()}>
                        {column.render('Header')}
                        <div
                          style={{ wordWrap: 'break-word', maxWidth: '40ch' }}
                        >
                          {column.render('Filter')
                            ? column.render('Filter')
                            : null}
                        </div>
                      </TableCell>
                    ))}
                    {props.standardActions.length > 0 && (
                      <TableCell>
                        <div>{'Acciones'}</div>
                      </TableCell>
                    )}
                  </TableRow>
                </ArrowTooltip>
              ))}
            </TableHead>
            {
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          <div
                            style={{
                              wordWrap: 'break-word',
                              maxWidth: '40ch',
                            }}
                          >
                            {renderCellManager(cell)}
                          </div>
                        </TableCell>
                      );
                    })}
                    {props.standardActions.length > 0 && (
                      <TableCell>
                        {props.standardActions.map((action) => {
                          return renderStandardAction(action, row);
                        })}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
            }
          </Table>
        
      </TableContainer>
      <TablePagination
        component="div"
        count={rows.length}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={pageIndex}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[10, 25, 100]}
      />
      <ConfirmDialog
        title={props.titleConfirmDeleteRow}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          setOpenConfirmDialog(false);
          props.onDeleteRow(actionSelectedRow);
        }}
      >
        {props.textConfirmDeleteRow}
      </ConfirmDialog>
      <DialogMsg
        title={titleMsgDialog}
        text={textMsgDialog}
        open={openMsgDialog}
        handleClose={() => {
          setOpenMsgDialog(false);
        }}
      ></DialogMsg>

      {props.editComponent && (
        <props.editComponent
          onCloseDialog={() => {setOpenEditRowDialog(false); setActionSelectedRow({})}}
          onCancel={() => {setOpenEditRowDialog(false); setActionSelectedRow({})}}
          onSave={props.onEditRow}
          open={openEditRowDialog}
          initialValues={actionSelectedRow}
          mode={'edit'}
        />
      )}
    </Paper>
  );
};

SimpleTable.propTypes = {
  actionIcon: PropTypes.any,
  actionIconTooltip: PropTypes.string,
  data: PropTypes.array.isRequired,
  filters: PropTypes.any, //Map
  columns: PropTypes.any, //Map
  onClick: PropTypes.func,
  standardActions: PropTypes.array,
  onDeleteRow: PropTypes.func,
  editComponent: PropTypes.elementType,
  onEditRow: PropTypes.func,
  titleConfirmDeleteRow: PropTypes.string,
  textConfirmDeleteRow: PropTypes.string,
  title: PropTypes.any,
  skipOtherColumns: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  ishelpdisplayed: state.valuesHelp.showhelp,
  
});

SimpleTable = connect(mapStateToProps,{
  loaddocumentaggregateddata,
  loaddocumentoverviewdata
})(SimpleTable);

export default SimpleTable;
