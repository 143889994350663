export const createFileQueries = (entityId, companyId, documentSpeciesId, documentFormatId) => {    
    return [
      {
        name: "DocumentDetailsUploadQueries",
        type: "update_entityfile",
        table: "entityfile",
        parents: [
          {
            table: "entity",
            fieldname: "entity",
            identifiers: {
              id: entityId,
            },
          },
          {
            table: "entitycompany",
            fieldname: "company",
            identifiers: {
              id: companyId,
            },
          },
          {
            table: "documentspecies",
            fieldname: "document_species",
            identifiers: {
              id: documentSpeciesId,
            },
          },
          {
            table: "documentformat",
            fieldname: "document_format",
            identifiers: {
              id: documentFormatId,
            },
          },          
        ],
        identifiers: {},
        defaults: {},
      },
    ];
  };

export const createDataQuestionnare = (entityID, companyID, documentSpecies, cif) => {
    return [
      {
        name: "EntityArray",
        type: "list",
        table: "entityfile",
        categories: ["id"],
        expressions: [],
        filters:{
          entity__id: entityID,
          company__id: companyID,
          document_species__id: documentSpecies,
          company__id_number: cif
           
        },
        orderby: ["id"],
      },
    ];
  };

export const createFileDetailsQueries = (entityId, fileId, details, questionId) => {    
    return (
      {
        name: "DocumentDetailsUploadQueries",
        type: "update_entityquestionnairedetails",
        table: "entityquestionnairedetails",
        parents: [
          {
            table: "entityfile",
            fieldname: "file",
            identifiers: {
              id: fileId,
            },
          },
          {
            table: "entity",
            fieldname: "entity",
            identifiers: {
              id: entityId,
            },
          },
        ],
        identifiers: {question: questionId, anwser: details.anwser },
        defaults: details,
      }
      )
  };