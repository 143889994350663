const setDeleteFlagQuery = (id)=> {
  return [
    {
      "name": "setDeleteFlag",
      "type": "update",
      "table": "entityfile",
      "identifiers": {"id": id},
      "defaults": {
        "delete_flag":true
      }
    }
  ]
}
export { setDeleteFlagQuery };
