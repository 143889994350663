// For loading the data
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_DOCUMENT_DATA_AGGREGATED = "GET_DOCUMENT_DATA_AGGREGATED";
export const GET_DOCUMENT_OVERVIEW_DATA = "GET_DOCUMENT_OVERVIEW_DATA";
export const GET_DATA_INVOICE = "GET_DATA_INVOICE";
export const GET_FUNNEL_ABBYY = "GET_FUNNEL_ABBYY";
export const GET_DATA_LITER = "GET_DATA_LITER";
export const GET_IDENTITY_TYPE_ARRAY = "GET_IDENTITY_TYPE_ARRAY";
export const GET_ENTITY_ARRAY = "GET_ENTITY_ARRAY";
export const GET_ENTITY_ARRAY_ABBYY = "GET_ENTITY_ARRAY_ABBYY";
export const GET_COMPANY_ARRAY_ABBYY = "GET_COMPANY_ARRAY_ABBYY";
export const GET_SUPPLIER_ARRAY_ABBYY = "GET_SUPPLIER_ARRAY_ABBYY";
export const GET_DATE_ARRAY_ABBYY = "GET_DATE_ARRAY_ABBYY";
export const GET_PROVINCE_ARRAY_ABBYY = "GET_PROVINCE_ARRAY_ABBYY";
export const GET_STATE_ARRAY_ABBYY = "GET_STATE_ARRAY_ABBYY";
export const GET_GRAPH_QUERIES_ABBY = "GET_GRAPH_QUERIES_ABBY";
export const GET_STATUS_INVOICE_ABBYY = "GET_STATUS_INVOICE_ABBYY";
export const GET_TOTAL_DOCUMENTS_ABBYY = "GET_TOTAL_DOCUMENTS_ABBYY";
export const GET_COMPANY_ARRAY = "GET_COMPANY_ARRAY";
export const GET_SUPPLIER_ARRAY = "GET_SUPPLIER_ARRAY";
export const GET_INVOICE_TYPE_ARRAY = "GET_INVOICE_TYPE_ARRAY";
export const GET_DOCUMENT_FORMAT_ARRAY = "GET_DOCUMENT_FORMAT_ARRAY";
export const GET_DOCUMENT_SPECIES_ARRAY = "GET_DOCUMENT_SPECIES_ARRAY";
export const GET_COMPANY_TYPE_ARRAY = "GET_COMPANY_TYPE_ARRAY";
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const GET_DEMO_VIDEO = "GET_DEMO_VIDEO";

// For updating data
export const UPDATE_COMPANY_DATA = "UPDATE_COMPANY_DATA";
export const CREATE_COMPANY = "CREATE_COMPANY";

// For slicing the data
export const SLICER_COMPANY_ARRAY = "SLICER_COMPANY_ARRAY";
export const SLICER_DATA_INVOICE = "SLICER_DATA_INVOICE";
export const SLICER_DATA_LITER = "SLICER_DATA_LITER";
export const SLICER_DATA_QUESTIONNAIRE = "SLICER_DATA_QUESTIONNAIRE";
export const SLICER_DATA_DOCUMENTS = "SLICER_DATA_DOCUMENTS";

// For deleting instances
export const SOFT_DELETE_DOKUMENT = "SOFT_DELETE_DOKUMENT";