function composeAggKey(arrProp, obj) {
  return arrProp.reduce(function(total, prop) {
    return total + '-' + obj[prop];
  }, []);
}

function composeAggObject(group_by_arr_fields, value_field, obj) {
  let newObj = group_by_arr_fields.reduce(function(total, prop) {
    total[prop] = obj[prop];
    return total;
  }, {});
  newObj[value_field] = obj[value_field];

  return newObj;
}

export function grpAgg(dataSet, group_by_arr_fields, value_fields) {
  
  var helper = {};
  var result = dataSet.reduce(function(r, o) {
    var key = composeAggKey(group_by_arr_fields, o);

    value_fields.forEach((value_field) => {
      if (!helper[key]) {
        helper[key] = composeAggObject(group_by_arr_fields, value_field, o); // create a copy of o
        helper[key][value_field + '_arr'] = [helper[key][value_field]];
        r.push(helper[key]);
      } else {
        if (!helper[key][value_field + '_arr']) {
          helper[key][value_field + '_arr'] = [o[value_field]];
          helper[key][value_field] = o[value_field];
        } else {
          helper[key][value_field + '_arr'].push(o[value_field]);
          helper[key][value_field] += o[value_field];
        }
      }
    });

    return r;
  }, []);
  return result;
}

// export function grpAgg(dataSet, group_by_field, value_field) {
//   let tmp_ = dataSet.reduce((total, obj) => {
//     total[obj[group_by_field]] = (total[obj[group_by_field]] || []).concat([
//       obj[value_field],
//     ]);
//     // total[obj[group_by_field]].push(obj[value_field]);
//     return total;
//   }, {});
//   return tmp_;
// }

export function calcMean(arrNum) {
  let total_ = arrNum.reduce((a, b) => a + b);
  if (arrNum.length() > 0) {
    return total_ / arrNum.length;
  } else {
    return 0;
  }
}

export function calcSum(arrNum) {
  let total_ = arrNum.reduce((a, b) => a + b);
  return total_;
}

export function calcCount(arrNum) {
  return arrNum.length;
}

export function calcCountDistinct(arrNum) {
  return new Set(arrNum).size;
}

export function mapReduceChartDatasets(
  dataSet,
  group_by_arr_fields,
  value_fields,
  label_field,
  agg_func
) {
  let tmp_ = grpAgg(dataSet, group_by_arr_fields, value_fields);
  let res = Object.entries(tmp_).map((obj) => {
    return {
      label: obj[1][label_field],
      value:
        agg_func !== undefined
          ? _aux_agg_func(value_fields, obj[1], agg_func)
          : _aux_agg_func(value_fields, obj[1]),
    };
  });
  return res;
}

function _aux_agg_func(value_fields, data, agg_func) {
  if (agg_func) {
    return value_fields.reduce((acc, value_field) => {
      return acc + agg_func(data[value_field + '_arr']);
    }, 0);
  } else {
    return value_fields.reduce((acc, value_field) => {
      return data[value_field + '_arr'];
    }, []);
  }
}
