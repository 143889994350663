import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CompanyDialog from 'views/CompanysPage/components/TableCompany/dialog/CompanyDialog'
import { filterData } from "functions/filterData";
import If, { Else } from 'if-else-react';
import { sliceCompany } from "common/actions/data";

import {
  changeEntity,
  changeCompany,
  changeDocumentFormat,
  changeDocumentSpecies,
  changeInvoiceType,
} from "common/actions/components";

import {
  FormControlSelectorCompany,
  FormControlAutocompleteSelector,
  FormControlSelector,
} from "components";

export default class GeneralDocumentFormSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async handleEntityChange(event) {
    let entityId = event.target.value;
    await this.props.changeEntity(entityId);
    if (entityId) {
      this.props.sliceCompany({ entityIds: [entityId] });
    }
  }

  async updateInvoiceType(event) {
    let field = event.target.value;
    await this.props.changeInvoiceType(field);
  }

  async updateFormatType(event) {
    let field = event.target.value;
    await this.props.changeDocumentFormat(field);
  }

  handleCancelAddCompanyDialog = () => {
    this.setState(() => {
      return {
        openAddCompanyDialog: false,
      };
    });
  };

  handleOpenAddCompanyDialog = () => {
    this.setState(() => {
      return {
        openAddCompanyDialog: true,
      };
    });
  };

  render() {
    return (
      <Fragment>
        <Grid item>
          <FormControlSelector
            value={this.props.valuesEntityFile.entityId}
            onChange={this.handleEntityChange.bind(this)}
            data={this.props.data.entityArray}
            label={"Organización territorial"}
            id_key={"entity"}
          ></FormControlSelector>
        </Grid>

        
        <Grid item  style={{  paddingRight: '0px' }}>
          <FormControlSelectorCompany
            value={this.props.valuesEntityFile.companyId}
            onChange={(e, value) => {
              
              this.props.changeDocumentFormat(1);
              this.props.changeDocumentSpecies(1);
              this.props.changeInvoiceType(1); 
            
              return this.props.changeCompany(e.target.value);
            }}
            data={filterData(
              this.props.data.companyArray,
              this.props.companySlicer
            )}
            label={"Cliente"}
            id_key={"company"}
          ></FormControlSelectorCompany>
          </Grid>
          <Grid item  style={{  paddingRight: '0px', paddingLeft: '0px' }}>
          <IconButton
            onClick={this.handleOpenAddCompanyDialog}
            aria-label="añadir cliente"
            size="medium"
          >
            <AddCircleOutlineIcon
              data-testid="add_company-button"
              fontSize="inherit"
            />
          </IconButton>
        </Grid>
        <Grid item>
          <FormControlSelector
            value={this.props.valuesEntityFile.documentSpeciesId}
            onChange={(e) => {
              if(e.target.value == 3 || e.target.value == 5 ){
                this.props.changeInvoiceType(1); 
                this.props.changeDocumentFormat(2);
                return this.props.changeDocumentSpecies(e.target.value);
              }else{
                this.props.changeInvoiceType(1); 
                this.props.changeDocumentFormat(1);
                return this.props.changeDocumentSpecies(e.target.value);
              }
             
            }} 
            data={this.props.data.documentSpeciesArray}
            label="Tipo de fichero"
            id_key="species"
          ></FormControlSelector>
        </Grid>
 
        


        <CompanyDialog
          onCloseDialog={this.handleCancelAddCompanyDialog}
          onCancel={this.handleCancelAddCompanyDialog}
          open={this.state.openAddCompanyDialog}
          initialValues={{}}
          mode={'create'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  valuesEntityFile: state.valuesEntityFile,
  data: state.data,
  companySlicer: state.slicer.companySlicer,
});

GeneralDocumentFormSection = connect(mapStateToProps, {
  changeEntity,
  changeCompany,
  changeDocumentFormat,
  changeDocumentSpecies,
  sliceCompany,
  changeInvoiceType
})(GeneralDocumentFormSection);
