import { SHOW_HELP, HIDE_HELP } from "common/constants/components";
import { loadState, saveState } from "functions/stateHelper";
import {STATE_KEY_HELP } from "common/constants/states";

const init = {
  showhelp: false,
};
let initialState = loadState(STATE_KEY_HELP, init);

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case SHOW_HELP:
      updatedState = {
        ...state,
        showhelp: true,
      };
      break;
    case HIDE_HELP:     
      updatedState = {
        ...state,
        showhelp: false,
      };
      break;
    default:
      return state;
  }

  saveState(updatedState, STATE_KEY_HELP);
  return updatedState;
}
