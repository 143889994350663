import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import { Input, Grid, TextField } from '@material-ui/core';
import { notifySuccess } from 'common/actions/notifications'
import { createFileDetailsQueries, createFileQueries, createDataQuestionnare } from './queries'
import { getQuestionnaire } from 'functions/fetchApi';
import { loaddocumentoverviewdata, loadcompanyarray } from 'common/actions/data';
import { connect } from "react-redux";
import If, { Else } from 'if-else-react';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
export default function RadioButtonsGroup(props, hola) {
    var value10Num="";
    var cont10= 0;
    const [entity, setEntity] = React.useState({
        entity1: ""
       
    });
    const [entityname, setEntityName] = React.useState({
        entity1: ""
       
    });
    const [entitycif, setEntityCif] = React.useState({
        entity1: ""
       
    });
  
    const [company, setCompany] = React.useState({
        company1: "",
    });
    var entidad= parseInt( new URLSearchParams(window.location.search).get("entity"));
    entity.entity1= entidad;
    
    var campania= parseInt( new URLSearchParams(window.location.search).get("company"));
    company.company1= campania;
    
   

    const [value, setValue] = React.useState({
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
        value9: "",
        value10: "",
        value11: "",
        value12: "",
        value14: "",
        value15: "",
        value16: "",
        value18: "",
        value19: "",
        value20: "",
    });

    

    const handleChange = (event) => {
        
            if(event.target.name == "value101"){
                if(value.value10 == ""){
                    value10Num= event.target.value ; 
                }else{
                    value10Num=  value.value10 + "," + event.target.value ; 
                } 
            
                setValue({ ...value, ["value10"]: value10Num });
                 
            }else{ 
                setValue({ ...value, [event.target.name]: event.target.value });
            }
          
    };

    const shouldDisableCheckbox = (event) => {

        if(event.value10){
            var str =event.value10;
            var res = str.split(",");
             var res1 = res.length;
           
    
           if(res1 == 3){
            return true
           }else{
            return false;
           }
          
        }
     
    };

    const handleChangeEntityName = (event) => {
       
        setEntityName({ ...entityname, [event.target.name]: event.target.value });
    };
    const handleChangeEntityCif = (event) => {
       
        setEntityCif({ ...entitycif, [event.target.name]: event.target.value });
    };


    const handleSubmit = async (event) => {
    
        event.preventDefault();
        var arry = [];
        const questionnare =  createDataQuestionnare( entity.entity1, company.company1, 3 ,entitycif.entity1)
        const resultsets = await getQuestionnaire(questionnare)
      
        if(  resultsets[0].result.length == 0){
        
                const createEmptyFilequeries = createFileQueries(
                    entity.entity1,
                    company.company1,
                    3,
                    2,
                )
                const resultset = await getQuestionnaire(createEmptyFilequeries);

                var fileId = null
                if (resultset[0].result.length > 0) {
                    fileId = resultset[0].result[0].id;
                }
                if (fileId) {
                    var cont = 1;
                    for (var i in value) {
                        var obj = {};
                        obj.id = cont;
                        cont++;
                        obj.valor = value[i];
                        const details = { anwser: obj.valor };
                        const qry = createFileDetailsQueries(entity.entity1, fileId, details, obj.id)
                        arry.push(qry)

                    }
                    // console.log("Template de filas" + JSON.stringify(arry) );

                    getQuestionnaire(arry).then(() => {
                        props.notifySuccess(
                            "Gracias por responder al formulario"
                        );
                     
                    });
                }
                else {
                }

        }
        //  entityquestionnairedetails(arry);


    };
    return (
        
        <div class="containerForm">
            <form onSubmit={handleSubmit}>


                <FormControl style={{ paddingBottom: 50,minWidth:150 }}>

                    {/* <FormLabel class="divir" >Empresa: A de los estados</FormLabel>
                <FormLabel class="divir">Fecha: 04/12/2020</FormLabel>  */}
                     <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    aligncontent="flex-start"
                    spacing={4}
                    style={{ paddingTop: 50 }}
                    >
                        <Grid item> 
                        <FormLabel
                         style={{ paddingRight: 10 }}
                        class="divir">Nombre de la Empresa</FormLabel>
                                <Input type="text" name="entity1" value={entityname.entity1}
                                  onChange={handleChangeEntityName}
                               ></Input>
                                                    
                             </Grid>    
                    
                            <Grid item>
                                <FormLabel 
                                  style={{ paddingRight: 10 }}
                                  class="divir">CIF de la Empresa</FormLabel>
                                <Input type="text" name="entity1" value={entitycif.entity1} 
                                onChange={handleChangeEntityCif}
                                   
                                ></Input>
                            </Grid>
                    </Grid>

                    <FormLabel class="divir">1.Indique el consumo medio anual (en litros):</FormLabel>
                    <Input type="number" name="value1" value={value.value1} onChange={handleChange} ></Input>


                    <FormLabel class="divir">2. Ha estado en operación la compañía desde el año 2002?</FormLabel>
                    <RadioGroup aria-label="value2" name="value2" value={value.value2} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Si" />
                        <FormControlLabel value="1" control={<Radio />} label="No" />
                    </RadioGroup>

                    <FormLabel class="divir" >3. ¿Quién selecciona las Estaciones de Servicio (“EESS”) en las que reposta la flota de la empresa?</FormLabel>
                    <RadioGroup aria-label="value3" name="value3" value={value.value3} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="La empresa decide u ofrece indicaciones." />
                        <FormControlLabel value="1" control={<Radio />} label="Cada conductor es libre de elegir." />
                    </RadioGroup>

                    <FormLabel class="divir">4. ¿Planifica su empresa o el conductor dónde se van a realizar los repostajes de combustible en cada ruta de transporte que se va a realizar?</FormLabel>
                    <RadioGroup aria-label="value4" name="value4" value={value.value4} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Sí, en general." />
                        <FormControlLabel value="1" control={<Radio />} label="No, porque no realizo rutas frecuentes." />
                        <FormControlLabel value="2" control={<Radio />} label="No, por otros motivos." />
                    </RadioGroup>

                    <FormLabel class="divir" >5. ¿Suele la empresa repostar en las mismas EESS o cadenas de EESS?</FormLabel>
                    <RadioGroup aria-label="value5" name="value5" value={value.value5} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Sí, en general." />
                        <FormControlLabel value="1" control={<Radio />} label="No" />
                    </RadioGroup>

                    <FormLabel class="divir">6. ¿Cuál es el criterio principal para seleccionar las EESS en las que reposta?</FormLabel>
                    <RadioGroup aria-label="value6" name="value6" value={value.value6} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Precio y descuentos." />
                        <FormControlLabel value="1" control={<Radio />} label="Promociones no incluidas en el precio (por ejemplo, comidas gratis, descuentos en hoteles…)." />
                        <FormControlLabel value="2" control={<Radio />} label="Dónde están situadas u otros motivos de conveniencia (por ejemplo, servicios que ofrecen)." />
                        <FormControlLabel value="3" control={<Radio />} label="Calidad del combustible." />
                        <FormControlLabel value="4" control={<Radio />} label="Otros" />
                    </RadioGroup>

                    <FormLabel class="divir" class="divir"  >7. ¿Cree que el combustible de las distintas cadenas de EESS presenta importantes diferencias en términos de calidad?</FormLabel>
                    <RadioGroup aria-label="value7" name="value7" value={value.value7} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Si" />
                        <FormControlLabel value="1" control={<Radio />} label="No" />

                    </RadioGroup>

                    <FormLabel class="divir" >8. En caso de que haya contestado afirmativamente a la pregunta anterior, ¿considera que la diferencia en calidad se ha acentuado en los últimos años o siempre han existido EESS que han ofrecido combustible de mejor calidad de manera consistente en los últimos 5 a 10 años?</FormLabel>
                    <RadioGroup aria-label="value8" name="value8" value={value.value8} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="La diferencia en calidad del combustible ha existido tradicionalmente." />
                        <FormControlLabel value="1" control={<Radio />} label="La diferencia en calidad del combustible se ha acentuado en los últimos 5 a 10 años." />
                        <FormControlLabel value="2" control={<Radio />} label="La calidad del combustible ofrecido ha tendido a homogeneizarse en los últimos 5 a 10 años." />

                    </RadioGroup>

                    <FormLabel class="divir" >9. En caso afirmativo, ¿cuáles considera que son las EESS que ofrecen mejor calidad?</FormLabel>
                    <RadioGroup aria-label="value9" name="value9" value={value.value9} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="REPSOL" />
                        <FormControlLabel value="1" control={<Radio />} label="GALP" />
                        <FormControlLabel value="2" control={<Radio />} label="BP" />
                        <FormControlLabel value="3" control={<Radio />} label="CEPSA" />
                        <FormControlLabel value="4" control={<Radio />} label="SHELL" />
                        <FormControlLabel value="5" control={<Radio />} label="DISA" />
                        <FormControlLabel value="6" control={<Radio />} label="Otros" />
                    </RadioGroup>

                    <FormLabel class="divir">10. ¿Cuáles considera Vd. que son las EESS con precios después de descuentos más elevados? Elija las 3 empresas que considere que ofrecen precios más elevados</FormLabel>
                   
                        <FormControlLabel  disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="0" name="value101" onChange={handleChange} />} label="REPSOL" />
                        <FormControlLabel  disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="1" name="value101" onChange={handleChange} />} label="GALP" />
                        <FormControlLabel  disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="2" name="value101" onChange={handleChange} />} label="BP" />
                        <FormControlLabel  disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="3" name="value101" onChange={handleChange} />} label="CEPSA" />
                        <FormControlLabel  disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="4" name="value101" onChange={handleChange} />} label="SHELL" />
                        <FormControlLabel disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="5" name="value101" onChange={handleChange}  />} label="DISA" />
                        <FormControlLabel  disabled={shouldDisableCheckbox(value)} control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} value="6" name="value101" onChange={handleChange}  />} label="Otros" />
                   

                    <FormLabel class="divir">11.	¿Considera Vd. que los precios más elevados están justificados por la mejor calidad del combustible y/o los servicios prestados?</FormLabel>
                    <RadioGroup aria-label="value11" name="value11" value={value.value11} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Sí" />
                        <FormControlLabel value="1" control={<Radio />} label="No." />
                        <FormControlLabel value="2" control={<Radio />} label="En la mayoría de las ocasiones" />
                        <FormControlLabel value="3" control={<Radio />} label="En escasas ocasiones." />
                        <FormControlLabel value="4" control={<Radio />} label="Otros" />
                    </RadioGroup>


                    <FormLabel class="divir">12.	¿Qué tipo de combustible utiliza con mayor asiduidad?</FormLabel>
                    <RadioGroup aria-label="value12" name="value12" value={value.value12} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Combustible normal" />
                        <FormControlLabel value="1" control={<Radio />} label="Combustible premium." />
                        <FormControlLabel value="2" control={<Radio />} label="Depende del precio" />
                    </RadioGroup>

                    <FormLabel class="divir">13.	¿Qué descuentos obtiene en la compra de combustible?</FormLabel>
                    <RadioGroup aria-label="value13" name="value13" value={value.value13} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="No obtengo descuentos" />
                        <FormControlLabel value="1" control={<Radio />} label="Solo obtengo los descuentos genéricos ofrecidos por las tarjetas de las cadenas de EESS" />
                        <FormControlLabel value="2" control={<Radio />} label="Me acojo además a los descuentos negociados por las asociaciones de transportistas." />
                        <FormControlLabel value="3" control={<Radio />} label="La empresa ha negociado directamente con ciertas EESS o cadenas de EESS descuentos que sólo se aplican a nuestra empresa" />
                    </RadioGroup>

                    <FormLabel class="divir" >14.	Los descuentos que obtiene la empresa…</FormLabel>
                    <RadioGroup aria-label="value14" name="value14" value={value.value14} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Se aplican en todas las EESS de la misma marca o cadena." />
                        <FormControlLabel value="1" control={<Radio />} label="También hay descuentos que son específicos para algunas EESS particulares" />
                    </RadioGroup>

                    <FormLabel class="divir">15.	¿Qué forma tienen estos descuentos en el precio de combustibles (marque más de una opción si procede)?</FormLabel>
                    <RadioGroup aria-label="value15" name="value15" value={value.value15} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Una rebaja en el precio que se aplica en cada repostaje y es fija para todos los litros que se reposten." />
                        <FormControlLabel value="1" control={<Radio />} label="Una rebaja en el precio que se aplica en cada repostaje, pero que va aumentando con los litros acumulados durante un periodo de tiempo" />
                        <FormControlLabel value="2" control={<Radio />} label="Una bonificación que se aplica sólo en algunos repostajes particulares, por ejemplo, si se alcanza un número determinado de litros acumulados durante un periodo de tiempo" />
                        <FormControlLabel value="3" control={<Radio />} label="Otros" />
                    </RadioGroup>

                    <FormLabel class="divir" >16.	¿Obtiene ventajas adicionales a los descuentos?</FormLabel>
                    <RadioGroup aria-label="value16" name="value16" value={value.value16} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Pago aplazado de los repostajes (por ejemplo, a fin de mes, a 60 días…)." />
                        <FormControlLabel value="1" control={<Radio />} label="Descuentos en otros servicios, directos o a través de puntos (talleres, ITVs, servicios en las EESS…)." />
                        <FormControlLabel value="2" control={<Radio />} label="Otras promociones o ventajas" />
                    </RadioGroup>

                    <FormLabel class="divir">17.	¿Son similares los descuentos y ventajas ofrecidos por las distintas EESS?</FormLabel>
                    <RadioGroup aria-label="value17" name="value17" value={value.value17} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Son todos muy similares" />
                        <FormControlLabel value="1" control={<Radio />} label="Las cadenas de EESS muy grandes ofrecen descuentos y ventajas similares, pero distintos de los ofrecidos por las cadenas de EESS pequeñas y por las EESS que no pertenecen a cadenas." />
                        <FormControlLabel value="2" control={<Radio />} label="Cada cadena ofrece condiciones distintas" />
                        <FormControlLabel value="3" control={<Radio />} label="No lo sé" />
                    </RadioGroup>

                    <FormLabel class="divir" >18.	¿Qué tipo de EESS ofrece mejores descuentos?</FormLabel>
                    <RadioGroup aria-label="value18" name="value18" value={value.value18} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Las cadenas de EESS muy grandes." />
                        <FormControlLabel value="1" control={<Radio />} label="Las cadenas de EESS pequeñas y las EESS que no pertenecen a cadenas." />
                        <FormControlLabel value="2" control={<Radio />} label="Ambas por igual." />
                    </RadioGroup>

                    <FormLabel class="divir">19.	¿Ha modificado su empresa las EESS en las que reposta a lo largo del tiempo debido a cambios en los precios o descuentos?</FormLabel>
                    <RadioGroup aria-label="value19" name="value19" value={value.value19} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Sí, con frecuencia." />
                        <FormControlLabel value="1" control={<Radio />} label="En algunas ocasiones." />
                        <FormControlLabel value="2" control={<Radio />} label="No o rara vez." />
                    </RadioGroup>

                    <FormLabel class="divir">20.	¿Con qué frecuencia negocia el precio que cobra a sus clientes por los servicios de transporte?</FormLabel>
                    <RadioGroup aria-label="value20" name="value20" value={value.value20} onChange={handleChange}>
                        <FormControlLabel value="0" control={<Radio />} label="Son contratos anuales o plurianuales, con cláusulas de revisión de precio en función de la evolución de ciertos costes" />
                        <FormControlLabel value="1" control={<Radio />} label="Se determina el precio casi para cada transporte en particular" />
                        <FormControlLabel value="2" control={<Radio />} label="Otros." />
                    </RadioGroup>


                    <If condition={value.value1 && value.value2 && value.value3 && value.value4 && value.value5 && value.value6 && value.value7  && value.value10 && value.value11 && value.value12 && value.value13 && value.value14 && value.value15 && value.value16 && value.value17 && value.value18 && value.value19 && value.value20}>
                        <Button type="submit" variant="contained">
                            Enviar
                        </Button>
                        <Else />
                            <Button type="submit" variant="contained"  disabled>
                                Enviar
                            </Button>
                    </If>




                </FormControl>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
    valuesEntityFile: state.valuesEntityFile,
    valuesAdditionalDocumentDetails: state.valuesAdditionalDocumentDetails,
});

RadioButtonsGroup = connect(mapStateToProps, {
    loaddocumentoverviewdata,
    loadcompanyarray,
    notifySuccess,
})(RadioButtonsGroup);
