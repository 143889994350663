import {
  SLICER_COMPANY_ARRAY,
  SLICER_DATA_INVOICE,
  SLICER_DATA_LITER,
  SLICER_DATA_QUESTIONNAIRE,
  SLICER_DATA_DOCUMENTS
} from "common/constants/data";

export const sliceCompany = ({   companyIds,
  entityIds }) => async (dispatch) => {

  let slicer = null;
  if (entityIds || companyIds) {
    slicer = { entityId: entityIds, id: companyIds };
  }
  dispatch({
    type: SLICER_COMPANY_ARRAY,
    payload: { slicer },
  });
};

export const sliceInvoiceDetails = ({
  companyIds,
  entityIds,
  supplierIds,
  invoiceTypeIds,
  years
}) => async (
  dispatch
) => {

  let slicer = null;
  if (entityIds || supplierIds || companyIds || invoiceTypeIds || years) {
    slicer = { company_id: companyIds, supplier_id: supplierIds, entity_id: entityIds,invoice_type_id:invoiceTypeIds, year: years };
  }
  dispatch({
    type: SLICER_DATA_INVOICE,
    payload: { slicer },
  });
};

export const sliceLiterSummaryDetails = ({
  companyIds,
  entityIds,
  supplierIds,
  years
}) => async (dispatch) => {
  let slicer = null;
  if (entityIds || supplierIds || companyIds || years) {
    slicer = { company_id: companyIds, supplier_id: supplierIds, entity_id: entityIds, year: years };
  }
  dispatch({
    type: SLICER_DATA_LITER,
    payload: { slicer },
  });
};

export const sliceQuestionnaireDetails = ({ companyIds }) => async (
  dispatch
) => {
  let slicer = null;
  if (companyIds) {
    slicer = { companyId: companyIds};
  }
  dispatch({
    type: SLICER_DATA_QUESTIONNAIRE,
    payload: { slicer },
  });
};

export const sliceDocuments = ({ companyIds }) => async (
  dispatch
) => {
  let slicer = null;
  if (companyIds) {
    slicer = { company_id: companyIds};
  }
  dispatch({
    type: SLICER_DATA_DOCUMENTS,
    payload: { slicer },
  });
};