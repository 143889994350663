import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {  Grid,  Button,  Paper,  Dialog,  AppBar,  Toolbar,  IconButton,   Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { downloadFile } from 'common/actions/data/requests';
import { notifySuccess } from 'common/actions/notifications';
import CloseIcon from '@material-ui/icons/Close';
import SimpleToolbar from '../../../../../components/SimpleTable/components/simpleToolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TableDocumentOverview from '../../../../DocumentsPage/components/TableDocumentOverview';
import { withStyles } from '@material-ui/styles';
import { FormControlTextInput } from 'components';
import { Field, reduxForm } from 'redux-form';
import { updateCompany, updateEntityFiles, updateMultiFileStatus } from "common/actions/data";

const themeStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

class CompanyDocsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    
  }

  handleDownloadFileCallback(rowData) {
    this.props.downloadFile({ fileId: rowData.id });
  }

  handleSubmit(form) {
   
    this.props.updateMultiFileStatus(this.props.initialValues.id);
    this.props.updateCompany(form);
    this.props.notifySuccess("Se han guardado los cambios");
  }

  renderCheckbox({ input, label }) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Dialog
          fullScreen
          onClose={() => {
            this.props.onCloseDialog();
          }}
          open={this.props.open}
        >
          <AppBar style={{ position: 'relative', background: '#7E79B0' }}>
            <Toolbar>
              <IconButton
                aria-label="close"
                color="inherit"
                edge="start"
                onClick={() => {
                  this.props.onCloseDialog();
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography style={{ marginLeft: 2, flex: 1 }} variant="h6">
                {'Documentos del cliente'}
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid style={{ padding: 24 }} container spacing={2}>
              <Grid item xs={12}>
                <TableDocumentOverview
                  documentsArrayFiltred={this.props.data}
                  standardActions={[
                    {
                      name: 'download',
                      callback: this.handleDownloadFileCallback.bind(this),
                    },
                  ]}
                  title="Look at all you documents!!!"
                  useActionIcon={false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: 24 }}>
              <Paper>
                <SimpleToolbar title={'Revisión de los documentos'} />
                <form autocomplete="off"
                  onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))}
                  className="ui form">
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={classes.root}
                    spacing={2}
                  >
                    <Grid item xs={11}>
                      <Field
                        name="comments"
                        component={FormControlTextInput}
                        inputProps={{
                          maxlength: "500"
                        }}
                        characterLimit={500}
                        type="text"
                        label="Comentarios"
                        multiline={true}
                        is_optional={true}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid
                        container
                        direction="column"
                        justify="flex-end"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item xs={12}>
                          <Field
                            name="is_verified"
                            component={this.renderCheckbox}
                            label="Documentos válidos"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button type="submit" variant="contained" color="primary">
                            Guardar datos
                      </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  companyArray: state.data.companyArray,
  isAuthenticated: state.auth.isAuthenticated,
});

CompanyDocsDialog.propTypes = {
  mode: PropTypes.string.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  initialValues: PropTypes.object,
};

CompanyDocsDialog = connect(mapStateToProps, {
  notifySuccess,
  downloadFile,
  updateCompany,
  updateEntityFiles,
  updateMultiFileStatus
})(CompanyDocsDialog);

CompanyDocsDialog = connect()(CompanyDocsDialog);

CompanyDocsDialog = reduxForm({
  form: 'companyValidationDocsForm',
  enableReinitialize: true
})(CompanyDocsDialog);
export default withStyles(themeStyles)(CompanyDocsDialog);
