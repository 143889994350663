import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Field, reduxForm } from "redux-form";
import {
  Grid,
  Button,
  TextField,  
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import { updateUser } from "common/actions/auth/auth";
import { notifySuccess } from '../../common/actions/notifications/notify';
import { loadUserData } from "common/actions/data/requests/accountData";
import validateUserForm  from 'views/ActivateAccountPage/validator'
// import { loadUserData } from "common/actions/account";

class InformationPage extends Component {
  renderTitle = () => {
    return (
      <Fragment>
        <AccountCircleIcon fontSize="large" />
      </Fragment>
    );
  };

  renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      inputProps={{'data-testid':custom.data_testid}}
      {...input}
      {...custom}
    />
  );

  renderSelectField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  }) => {
    return (
      <FormControl error={error}>
        <InputLabel id={"form_label_idType"}>{label}</InputLabel>
        <Select
          label={label}
          placeholder={label}
          error={touched && invalid}
          helperText={touched && error}
          {...input}
          {...custom}
        >
          <MenuItem value={"CIF"} id={"form_select_item_idType_cif"}>
            CIF
          </MenuItem>
          <MenuItem value={"NIF"} id={"form_select_item_idType_cif"}>
            NIF
          </MenuItem>
          <MenuItem value={"NIE"} id={"form_select_item_idType_cif"}>
            NIE
          </MenuItem>
        </Select>
        {error && (
          <FormHelperText id={"form_select_helper_idType"}>
            Seleccione una opción
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  async handleSubmit(formValues) {    
    formValues.preventDefault();
    var formSubmit = {
      id_type: formValues.target.id_type.value,
      id_number: formValues.target.id_number.value,
      first_name: formValues.target.first_name.value,
      last_name_1: formValues.target.last_name_1.value,
      last_name_2: formValues.target.last_name_2.value,
    };
    this.props.notifySuccess("Se ha actualizado la información");
    await this.props.updateUser(formSubmit)    
    await this.props.loadUserData()
  }

  renderSubheader() {
    if (this.props.initialValues) {
      return "Perfil de Usuario\n" + this.props.initialValues.email;
    }
    return "Perfil de Usuario\nUnknow";
  }

  componentDidMount() {    
    // this.props.loadUserData();
  }

  render() {
    if (!this.props.isAuthenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Card style={{ width: 350, marginTop: 30, padding: 15 }}>
          <CardHeader
            title={this.renderTitle()}
            style={{ textAlign: "center" }}
            subheader={this.renderSubheader()}
          />
          <CardContent>
            <form onSubmit={this.handleSubmit.bind(this)} className="ui form">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12}>
                  <Field
                    name="id_type"
                    type="select"
                    component={this.renderSelectField}
                    label="NIF/NIE"
                    style={{ width: 250 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="id_number"
                    data_testid="id_number-input"
                    type="text"
                    component={this.renderTextField}
                    label="NIF/NIE"
                    style={{ width: 250 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="first_name"
                    data_testid="first_name-input"
                    type="text"
                    component={this.renderTextField}
                    label="Nombre"
                    style={{ width: 250 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="last_name_1"
                    data_testid="last_name_1-input"
                    type="text"
                    component={this.renderTextField}
                    label="Primer Apellido"
                    style={{ width: 250 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="last_name_2"
                    data_testid="last_name_2-input"
                    type="text"
                    component={this.renderTextField}
                    label="Segundo Apellido"
                    style={{ width: 250 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                  disabled={this.props.invalid|| this.props.submitting || this.props.pristine}
                    style={{ marginTop: 20 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    data-testid="update_info-button"
                  >
                    Actualizar información
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  initialValues: state.data.userDataArray,
});

export default connect(mapStateToProps, { updateUser,notifySuccess, loadUserData })(
  reduxForm({
    form: "updateUserForm",
    enableReinitialize: true,
    validate: validateUserForm
  })(InformationPage)
);
