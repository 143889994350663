import React, { Component, Fragment } from "react";
import {
  
  Grid
} from "@material-ui/core";
import D3Funnel from 'd3-funnel';
import { connect } from 'react-redux';




class Funnel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createFunnel() {

    console.log("");
    var datos=[];
    if(this.props.funnelAbbyy[0] != undefined){
    datos = [
      { label: 'Documentos PDF',  backgroundColor: '#037ef3', value: this.props.funnelAbbyy[0].result[0]},
      { label: 'Documentos PDF cerrados',  backgroundColor: '#00c16e', value: this.props.funnelAbbyy[1].result[0] + " ( " +  this.props.funnelAbbyy[0].result[0]*  this.props.funnelAbbyy[1].result[0]/100 + "% )*" },
      { label: 'Documentos PDF validados',   backgroundColor: '#0cb9c1',value: this.props.funnelAbbyy[2].result[0]  + " ( " +  this.props.funnelAbbyy[1].result[0]*  this.props.funnelAbbyy[2].result[0]/100 + "% )*" },
      
      { label: 'Enviados a procesar',  backgroundColor: '#3DDAFA', value: this.props.funnelAbbyy[3].result[0]  + " ( " +  this.props.funnelAbbyy[2].result[0]*  this.props.funnelAbbyy[3].result[0]/100 + "% )*" },
      { label: 'Exportados',  backgroundColor: '#caccd1', value: this.props.funnelAbbyy[4].result[0]  + " (" +  this.props.funnelAbbyy[3].result[0]*  this.props.funnelAbbyy[4].result[0]/100 + "% )*" },
    
  
      
      
  ];

  const options = {
    block: {
      highlight: true,
        
    },
    chart:{
      height:250,
      width:350,
      animate: 200,
      curve:{
        enabled: true,
        bottomPinch: true
      }
    },
    label:{
      fontFamily: "Roboto",
      fontSize: "12px"
    },
    tooltip:{
      enabled: true
    }
   
};
 console.log("aaaaaa")
const chart = new D3Funnel('#funnel');
chart.draw(datos, options);
}

    
  }
  componentDidMount() { 
    this.createFunnel()
   }
  render() {
    return (
   
       
         
              <Grid container alignContent="center" style={{ padding: 1 }}>
                  <div id="funnel"></div>
                   
                   
                </Grid>
               
    );
  }
}


const mapStateToProps = (state) => ({
 
  funnelAbbyy:  state.data.funnelAbbyy,
 
});


Funnel = connect(mapStateToProps, {
 
})(Funnel);


export default Funnel;