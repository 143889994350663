import {
  ADDITIONAL_DOC,
  INVOICE,
  CONTRACT,
  EUROPAYMENT,
  QUESTIONAIRE,
  SUMMARY,
} from "common/constants/model/values/documentSpecies";
import {
  INVOICEDETAILSMODEL,
  ADDITIONALDOCUMENTMODEL,
  CONTRACTDETAILSMODEL,
  EUROPAYMENTDETAILSMODEL,
  QUESTIONNAIREDETAILSMODEL,
  LITERSUMMARYMODEL,
} from "common/constants/model/names";

export function selectDetailsQueryParameter(
  documentSpeciesArray,
  documentSpeciesId,
  valuesEntityInvoiceDetails,
  valuesAdditionalDocumentDetails
) {
  // Get species value for corresponding species id
  let documentSpecies = documentSpeciesArray.filter(
    (s) => s.id === documentSpeciesId
  )[0];

  let modelName = null;
  let details = null;
  switch (documentSpecies.value) {
    case INVOICE:
      modelName = INVOICEDETAILSMODEL;
      details = {
        invoice_type_id: valuesEntityInvoiceDetails.invoiceTypeId,
        year: valuesEntityInvoiceDetails.documentYear,
        supplier_id: valuesEntityInvoiceDetails.supplierId,
      };
      break;
    case ADDITIONAL_DOC:
      modelName = ADDITIONALDOCUMENTMODEL;
      details = {
        description: valuesAdditionalDocumentDetails.description,
      };
      break;
    case CONTRACT:
      modelName = CONTRACTDETAILSMODEL;
      details = {};
      break;
    case EUROPAYMENT:
      modelName = EUROPAYMENTDETAILSMODEL;
      details = {};
      break;
    case QUESTIONAIRE:
      modelName = QUESTIONNAIREDETAILSMODEL;
      details = {};
      break;
      case SUMMARY:
        modelName = LITERSUMMARYMODEL;
        details = {};
        break;    
    default:
  }
  return { modelName: modelName, details: details };
}

export function checkIfSelected(
  documentSpeciesArray,
  valuesEntityFile,
  valuesEntityInvoiceDetails,
  valuesAdditionalDocumentDetails
) {  
  
  let documentSpecies = documentSpeciesArray.filter(
    (s) => s.id === valuesEntityFile.documentSpeciesId
  )[0];

  if (!documentSpecies) {
    return true
  }

  switch (documentSpecies.value) {
    case INVOICE:
      return (
        valuesEntityFile.checkSelectedCategories &&
        valuesEntityInvoiceDetails.checkSelectedCategories
      );
    case ADDITIONAL_DOC:
      return (
        valuesEntityFile.checkSelectedCategories &&
        valuesAdditionalDocumentDetails.checkSelectedCategories
      );
      case QUESTIONAIRE:
        return false;
    default:
      return valuesEntityFile.checkSelectedCategories;
  }
}
