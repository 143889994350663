import React, { Component, Fragment } from "react";

import { Grid, Typography, Box } from "@material-ui/core";
import { useParams } from "react-router";
import Radio from "./components/radioForm"

const Withdrawal = (props) => {

    let { id } = useParams();

    return(
        <Grid 
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ width: '100%' }}
            mb={10}
        >
            <Box width="100%">
                <Box
                    display="flex" 
                >
                    <Box m="auto">
                    <h1>INTERRUPCIÓN PRESCRIPCIÓN CÁRTEL DE COCHES Y FURGONETAS</h1>
                    </Box>
                </Box>
            </Box>
            {
                id && 
                    <Radio 
                        uuid = {id}
                    />
            } 
            {
                !id &&  <Typography variant="h3">
                            No se puede visualizar el contenido
                        </Typography>
            }

        </Grid>
    );
}

export default Withdrawal;
