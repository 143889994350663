import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import history from './history';
import Routes from './Routes';

import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import store from './store';
import Notifier from 'components/Notifier';
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

export default class App extends Component {  
  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          
          <Provider store={store}>
            <Notifier />
            <Router history={history}>
              <Routes />
            </Router>
          </Provider>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
