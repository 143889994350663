import {
  GET_USER_DATA,
  GET_DOCUMENT_DATA_AGGREGATED,
  GET_DOCUMENT_OVERVIEW_DATA,
  GET_DATA_INVOICE,
  GET_DATA_LITER,
  GET_FUNNEL_ABBYY,
  GET_IDENTITY_TYPE_ARRAY,
  GET_ENTITY_ARRAY,
  GET_COMPANY_ARRAY,
  GET_SUPPLIER_ARRAY,
  GET_INVOICE_TYPE_ARRAY,
  GET_DOCUMENT_FORMAT_ARRAY,
  GET_DOCUMENT_SPECIES_ARRAY,
  GET_COMPANY_TYPE_ARRAY,
  UPDATE_COMPANY_DATA,
  CREATE_COMPANY,
  SOFT_DELETE_DOKUMENT,
  GET_DEMO_VIDEO,
  GET_ENTITY_ARRAY_ABBYY,
  GET_COMPANY_ARRAY_ABBYY,
  GET_SUPPLIER_ARRAY_ABBYY,
  GET_DATE_ARRAY_ABBYY,
  GET_GRAPH_QUERIES_ABBY,
  GET_STATUS_INVOICE_ABBYY,
  GET_PROVINCE_ARRAY_ABBYY,
  GET_STATE_ARRAY_ABBYY,
  GET_TOTAL_DOCUMENTS_ABBYY
} from "common/constants/data";

import { loadState, saveState } from "functions/stateHelper";
import { STATE_KEY_DATA } from "common/constants/states";

const init = {
  documentOverviewArray: [],
  documentAggregatedArray: [],
  invoiceDetailsArray: [],
  funnelAbbyy:[],
  literSummaryArray: [],
  userDataArray: [],
  supplierArray: [],
  entityArray: [],
  entityArrayAbbyy: [],
  companyArrayAbbyy: [],
  supplierArrayAbbyy: [],
  dateArrayAbbyy: [],
  provinceArrayAbbyy: [],
  stateArrayAbbyy:[],
  statusInvoiceAbbyy:[],
  graphQueriesAbby: [],
  totaldocumentsabbyy: [],
  companyArray: [],
  invoiceTypeArray: [],
  idTypeArray: [],
  documentFormatArray: [],
  documentSpeciesArray: [],
  demovideo:null
};

let initialState = loadState(STATE_KEY_DATA, init);

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case GET_USER_DATA:
      updatedState = {
        ...state,
        userDataArray: action.payload.userDataArray,
      };
      break;
    case GET_DOCUMENT_DATA_AGGREGATED:
      updatedState = {
        ...state,
        documentAggregatedArray: action.payload.documentAggregatedArray,
      };
      break;
    case GET_DOCUMENT_OVERVIEW_DATA:
      updatedState = {
        ...state,
        documentOverviewArray: action.payload.documentOverviewArray,
      };
      break;
    case SOFT_DELETE_DOKUMENT:
      updatedState = {
        ...state,
        documentOverviewArray: action.payload.documentOverviewArray,
      };
      break;
    case GET_DATA_INVOICE:
      updatedState = {
        ...state,
        invoiceDetailsArray: action.payload.invoiceDetailsArray,
      };
      break;
    case GET_DATA_LITER:
      updatedState = {
        ...state,
        literSummaryArray: action.payload.literSummaryArray,
      };
      break;
      case GET_FUNNEL_ABBYY:
        updatedState = {
          ...state,
          funnelAbbyy: action.payload.funnelAbbyy,
        };
        break;
      
    case GET_COMPANY_ARRAY:
      updatedState = {
        ...state,
        companyArray: action.payload.companyArray,
      };
      break;
    case GET_ENTITY_ARRAY:
      updatedState = {
        ...state,
        entityArray: action.payload.entityArray,
      };
      break;
    case GET_ENTITY_ARRAY_ABBYY:
      updatedState = {
        ...state,
        entityArrayAbbyy: action.payload.entityArrayAbbyy,
      };
      break;
     case GET_COMPANY_ARRAY_ABBYY:
      updatedState = {
        ...state,
        companyArrayAbbyy: action.payload.companyArrayAbbyy,
      };
      break;
    case GET_SUPPLIER_ARRAY_ABBYY:
      updatedState = {
        ...state,
        supplierArrayAbbyy: action.payload.supplierArrayAbbyy,
      };
      break;
     case GET_DATE_ARRAY_ABBYY:
      updatedState = {
        ...state,
        dateArrayAbbyy: action.payload.dateArrayAbbyy,
      };
       break;
    case GET_PROVINCE_ARRAY_ABBYY:
      updatedState = {
        ...state,
        provinceArrayAbbyy: action.payload.provinceArrayAbbyy,
      };
      break;
      case GET_STATE_ARRAY_ABBYY:
        updatedState = {
          ...state,
          stateArrayAbbyy: action.payload.stateArrayAbbyy,
        };
        break;
    case GET_INVOICE_TYPE_ARRAY:
      updatedState = {
        ...state,
        invoiceTypeArray: action.payload.invoiceTypeArray,
      };
      break;
    case GET_GRAPH_QUERIES_ABBY:
      updatedState = {
        ...state,
        graphQueriesAbby: action.payload.graphQueriesAbby,
      };
      break;
      case GET_STATUS_INVOICE_ABBYY:
        updatedState = {
          ...state,
          statusInvoiceAbbyy: action.payload.statusInvoiceAbbyy,
        };
        break;

       
    case GET_TOTAL_DOCUMENTS_ABBYY:
      updatedState = {
        ...state,
        totaldocumentsabbyy: action.payload.totaldocumentsabbyy,
      };
      break;

       
    case GET_SUPPLIER_ARRAY:
      updatedState = {
        ...state,
        supplierArray: action.payload.supplierArray,
      };
      break;
    case GET_DOCUMENT_FORMAT_ARRAY:
      updatedState = {
        ...state,
        documentFormatArray: action.payload.documentFormatArray,
      };
      break;
    case GET_DOCUMENT_SPECIES_ARRAY:
      updatedState = {
        ...state,
        documentSpeciesArray: action.payload.documentSpeciesArray,
      };
      break;
    case GET_IDENTITY_TYPE_ARRAY:
      updatedState = {
        ...state,
        idTypeArray: action.payload.idTypeArray,
      };
      break;
    case GET_COMPANY_TYPE_ARRAY:
      updatedState = {
        ...state,
        companyTypeArray: action.payload.companyTypeArray,
      };
      break;    
    case CREATE_COMPANY:
      updatedState = {
        ...state,
        companyArray: action.payload.data,
      };
      break;
    case UPDATE_COMPANY_DATA:
      updatedState = {
        ...state,
        companyArray: action.payload.data,
      };
      break;
    case GET_DEMO_VIDEO:
      updatedState = {
        ...state,
        demovideo: action.payload.video,
      };
      break;
    default:
      return state;
  }
  saveState(updatedState, STATE_KEY_DATA);
  return updatedState;
}
