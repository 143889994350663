import {
  CHANGE_COMPANY,
  CHANGE_DOCUMENT_SPECIES,
  CHANGE_DOCUMENT_FORMAT,
} from "common/constants/components";

export const changeCompany = (companyId) => async (dispatch) => {
  dispatch({
    type: CHANGE_COMPANY,
    payload: { companyId },
  });
};

export const changeDocumentFormat = (documentFormatId) => async (dispatch) => {
  dispatch({
    type: CHANGE_DOCUMENT_FORMAT,
    payload: { documentFormatId },
  });
};

export const changeDocumentSpecies = (documentSpeciesId) => async (
  dispatch
) => {
  dispatch({
    type: CHANGE_DOCUMENT_SPECIES,
    payload: { documentSpeciesId },
  });
};
