import React from "react";
import {
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import If, { Else } from 'if-else-react';

export const FormControlSelector = ({ value, onChange, data, label, id_key, defaultValue }) => {
  return (
    <FormControl style={{ width: '100%',minWidth:150 }} error={!value}>
      <InputLabel id={"form_label_" + id_key}>{label}</InputLabel>
      <Select
        labelId={"form_label_" + id_key}
        id={"form_select_" + id_key}
        data-testid={"form_select_" + id_key}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
      >
        {data.map((obj, index) => {
          return (
            <MenuItem
              id={"form_select_item_" + id_key + "_" + index}
              value={obj.id}
              data-testid={"form_select_item_" + id_key + "_" + index}
            >
              {obj.value}
            </MenuItem>
          );
        })}
      </Select>
      {!value && (
        <FormHelperText id={"form_select_helper_" + id_key}>
          Seleccione una opción
        </FormHelperText>
      )}
    </FormControl>
  );
};


export const FormControlSelectorCompany= ({ value, onChange, data, label, id_key, defaultValue }) => {
  return (
    <FormControl style={{ width: '100%',minWidth:150 }} error={!value}>
      <InputLabel id={"form_label_" + id_key}>{label}</InputLabel>
      <Select
        labelId={"form_label_" + id_key}
        id={"form_select_" + id_key}
        data-testid={"form_select_" + id_key}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
      >
        {data.map((obj, index) => {
          return (
          
            <MenuItem
              id={"form_select_item_" + id_key + "_" + index}
              value={!obj.is_closed ? obj.id : null} 
              data-testid={"form_select_item_" + id_key + "_" + index}
            >
               <If condition={!obj.is_closed}>
              {obj.value  + " - " + obj.id_number}
              </If>
              <If condition={obj.is_closed}>
             <span class="colorDesha"> {obj.value + " - " + obj.id_number}</span>
              </If>
            </MenuItem>
            
          );
        })}
      </Select>
      {!value && (
        <FormHelperText id={"form_select_helper_" + id_key}>
          Seleccione una opción
        </FormHelperText>
      )}
    </FormControl>
  );
};
