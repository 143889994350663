import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { TextField, Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FileCopyIcon from '@material-ui/icons/FileCopy';


export default class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddCompanyDialog: false,
      url: ""
    };
  }
  copyCodeToClipboard = () => {
    const el = this.text
    el.select()
    document.execCommand("copy")
  }

  render() {
    if (this.props.valuesEntityFile.entityId != null && this.props.valuesEntityFile.companyId != null) {
      this.state.url = window.location.origin + "/questionnaire?entity=" + this.props.valuesEntityFile.entityId + "&company=" + this.props.valuesEntityFile.companyId;
    }
    return (
      <Fragment>
        <Grid item xs={12}>
          <TextField 
            id="filled-margin-none" 
            variant="filled"
            style={{ width: '32%' }}
            margin="normal"
            value={"Selecciona una Organización Territorial y un Cliente para generar el link:"}
          />
          <TextField
            id="outlined-basic" 
            variant="outlined"
            id="filled-read-only-input"
            InputProps={{ readOnly: true }}
            style={{ width: '42%' }}
            margin="normal"
            inputRef={el => this.text = el}
            value={this.state.url}
          />
          <Button
            id="btn_copy"
            variant="text"
            color="inherit"
            style={{ height: '77px' }}
            onClick={() => this.copyCodeToClipboard()}
          >
            <FileCopyIcon />
          </Button>
          {/* <div class="fuente">
            Aquí puede copiar el enlace al cuestionario correspondiente:  
                
            <textarea
            class="ancho"
            ref={(textarea) => this.textArea = textarea}
            value={this.state.url }
          />
               
              
               <Button 
              id="btn_copy"
              variant="text" 
              color="inherit"
              style={{ height: '77px'}}
              onClick={() => this.copyCodeToClipboard()}
              >
            <FileCopyIcon />
            </Button>
            
            </div>  */}
        </Grid>
      </Fragment>
    );
  }
}



const mapStateToProps = (state) => ({
  valuesEntityFile: state.valuesEntityFile,
  documentSpeciesArray: state.data.documentSpeciesArray,
});

Survey = connect(mapStateToProps, {})(Survey);
