/* eslint-disable react/no-set-state */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Table from "components/SimpleTable";
import { createData } from "functions/fetchApi";
import { setDeleteFlagQuery } from "./queries";
import AddTextFilesDialog from "./components/AddTextFilesDialog";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { hidehelp } from "common/actions/components/values/help";
import {softDeleteDocument} from "common/actions/data/delete";


class TableDocumentOverview extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      openUploadDocumentDialog: false,
      loaded: false,
      placeholder: 'Cargando datos',
      columns: new Map([
        ['id', { name: 'ID archivo', filter: 'default' }],
        ['file', { name: 'Nombre de archivo', filter: 'default' }],
        ['company_name', { name: 'Empresa', filter: 'default' }],
        ['document_species__value', { name: 'Tipo de documento', filter: 'default' }],
        ['upload_by', { name: 'Subido por', filter: 'default' }],
        ['status__value', { name: 'Estado', filter: 'default' }],
        ['ts_upload',{ name: 'Fecha de carga', filter: 'default' }],
        ['ts_change',{ name: 'Fecha de modificacion', filter: 'default' }],
        ['entity__name',{ name: 'Organización', filter: 'default' }],
      ]),
    }; 
  }

  
  handleOpenUploadFileDialog = () => {
    this.props.hidehelp();
    this.setState(() => {
      return {
        openUploadDocumentDialog: true,
      };
    });
  };

  handleCancelUploadFileDialog = () => {
    this.setState(() => {
      return {
        openUploadDocumentDialog: false,
      };
    });
  };

  handleSaveUploadFileDialog = (e) => {
    this.setState(() => {
      return {
        openUploadDocumentDialog: false,
      };
    });
  };

  handleDeleteRow = (row) => {
    const query = setDeleteFlagQuery(row["id"]);

    createData(query);
    this.props.softDeleteDocument(row["id"]);
  };

  render() {
    return (
      <Fragment>
        <div>
          <Table
            actionIcon={this.props.useActionIcon == true ? AddCircleOutlineIcon:undefined}
            viewOnly = {!this.props.useActionIcon}
            actionIconTooltip={this.props.useActionIcon==true ? "Añadir documentos":undefined}
            data={this.props.documentOverviewArray}
            onClick={this.handleOpenUploadFileDialog}
            title="Documentos"
            standardActions={this.props.standardActions}
            onDeleteRow={this.handleDeleteRow}
            titleConfirmDeleteRow={"Eliminar documento"}
            textConfirmDeleteRow={"¿Está seguro de que desea eliminar el documento?"}
            skipOtherColumns={true}
            columns={this.state.columns}
            
          />
        </div>
        <AddTextFilesDialog
          onCloseDialog={this.handleCancelUploadFileDialog}
          onCancel={this.handleCancelUploadFileDialog}
          onSave={this.handleSaveUploadFileDialog}
          open={this.state.openUploadDocumentDialog}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  documentOverviewArray: ownProps.documentsArrayFiltred || state.data.documentOverviewArray,
  standardActions: ownProps.standardActions || [{name:'delete'}],
  useActionIcon: ownProps.useActionIcon && true,
}}

TableDocumentOverview = connect(mapStateToProps, {
  hidehelp,
  softDeleteDocument
})(TableDocumentOverview);

export default TableDocumentOverview;
