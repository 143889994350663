import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Divider, Typography } from "@material-ui/core";
import { connect } from "react-redux";

import {
  GeneralDocumentFormSection,
  InvoiceDetailsFormSection,
  AdditionalDocumentFormSection,
  Survey
} from "./components";

import {
  ADDITIONAL_DOC,
  INVOICE,
  QUESTIONAIRE
} from "common/constants/model/values/documentSpecies";

export default class StepCategorizeDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddCompanyDialog: false,
    };
  }  

  handleOpenAddCompanyDialog = () => {
    this.setState(() => {
      return {
        openAddCompanyDialog: true,
      };
    });
  };

  isDocumentTargetSpecies = (targetDocumentSpecies) => {    
    let documentSpecies = this.props.documentSpeciesArray.filter(
      (s) => s.id == this.props.valuesEntityFile.documentSpeciesId
    )[0];
    return documentSpecies.value == targetDocumentSpecies;
  };

  render() {
    return (
      <Fragment>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          aligncontent="flex-start"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography style={{ marginTop: 20 }}>
              <h3>Informacíon general de fichero</h3>
            </Typography>
            <Divider variant="insert" />
          </Grid>

          <GeneralDocumentFormSection />

          {this.isDocumentTargetSpecies(ADDITIONAL_DOC) ? (
            <Fragment>
              <Grid item xs={12}>
                <Typography style={{ marginTop: 20 }}>
                  <h3>Detalles de documento adicional</h3>
                </Typography>
                <Divider variant="insert" />
              </Grid>
              <AdditionalDocumentFormSection />
            </Fragment>
          ) : null}

          {this.isDocumentTargetSpecies(INVOICE) ? (
            <Fragment>
              <Grid item xs={12}>
                <Typography style={{ marginTop: 20 }}>
                  <h3>Detalles de la factura</h3>
                </Typography>
                <Divider variant="insert" />
              </Grid>
              <InvoiceDetailsFormSection />
            </Fragment>
          ) : null}

      {this.isDocumentTargetSpecies(QUESTIONAIRE) ? (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 20 }}>
                      <h3>Cuestionario</h3>
                    </Typography>
                    <Divider variant="insert" />
                  </Grid>
                  <Survey />
                   
                </Fragment>
              
              ) : null}



        </Grid>
      </Fragment>
    );
  }
}

StepCategorizeDocuments.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  valuesEntityFile: state.valuesEntityFile,
  documentSpeciesArray: state.data.documentSpeciesArray,
});

StepCategorizeDocuments = connect(mapStateToProps, {})(StepCategorizeDocuments);
