import React, { Component } from 'react';
import Chart from 'chart.js';
import moment from 'moment';
let esLocale = require('moment/locale/es');
moment.locale('es', esLocale);

//datasets:[
//   {
//     label:""//title of the data
//     data:[]
//   }
// ]
class LineChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidUpdate() {
    if (!this.props.datasets) {
      return;
    }

    this.chart.data.labels = this.props.datasets
      .map((set) =>
        set.data.map((d) => {
          return d.label;
        })
      )
      .flat()
      .filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });

    this.chart.data.datasets = this.props.datasets.map((set) => {
      return {
        label: set.title,
        data: set.data.map((d) => d.value),
        fill: 'none',
        backgroundColor: set.color,
        pointRadius: 2,
        pointStyle: 'circle',
        borderColor: set.color,
        borderWidth: 1,
        lineTension: 0,
        spanGaps: false,
      };
    });
    this.chart.update();
  }

  componentDidMount() {
    let labels_ = this.props.datasets
      .map((set) => set.data.map((d) => d.label))
      .flat()
      .filter(function(elem, index, self) {
        return index === self.indexOf(elem);
      });

    let datasets_ = this.props.datasets.map((set) => {
      return {
        label: set.title,
        data: set.data.map((d) => d.value),
        fill: 'none',
        backgroundColor: set.color,
        pointRadius: 2,
        borderColor: set.color,
        borderWidth: 1,
        lineTension: 0,
      };
    });

    this.chart = new Chart(this.chartRef.current, {
      type: 'line',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                stepSize: 1,
              },
            },
          ],
        },
      },
      data: {
        labels: labels_,
        datasets: datasets_,
      },
    });
  }

  render() {
    return <canvas ref={this.chartRef} height={100} />;
  }
}

export default LineChart;
