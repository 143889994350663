import React, { Component, Fragment } from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,  
  Grid
} from "@material-ui/core";
import If, { Else } from 'if-else-react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import EuroIcon from '@material-ui/icons/Euro';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReceiptIcon from '@material-ui/icons/Receipt';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
export default class KpiCardAbbyy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setNumberFormat(number) {
    if (!number) {
      number = 0;
    }
    var nround = Math.round(number);
    const ndigit = nround.toString().length;
    var res = null;
    var suffix = "";
    if (this.props.unittype) {
      suffix = this.props.unittype;
    }
    var digits = null;
    if (this.props.decimalScale) {
      digits = this.props.decimalScale;
    }

    // digits;
    if (ndigit > 9) {
      res = (nround / 1000000000).toFixed(digits).concat(" B ", suffix);
    }
    if (ndigit <= 9 && ndigit > 6) {
      res = (nround / 1000000).toFixed(digits).concat(" M ", suffix);
    }
    if (ndigit <= 6 && ndigit > 3) {
      res = (nround / 1000).toFixed(digits).concat(" K ", suffix);
    }
    if (ndigit <= 3) {
      res = number.toFixed(digits).concat(" ", suffix);
    }
    return res;
  }

  render() {
    return (
      <Fragment>
        <Card elevation={3} alignContent="center" style={{vw: 600, height:330}}>
          <CardHeader            
            title={
              <Typography variant="h5" style={{ textAlign: 'center'}} >
                {this.props.kpiTitle}
              </Typography>
            }
          />
          <CardContent style={{marginTop: -7}}>
              <Grid container alignContent="center" style={{ padding: 1 }}>
                <Grid item xs={12}  >
                <Typography variant="h5" style={{ textAlign: 'center', color: '#7E79B0' }} >
                    {this.props.kpiMainTitle}  
                </Typography> 
                <Typography variant="h1" style={{ textAlign: 'center', color: '#7E79B0' }} >
                    {this.setNumberFormat(this.props.kpiMain)} 
                </Typography> 
                </Grid>
                
                {<Grid item xs={4} style={{  paddingLeft: "5%" , paddingTop: "5%"}} >
                 <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
                 
                 <If condition={this.props.kpiSub1Title }>    
                    {this.props.kpiSub1Title} 
                   
                    </If>
                </Typography>
              
                </Grid>}
                {<Grid item xs={2} style={{  paddingTop: "5%"}} >
                 <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20}}>
        
                      
                    <span className="spanReport "> {this.setNumberFormat(this.props.kpiSub1)}</span>
                  
                    
                </Typography>
                
                </Grid>}




                {<Grid item xs={4} style={{  paddingLeft: "5%", paddingTop: "5%" }} >
                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
               
                
                    {this.props.kpiSub1_2Title}
                   
                   
                </Typography>
                
                </Grid>}



                {<Grid item xs={2} style={{  paddingTop: "5%" }} >
                <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20}}>
              
                  
                    <span className="spanReport "  > {this.setNumberFormat(this.props.kpiSub1_2)}</span>
                
                   
                    
                   
                </Typography>
                
                </Grid>}









                {<Grid item xs={4} style={{  paddingLeft: "5%" }} >
                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
               
                <If condition={this.props.kpiSub2Title }>    
                    {this.props.kpiSub2Title}
                    </If>
                   
                </Typography>
                
                </Grid>}



                {<Grid item xs={2}>
                <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20 }}>
  
                   
                    <span className="spanReport " > {this.setNumberFormat(this.props.kpiSub2)}</span>
                   
                   
                </Typography>
                
                </Grid>}










                {<Grid item xs={4} style={{  paddingLeft: "5%" }} >
                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
               
                <If condition={this.props.kpiSub2_2Title }>    
                    {this.props.kpiSub2_2Title}
                    </If>
                   
                </Typography>
                
                </Grid>}



                {<Grid item xs={2}  >
                <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20 }}>
  
                   
                    <span className="spanReport "> {this.setNumberFormat(this.props.kpiSub2_2)}</span>
                   
                   
                </Typography>
                
                </Grid>}























                {<Grid item xs={4} style={{  paddingLeft: "5%" }} >

                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
                
                <If condition={this.props.kpiSub3Title }>    
                    {this.props.kpiSub3Title}
                    </If>
                   
                </Typography>
               
                </Grid>}



                {<Grid item xs={2} style={{  paddingLeft: "5%" }} >
                <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20 }}>
             
                    <If condition={this.props.kpiSub3Title }>    
                    <span className="spanReport">  {this.setNumberFormat(this.props.kpiSub3)}</span>
                    </If>
                   
                </Typography>
               
                </Grid>}




                {<Grid item xs={4} style={{  paddingLeft: "5%" }} >
                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
                
                <If condition={this.props.kpiSub4Title }>    
                    {this.props.kpiSub4Title}
                    </If>
                    
                </Typography>
    
                </Grid>}



                {<Grid item xs={2} style={{  paddingLeft: "5%" }} >
                <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20 }}>

                  
                    <span className="spanReport">{this.setNumberFormat(this.props.kpiSub4)}</span> 
                   
                    
                </Typography>
    
                </Grid>}


                {<Grid item xs={3} style={{  paddingLeft: "5%" }} >
                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
                 
                <If condition={this.props.kpiSub5Title }>    
                    {this.props.kpiSub5Title}
                    </If>
                    
                </Typography>
               
                </Grid>}







                {<Grid item xs={3} style={{  paddingLeft: "5%" }} >
                <Typography variant="h5" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20 }}>
      
                    <If condition={this.props.kpiSub5Title }>    
                    <span className="spanReport">{this.setNumberFormat(this.props.kpiSub5)}</span> 
                    </If>
                   
                </Typography>
               
                </Grid>}


                {<Grid item xs={3} style={{  paddingLeft: "5%"}} >
                <Typography variant="h6" style={{ textAlign: 'left', marginTop: 20 }}>
                
                <If condition={this.props.kpiSub6Title }>    
                    {this.props.kpiSub6Title}
                    </If>
                   
                </Typography>
               
                </Grid>}


                {<Grid item xs={3} style={{  paddingLeft: "5%" }} >
                <Typography variant="h6" style={{ textAlign: 'right', color: '#7E79B0', marginTop: 20 }}>
               
                    <If condition={this.props.kpiSub2Title }>    
                    <span className="spanReport"> {this.setNumberFormat(this.props.kpiSub6)}</span>
                    </If>
                    
                </Typography>
               
                </Grid>}
                
 



              </Grid>
                        
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}