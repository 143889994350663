
export const createDataQuestionnare = (nombre, idNumber, representante, representanteIdNumber, mail, telefono, direccion, provincia, tipo, uuid) => {
    return [
        {
            name: "PublicCompany",
            type: "add",
            values:{
                agent_name: representante,
                agent_id_number: representanteIdNumber,
                company_name: nombre,
                company_id_type: tipo,
                company_province: provincia,
                company_address: direccion,
                company_email: mail,
                company_phone: telefono,
                company_id_number: idNumber,
                entity_id: uuid
            }
        }
    ]
};
