// helper function
export const tokenConfig = () => {
    // Get token
    const token_access = localStorage.getItem('token_access');
  
    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  
    if (token_access) {
      config.headers['Authorization'] = `JWT ${token_access}`;
    }
  
    return config;
  };