import {
  GET_COMPANY_ARRAY,  
} from 'common/constants/data';
import {
  STATE_KEY_DATA
} from 'common/constants/states'
import { getCompanyDataQueries, updateCompanyStatusQuery, createEntityCompanyQuery, getupdateStatusFiles, updateFilesStatusToAbbyy } from './queries';
import { getData, createData } from 'functions/fetchApi';
import {reset} from 'redux-form';


export const loadcompanyarray = ( companyId) => async (dispatch) => {
  
  var filters={}
  
  if(companyId  != "" && companyId != undefined){
    filters.id= companyId;
  }
  let queries = getCompanyDataQueries(filters);
  await getData(queries).then((resultsets) => {
    
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          const companyArray = resultsets[0].result;
          dispatch({
            type: GET_COMPANY_ARRAY,
            payload: { companyArray },
          });
        }
      }
    }
  });
};

export const updateEntityFiles = (fileId, status) => async (dispatch) => {




let queries = getupdateStatusFiles(fileId, status);

await createData(queries).then((resultsets) => {

});
};

export const updateMultiFileStatus = (companyId, status) => async (dispatch) => {
    let queries = updateFilesStatusToAbbyy(companyId);
    await createData(queries).then((resultsets) => {});
};

export const createCompany = (data) => async (dispatch) => {
  let qry = createEntityCompanyQuery(data);
  delete qry[0].identifiers.entity;
  delete qry[0].defaults.entity;

  delete qry[0].identifiers.id_type;
  delete qry[0].defaults.id_type;

  delete qry[0].identifiers.type;
  delete qry[0].defaults.type;
 
  
 let dataCreate= await createData(qry).then((resultsets) => {    
   
    dispatch(loadcompanyarray());
    
    return resultsets;
  })

  dispatch(reset('companyForm'));
 return dataCreate
};

//TODO make the query

export const updateCompany = (companyUpdatedData) => async (dispatch) => {
  let data = JSON.parse(localStorage.getItem(STATE_KEY_DATA));
  const oldData = data.companyArray.find((company) => company.id === companyUpdatedData.id)

  

  let companySendData = { ...oldData, ...companyUpdatedData };

  companySendData.id_type_id = companySendData.id_type;
  companySendData.type_id = companySendData.type;


  delete companySendData.has_invoices;
  delete companySendData.has_contract;
  delete companySendData.has_paid;
  delete companySendData.has_liter_summary;
  delete companySendData.has_questionnaire;
  delete companySendData.has_additional_doc;
  

  //TODO UPDATE QUERY  
  const updateQuery = updateCompanyStatusQuery(companySendData);    
  delete updateQuery[0].identifiers.entity;
  delete updateQuery[0].defaults.entity;
  delete updateQuery[0].identifiers.id_type;
  delete updateQuery[0].defaults.id_type;
  delete updateQuery[0].identifiers.type;
  delete updateQuery[0].defaults.type;
  delete updateQuery[0].defaults.id;
  delete updateQuery[0].defaults.entityId;
  delete updateQuery[0].defaults.value;  
  let dataCreate= await createData(updateQuery).then((resultsets) => {
    if (resultsets) {      
      data = data.companyArray.map((company) => {
        if (company.id === companyUpdatedData.id) {
          company = companyUpdatedData;
        }
        return company;
      });      
      dispatch(loadcompanyarray());
    }
  });
  return dataCreate
};


