import {
  SLICER_COMPANY_ARRAY,
  SLICER_DATA_INVOICE,
  SLICER_DATA_LITER,
  SLICER_DATA_QUESTIONNAIRE,
  SLICER_DATA_DOCUMENTS
} from "common/constants/data";

import { loadState, saveState } from "functions/stateHelper";
import { STATE_KEY_SLICER } from "common/constants/states";

const init = {
  companySlicer: null,
  copmanyUpdateCycle: 0,
  invoiceDetailsSlicer: null,
  invoiceDetailsUpdateCycle: 0,
  literSummaryDetailsSlicer: null,
  literSummaryUpdateCycle: 0,
  questionnaireDetailsSlicer: null,
  questionnaireUpdateCycle: 0,
  documentsSlicer: null,
  documentsUpdateCycle: 0,
};

let initialState = loadState(STATE_KEY_SLICER, init);

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case SLICER_COMPANY_ARRAY:
      updatedState = {
        ...state,
        companySlicer: action.payload.slicer,
        copmanyUpdateCycle: state.copmanyUpdateCycle + 1,
      };
      break;
    case SLICER_DATA_INVOICE:
      updatedState = {
        ...state,
        invoiceDetailsSlicer: action.payload.slicer,
        invoiceDetailsUpdateCycle: state.invoiceDetailsUpdateCycle + 1,
      };
      break;
    case SLICER_DATA_LITER:
      updatedState = {
        ...state,
        literSummaryDetailsSlicer: action.payload.slicer,
        literSummaryUpdateCycle: state.literSummaryUpdateCycle + 1,
      };
      break;
    case SLICER_DATA_QUESTIONNAIRE:
      updatedState = {
        ...state,
        questionnaireSlicer: action.payload.slicer,
        questionnaireUpdateCycle: state.questionnaireUpdateCycle + 1,
      };
      break;
    case SLICER_DATA_DOCUMENTS:
      updatedState = {
        ...state,
        documentsSlicer: action.payload.slicer,
        documentsUpdateCycle: state.documentsUpdateCycle + 1,
      };
      break;
    default:
      return state;
  }

  saveState(updatedState, STATE_KEY_SLICER);
  return updatedState;
}
