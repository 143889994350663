import React, { Component } from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import { formatNumberWithCommas } from './helper';

let esLocale = require('moment/locale/es');
moment.locale('es', esLocale);

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = { colors: {} };
  }

  componentDidUpdate() {
    if (!this.props.datasets) {
      return;
    }
    this.chart.data.labels = this.props.labels;

    this.chart.data.datasets = this.props.datasets.map((set) => {
      return {
        type: 'bar',
        label: set.label,
        data: set.data.map((d) => d),
        fill: 'none',
        backgroundColor: set.color || this.getColor(set.label),
        pointRadius: 2,
        borderColor: set.color || this.getColor(set.label),
        borderWidth: 1,
        lineTension: 0,
        rotated: true
      };
    });
    this.chart.update();
  }

  componentDidMount() {
    let labels_ = this.props.labels;

    // let labels_ = this.props.datasets
    //   .map((set) => set.data.map((d) => d.label))
    //   .flat()
    //   .filter(function(elem, index, self) {
    //     return index === self.indexOf(elem);
    //   });

    let datasets_ = this.props.datasets.map((set) => {
      return {
        type: 'bar',
        label: set.label,
        data: set.data.map((d) => d),
        fill: 'none',
        backgroundColor: set.color || this.getColor(set.label),
        pointRadius: 2,
        borderColor: set.color || this.getColor(set.label),
        borderWidth: 1,
        lineTension: 0,
      };
    });

    this.chart = new Chart(this.chartRef.current, {
      type: 'bar',
      options: {
        tooltips: {
          mode: 'label',
          callbacks: {
            label: function(tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label +
                ': ' +
                formatNumberWithCommas(tooltipItem.yLabel)
              );
            },
          },
        },
        scales: {
          // xAxes: [
          //   {
          //     type: 'time',
          //     time: {
          //       unit: 'year',
          //       displayFormats: { year: 'YYYY' },
          //       min: '2004',
          //       max: '2024',
          //     },
          //   },
          // ],
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
        legend: {
          labels: {
            fontSize: 11,
            usePointStyle: true,
            padding: 15,
          },
        },
      },
      data: {
        labels: labels_,
        datasets: datasets_,
      },
    });
  }

  getColor(label) {
    if (!this.state.colors[label]) {
      this.state.colors[label] = this.generateRandomColor();
      this.setState({ colors: this.state.colors });
    }

    return this.state.colors[label];
  }

  generateRandomColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  render() {
    return <canvas ref={this.chartRef} height={100} />;
  }
}

export default BarChart;
