import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Grid, TextField, Typography, Link } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { fetchApiRequestPassword } from 'functions/fetchApi'

class RequestPasswordConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {      
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    formValues.preventDefault();
    var formSubmit = {
      email: formValues.target.email.value      
    };
    fetchApiRequestPassword(formSubmit)
  }

  renderField = ({ input, label, type, meta: { touched, error }, style }) => {
    return (
      <div className={`field ${touched && error ? "error" : ""}`}>
        <TextField id="standard-basic" {...input} type={type} label={label} style={style} variant="outlined"/>
        {touched && error && (
          <span className="ui pointing red basic label">{error}</span>
        )}
      </div>
    );
  };

  hiddenField = ({ type, meta: { error } }) => {
    return (
      <div className="field">
        <input type={type} />
        {error && <div className="ui red message">{error}</div>}
      </div>
    );
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ padding: 96 }}
      >        
        <Grid item>
          <Paper style={{ width: 300, minHeight: "42vh", padding: 16, marginTop: 100 }}>
            <form onSubmit={this.handleSubmit} className="ui form">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid xs={12}>
                  <Typography align="center" variant="h3" style={{marginTop: 24}}>
                    Enviando petición
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CheckCircleIcon color="primary" fontSize="large"/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottomhy display="block" align="center" >
                  Una vez enviado, recibirá un 
                  correo electrónico con más instrucciones 
                  para reestablecer su contraseña. Este proceso durará pocos minutos.
                  Por favor, póngase en contacto con el 
                  <Link href={"mailto:info@afectadosporlaspetroleras.es"}>{" administrador "}</Link> 
                  para más información.                  
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default reduxForm({
  form: "loginForm",
})(RequestPasswordConfirmation);
