import React, { Component } from 'react';
import Chart from 'chart.js';
import moment from 'moment';
import { formatNumberWithCommas } from './helper';

let esLocale = require('moment/locale/es');
moment.locale('es', esLocale);

class BarChartHor extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = { colors: {} };
  }

  componentDidUpdate() {
    if (!this.props.datasets) {
      return;
    }
    this.chart.data.labels = this.props.labels;
    this.chart.data.datasets = this.props.datasets
    this.chart.update();
  }

  componentDidMount() {

    this.chart = new Chart(this.chartRef.current, {
      type: this.props.type,
      data: {
        labels: this.props.labels,
        datasets: this.props.datasets
      },
    });
  }

  getColor(label) {
    if (!this.state.colors[label]) {
      this.state.colors[label] = this.generateRandomColor();
      this.setState({ colors: this.state.colors });
    }

    return this.state.colors[label];
  }

  generateRandomColor() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }

  render() {
    return <canvas ref={this.chartRef} height={100} />;
  }
}

export default BarChartHor;
