import { GET_DOCUMENT_DATA_AGGREGATED } from "common/constants/data";
import { getDocumentAggregatedDataQueries } from "./queries";
import { getData } from "functions/fetchApi";


export const loaddocumentaggregateddata = (e) => async (dispatch) => {
  let queries = getDocumentAggregatedDataQueries(e);
  await getData(queries).then((resultsets) => {
    if (resultsets) {
      for (const resultset of resultsets) {
        if (resultset.result.length > 0) {
          let documentAggregatedArray = resultset.result          
          dispatch({
            type: GET_DOCUMENT_DATA_AGGREGATED,
            payload: { documentAggregatedArray },
          });
        }
      }
    }
  });
};
