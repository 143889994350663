import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

import {
  changeSupplier,
  changeInvoiceType,
  changeDocumentYear,
} from "common/actions/components";

import { FormControlSelector, FormControlTextInput } from "components";

export default class InvoiceDetailsFormSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Fragment>
        <Grid item>
          <FormControlSelector
            value={this.props.valuesInvoiceDetails.supplierId}
            onChange={(e) => {
              return this.props.changeSupplier(e.target.value);
            }}
            data={this.props.supplierArray}
            label="Proveedor"
            id_key="supplier"
          ></FormControlSelector>
        </Grid>
        {/* <Grid item>
          <FormControlSelector
            value= "1" 
            // value={this.props.valuesInvoiceDetails.invoiceTypeId}
            onChange={(e) => {
              return this.props.changeInvoiceType(e.target.value); 
            }}
            data= {[{
              id: 1,
              value: "EESS"
            } ]}
            label="Tipo de factura"
            id_key="invoiceType"
          ></FormControlSelector>
        </Grid>

         */}
        <Grid item>
          <FormControlSelector
            value={this.props.valuesInvoiceDetails.documentYear}
            onChange={(e) => {
              return this.props.changeDocumentYear(e.target.value);
            }}
            data={[ { value: "2002", id: "2002"}, { value: "2003", id: "2003"}, { value: "2004", id: "2004"}, { value: "2005", id: "2005"},{ value: "2006", id: "2006"},{ value: "2007", id: "2007"},{ value: "2008", id: "2008"},{ value: "2009", id: "2009"},{ value: "2010", id: "2010"},{ value: "2011", id: "2011"},{ value: "2012", id: "2012"},{ value: "2013", id: "2013"},{ value: "2014", id: "2014"},{ value: "2015", id: "2015"},{ value: "2016", id: "2016"},{ value: "2017", id: "2017"},{ value: "2018", id: "2018"},{ value: "2019", id: "2019"},{ value: "2020", id: "2020"},{ value: "2021", id: "2021"},{ value: "2022", id: "2022"}, {value: "2023", id: "2023"}]}
            label="Año"
            id_key="invoiceYear"
           
          ></FormControlSelector>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  valuesInvoiceDetails: state.valuesInvoiceDetails,
  invoiceTypeArray: state.data.invoiceTypeArray,
  supplierArray: state.data.supplierArray,
});

InvoiceDetailsFormSection = connect(mapStateToProps, {
  changeSupplier,
  changeInvoiceType,
  changeDocumentYear,
})(InvoiceDetailsFormSection);
