import {
  CHANGE_SUPPLIER,
  CHANGE_INVOICE_TYPE,
  CHANGE_DOCUMENT_YEAR,
} from "common/constants/components";

import { loadState, saveState } from "functions/stateHelper";
import {STATE_KEY_INVOICE } from "common/constants/states";

const init = {
  supplierId: null,
  invoiceTypeId: null,
  documentYear: null,
  checkSelectedCategories: false,
};

let initialState = loadState(STATE_KEY_INVOICE, init);

const checkSelectedCategories = (state) => {
  return state.supplierId !== null && state.invoiceTypeId !== null && state.documentYear !== null  && state.documentYear !== "";
};

export default function(state = initialState, action) {
  let updatedState = null;
  switch (action.type) {
    case CHANGE_SUPPLIER:
      updatedState = {
        ...state,
        supplierId: action.payload.supplierId,        
      };
      break;
    case CHANGE_INVOICE_TYPE:
      updatedState = {
        ...state,
        invoiceTypeId: action.payload.invoiceTypeId,        
      };
      break;
    case CHANGE_DOCUMENT_YEAR:
      updatedState = {
        ...state,
        documentYear: action.payload.documentYear,        
      };
      break;
    default:
      return state;
  }

  Object.assign(updatedState, {
    checkSelectedCategories: checkSelectedCategories(updatedState),
  });

  saveState(updatedState, STATE_KEY_INVOICE);
  return updatedState;
}
