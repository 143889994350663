import { SOFT_DELETE_DOKUMENT } from "common/constants/data";
import { STATE_KEY_DATA } from "common/constants/states";

export const softDeleteDocument = (id) => async (dispatch) => {
  let documentOverviewArray = JSON.parse(localStorage.getItem(STATE_KEY_DATA)).documentOverviewArray;

  documentOverviewArray = documentOverviewArray.filter((document) => {
    return document["id"] !== id;
  });
  dispatch({
    type: SOFT_DELETE_DOKUMENT,
    payload: { documentOverviewArray },
  });
};
