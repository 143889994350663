import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Toolbar,

} from '@material-ui/core';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import MenuIcon from '@material-ui/icons/Menu';
import CachedIcon from '@material-ui/icons/Cached';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import BarChartIcon from '@material-ui/icons/BarChart';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { useLocation } from 'react-router-dom';
import ArrowTooltip from 'components/Tooltip';
import { logout } from 'common/actions/auth';
import { showhelp, hidehelp } from 'common/actions/components';
import {
  loadcompanyarray,
  loadcompanytypearray,
  loaddocumentaggregateddata,
  loaddocumentformatarray,
  loaddocumentoverviewdata,
  loaddocumentspeciesarray,
  loadentityarray,
  loadidtypearray,
  loadinvoicedetailsdata,
  loadfunnelabbyy,
  loadinvoicetypearray,
  loadliterdetailsdata,
  loadsupplierarray,
  downloaddemovideo,
} from 'common/actions/data';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  helpButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  helpButtonToggled: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  alignItemsAndJustifyContent: {
    width: 610,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Roboto',
  },
}));

function Topbar(props) {

  const { className } = props;
  const rest = {};
  for (const key of Object.keys(props)) {
    if (!['className', 'onSidebarOpen'].includes(key)) {
      rest[key] = props[key];
    }
  }


  const Header = () => {
      return <Toolbar />;
  };
  const classes = useStyles();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      style={{ background: '#469e4a' }}
    >
      <Header />
    </AppBar>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userinfo: state.data.userDataArray,
  demovideo: state.data.demovideo,
  ishelpdisplayed: state.valuesHelp.showhelp,
});

Topbar = connect(mapStateToProps, {
  logout,
  showhelp,
  hidehelp,
  loadcompanyarray,
  loadcompanytypearray,
  loaddocumentaggregateddata,
  loaddocumentformatarray,
  loaddocumentoverviewdata,
  loaddocumentspeciesarray,
  loadentityarray,
  loadidtypearray,
  loadinvoicedetailsdata,
  loadfunnelabbyy,
  loadinvoicetypearray,
  loadliterdetailsdata,
  loadsupplierarray,
  downloaddemovideo,
})(Topbar);

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
