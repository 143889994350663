import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import TableDocumentOverview from "./components/TableDocumentOverview/";
import { login, refreshtoken } from "../../common/actions/auth/auth";
import {  loaddocumentaggregateddata,
  loaddocumentoverviewdata,
  loaddocumentformatarray,
  loaddocumentspeciesarray
} from "common/actions/data";
import { Backdrop, CircularProgress } from '@material-ui/core';

export default class DocumentOverview extends Component {
  constructor() {
    super();
    this.state = {
      openLoadingScreen: true,
    };
    this.references = {};
  }

  getOrCreateRef(id) {
    if (!Object.keys(this.references.indexOf(id))) {
      this.references[id] = React.createRef();
    }
    return this.references[id];
  }


  render() {
    this.props.refreshtoken();
    var filters={};
    filters.delete_flag= false; 
       this.props.loaddocumentaggregateddata(filters);
      this.props.loaddocumentoverviewdata(filters);
      this.props.loaddocumentformatarray();
      this.props.loaddocumentspeciesarray();
    this.state.openLoadingScreen= false ;
    
    return (
      
      <div style={{ padding: 24 }}>
         <Backdrop
          className="loading"
          open={this.state.openLoadingScreen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={2}>          
          <Grid item xs={12}>
            <TableDocumentOverview 
                useActionIcon = {this.props.userinfo.view_only ? false : true}
                title="Look at all you documents!!!" 
            />
          </Grid>
        </Grid>
      </div>
    );  
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userinfo: state.data.userDataArray,
  ishelpdisplayed: state.valuesHelp.showhelp
});
 
DocumentOverview = connect(mapStateToProps, {
  login,
  refreshtoken,
  loaddocumentaggregateddata,
  loaddocumentoverviewdata,
  loaddocumentformatarray,
  loaddocumentspeciesarray
})(DocumentOverview);

 