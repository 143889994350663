import { GET_USER_DATA } from "common/constants/data";

import { getUserData } from "functions/fetchApi";

export const loadUserData = () => async (dispatch) => {  
  const result = await getUserData();
  let userDataArray = result.result;
  dispatch({
    type: GET_USER_DATA,
    payload: { userDataArray },
  });
};
