import React, { Component, Fragment } from "react";
import {
  Dialog,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Transition from "../DialogTransition/";
import FileDrop from "../FileDrop/";
import CloseIcon from "@material-ui/icons/Close";
import { createDataUpload, createData, createDataUploadFile } from "functions/fetchApi";
import StepCategorizeDocuments from "../StepCategorizeDocuments/";
import { createTextUploadQueries, createFileDetailsQueries, createFileDetailsQueries2, createTextAndUploadFile } from "./queries";
import { selectDetailsQueryParameter, checkIfSelected } from "./helper";
import { loaddocumentoverviewdata, loadcompanyarray } from "common/actions/data";
import { connect } from "react-redux";
import { notifySuccess, enqueueSnackbar } from "common/actions/notifications";

function getSteps() {
  return [
    "Selecciona las categorías de los documentos",
    "Añade los documentos",
  ];
}

export default class AddTextFilesDialog extends Component {
  constructor(props) {
    super();
    this.steps = getSteps();
    this.state = {
      activeStep: 0,
      activateNext:false
    };
  }
  handleNext = () => {
    this.setState(() => {
      return {
        activeStep: this.state.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState(() => {
      return {
        activeStep: this.state.activeStep - 1,
      };
    });
  };
  

  handleReset = () => {
    this.setState(() => {
      return {
        activeStep: 0,
      };
    });
  };

  uploadTextFiles = async (e) => {
    console.log("EEEE" + JSON.stringify(e)  )
    var cont= 0;
    for (let i = 0; i < e.length; i++) {
      cont = cont + e[i].size;
    }
    if(cont> 100000000){
      var notify={};
      notify.message=  "El tamaño máximo del conjunto no debe superar los 100 Mb";
      this.props.enqueueSnackbar(notify);
       
      return false;
    }else{
      
      this.props.notifySuccess(
        "Subiendo ficheros"
      );
    }
    const files = e;
    if (files) {
      console.log("entra entra")
      let { modelName, details } = selectDetailsQueryParameter(
        this.props.documentSpeciesArray,
        this.props.valuesEntityFile.documentSpeciesId,
        this.props.valuesEntityInvoiceDetails,
        this.props.valuesAdditionalDocumentDetails
      );
      let queries2 = createFileDetailsQueries2(
        this.props.valuesEntityFile.entityId,
        modelName,
        details
      );
      const queries = createTextAndUploadFile({
        companyId: this.props.valuesEntityFile.companyId,
        entityId: this.props.valuesEntityFile.entityId,
        documentFormatId: this.props.valuesEntityFile.documentFormatId,
        documentSpeciesId: this.props.valuesEntityFile.documentSpeciesId,
        
      },queries2);
      console.log("queries" + JSON.stringify(queries))
      console.log("queries2" + JSON.stringify(queries2))
      await createDataUploadFile(queries, files)
        .then((resultsets) => {
          console.log("resultados"+  JSON.stringify(resultsets))
        })
        .then(() => {
          this.props.notifySuccess(
            "Se han subido los documentos correctamente"
          );
          this.handleReset();
          var filters={};
          filters.delete_flag= false; 
          this.props.loaddocumentoverviewdata(filters);
          this.props.loadcompanyarray();
        });
      
    }
    
  };

  getContent = (stepIndex) => {
    switch (stepIndex) {
      case 0: //filter
        return (
          <StepCategorizeDocuments onCloseDialog={this.props.onCloseDialog} />
        );
      case 1: //dropzone
        return (
          <FileDrop
            acceptedFiles={[".pdf", ".xlsx"]}
            handleupload={this.uploadTextFiles.bind(this)}
          />
        );
      default:
        return "";
    }
  };

  render() {
    return (
      <Fragment>
        <Dialog
          fullScreen
          onClose={() => {
            this.props.onCloseDialog();
          }}
          open={this.props.open}
          TransitionComponent={Transition}
        >
          <AppBar style={{ position: "relative", background: "#7E79B0" }}>
            <Toolbar>
              <IconButton
                aria-label="close"
                color="inherit"
                edge="start"
                onClick={() => {
                  this.props.onCloseDialog();
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography style={{ marginLeft: 2, flex: 1 }} variant="h6">
                Subir documentos
              </Typography>
            </Toolbar>
          </AppBar>
          <Stepper activeStep={this.state.activeStep} alternativeLabel>
            {this.steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid
            container
            style={{ padding: 24 }}
            spacing={3}
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              {this.state.activeStep === this.steps.length ? (
                <Button onClick={this.handleReset}>Reset</Button>
              ) : (
                <Fragment>
                  <Button
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleBack}
                    style={{ marginRight: 2 }}
                    data-testid="back_step-button"
                  >
                    Atrás
                  </Button>
                  <Button
                    disabled={
                      !checkIfSelected(
                       this.props.documentSpeciesArray,
                      this.props.valuesEntityFile,
                      this.props.valuesEntityInvoiceDetails,
                       this.props.valuesAdditionalDocumentDetails
                      )
                     }
                    onClick={this.handleNext}
                    data-testid="next_step-button"
                  >
                    {this.state.activeStep === this.steps.length - 1
                      ? "Terminar"
                      : "Siguiente"}
                  </Button>
                </Fragment>
              )}
              <Grid item xs={12}>
                {this.getContent(this.state.activeStep)}
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  valuesEntityFile: state.valuesEntityFile,
  valuesEntityInvoiceDetails: state.valuesInvoiceDetails,
  valuesAdditionalDocumentDetails: state.valuesAdditionalDocumentDetails,
  documentSpeciesArray: state.data.documentSpeciesArray,  
});

AddTextFilesDialog = connect(mapStateToProps, {
  loaddocumentoverviewdata,
  notifySuccess,
  loadcompanyarray,
  enqueueSnackbar
})(AddTextFilesDialog);

AddTextFilesDialog.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
