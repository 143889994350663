import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { getClientsBySuperentity ,getDocumentsBySuperentityAndProvider ,getLitersBySuperentity ,getLitersByProvider ,getLitersByYearAndProvider ,getAsociaciones ,getEntities ,getCompanies } from 'common/actions/data';
import Selector from '../../components/Charts/Selector';
import ClientesRegistrados from '../../components/Charts/ClientesRegistrados';
import DocumentosPlataforma from '../../components/Charts/DocumentosPlataforma';
import Litros from '../../components/Charts/Litros'
import LitrosYear from '../../components/Charts/LitrosYear'
import { connect } from 'react-redux';

let ReportPage = ({userinfo}) => {

	const [listaAsociacion, setListaAsociacion] = useState([]);
	const [listaEntidad, setListaEntidad] = useState([]);
	const [listaCompany, setListaCompany] = useState([]);
	const [documentosSubidos, setDocumentosSubidos] = useState({ total: 0, labels: [], datasets: [] });
	const [clientesPlataforma, setClientesPlaforma] = useState({ total: 0, data: [] });
	const [litrosYear, setLitrosYear] = useState({ labels: [], datasets: [] });
	const [litrosAsociacion, setLitrosAsociacion] = useState({ total: 0, data: [] });
	const [litrosProvider, setLitrosProvider] = useState({data: [], colors: []});	
	const [filter, setFilter] = useState({asociacion: 0, entity: 0, company: 0})

	const updateGraphClientes = (clients) => {
		setClientesPlaforma(prevState => ({
			...prevState,
			total: clients.total,
			data: clients.data
		}));
	}

	const updateGraphDocuments = (documents) => {
		setDocumentosSubidos(prevState => ({
			...prevState,
			total: documents.total,
			datasets: documents.data.datasets,
			labels: documents.data.labels
		}));
	}

	const updateGraphLitersSuperentity = (litersSuperentity) => {
		setLitrosAsociacion(prevState => ({
			...prevState,
			total: litersSuperentity.total,
			data: litersSuperentity.data
		}));
	}

	const updateGraphLitersProvider = (litersProvider) => {
		setLitrosProvider(prevState => ({
			...prevState,
			colors: litersProvider.colors,
			data: litersProvider.data
		}));
	}

	const updateGraphLitersYear = (litersYear) => {
		setLitrosYear(prevState => ({
			...prevState,
			datasets: litersYear.datasets,
			labels: litersYear.labels
		}));
	}

	const restoreState = async () => {
		let clients = await getClientsBySuperentity();
		let documents = await getDocumentsBySuperentityAndProvider();
		let litersSuperentity = await getLitersBySuperentity();
		let litersProvider = await getLitersByProvider();
		let litersYear = await getLitersByYearAndProvider();

		updateGraphClientes(clients)
		updateGraphDocuments(documents)
		updateGraphLitersSuperentity(litersSuperentity)
		updateGraphLitersProvider(litersProvider)
		updateGraphLitersYear(litersYear)

		setListaAsociacion(await getAsociaciones(userinfo.is_admin));
		setListaEntidad([{id: 0, value: 'TODOS'}]);
		setListaCompany([{id: 0, value: 'TODOS'}]);	
	}

	useEffect(() => {
		const consultarAPI = async () => {
            try {
				restoreState();	
            } catch (error) {  
                console.log(error);
            }
        }

		consultarAPI();
    }, [])

	useEffect(() => {
		const consultarAPI = async () => {

            try {

				let queryFilter = '';

				if (filter.company > 0) {
					queryFilter = {file__company__id: filter.company}
				} else if (filter.entity > 0) {
					setListaCompany(await getCompanies(filter.entity));
					queryFilter = {entity__id: filter.entity}
				} else if (filter.asociacion > 0){
					setListaEntidad(await getEntities(filter.asociacion));
					queryFilter = {entity__superentity__id: filter.asociacion}
				}

				if (filter.company == 0) {
					let clients = await getClientsBySuperentity(queryFilter);
					updateGraphClientes(clients)
				}

				let documents = await getDocumentsBySuperentityAndProvider(queryFilter);
				let litersSuperentity = await getLitersBySuperentity(queryFilter);
				let litersProvider = await getLitersByProvider(queryFilter);
				let litersYear = await getLitersByYearAndProvider(queryFilter);

				
				updateGraphDocuments(documents)
				updateGraphLitersSuperentity(litersSuperentity)
				updateGraphLitersProvider(litersProvider)
				updateGraphLitersYear(litersYear)
            } catch (error) {
                console.log(error);
            }
        }

		consultarAPI();
    }, [filter])

	const section = {
		height: "100%",
		paddingTop: 5,
		backgroundColor: "#fff"
	};

	return (
		<div style={{ marginTop: 10, padding: 15 }}>
		
		<Grid container spacing={1}>
			<Grid item xs lg={3} sm={6} xl={3} xs={12} >
				<div style={section}>
					<Selector
						listaAsociacion = {listaAsociacion}
						listaEntidad = {listaEntidad}
						listaCompany = {listaCompany}
						filter = {filter}
						setFilter = {setFilter}
					/>
				</div>
			</Grid>

			<Grid item xs lg={3} sm={6} xl={3} xs={12} >
				{<div style={section}>
					<ClientesRegistrados
						numeroClientes = {clientesPlataforma.total}
						datosClientes = {clientesPlataforma.data}
					/>
				</div>}
			</Grid>

			<Grid item xs={6} >
				 <div style={section}>
					<DocumentosPlataforma
						numeroDocumentos  = { documentosSubidos.total }
						labels = { documentosSubidos.labels }
						datasets = { documentosSubidos.datasets }
					/>
				</div>
			</Grid>
		</Grid>
		<Grid container spacing={1}>
			<Grid item xs={6} >
				<div style={section}>
					<Litros
						numeroClientes = {clientesPlataforma.total}
						datosClientes = {clientesPlataforma.data}
						numeroLitros = {litrosAsociacion.total}
						datosSuper = { litrosAsociacion.data }
						datosProvider = {litrosProvider.data}
						colorsProvider = {litrosProvider.colors}
					/>
				</div>
			</Grid>
			<Grid item xs={6} >
				<div style={section}>
					<LitrosYear
						datasets = {litrosYear.datasets}
						labels = {litrosYear.labels}
					/>
				</div>
			</Grid>
		</Grid>

		</div>
	);
}


const mapStateToProps = state => ({
	auth: state.auth,
	userinfo: state.data.userDataArray,
});

export default connect(mapStateToProps)(ReportPage);