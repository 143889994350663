import { createStore } from 'redux'
import rootReducer from 'common/reducers/'
import { LOGOUT } from 'common/constants/auth'
export const API = process.env.REACT_APP_BACKEND_API;
export const APIFLOWER = process.env.REACT_APP_FLOWER_API;
const APIACTIVATE = API + "api/auth/activate";
const APILOGOUT = API + "api/auth/logout";
const APIMEDIA = API + "media/";
const APIUSERDATA = API + "api/auth/userdata"
export const APIREFRESH = API + "api/auth/token/refresh";
const APIREQUESTPASSWORD = API + "api/auth/requestreset";
const APIRESESTPASSWORD = API + "api/auth/reset/";
const APIRENEWPASSWORD = API + "api/auth/renewpass";
const APICREATE = API + "api/compute/create";
const APICREATEFILE = API + "api/compute/create_file";
const APIDELETE = API + "api/compute/delete";
const APICOMPUTE = API + "api/compute/view";
const APIDOWNLOAD = API + "api/compute/download";
const APIDOWNLOADSTATICFILE = API + "api/compute/download/assets/";
const APIQUESTIONNAIRE = API + "api/compute/questionnaire";
const APIWITHDRAWAL = API + "api/compute/newcompany";

export async function fetchApi(queries, api) {
  var url = api;

  // WHY USING BLOB? WELL, OTHERWISE WE CANT PUSH MULTIPLE FILES OR WE HAVE
  // TO WRITE EXCEPTIONS ON THE BACKEND SIDE ... MAYBE THERE IS A BETTER SOLUTION
  const formData = new FormData();
  formData.append(
    "queries",
    new Blob([JSON.stringify(queries)], { type: "application/json" })
  );
  const token = localStorage.getItem("token_access");

  return fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    if (response.status >= 400) {
      return {
        resultsets: [{ result: [], name: "" }],
        status: response.status,
      };
    }
    return { resultsets: response.json(), status: response.status };
  });
}

export async function fetchApiDownloadFile(queries, api) {
  var url = api;

  // WHY USING BLOB? WELL, OTHERWISE WE CANT PUSH MULTIPLE FILES OR WE HAVE
  // TO WRITE EXCEPTIONS ON THE BACKEND SIDE ... MAYBE THERE IS A BETTER SOLUTION
  const formData = new FormData();
  formData.append(
    'queries',
    new Blob([JSON.stringify(queries)], { type: 'application/json' })
  );
  const token = localStorage.getItem('token_access');
  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    if (response.status >= 400) {
      return {
        resultsets: [{ result: [], name: '' }],
        status: response.status,
      };
    }

    response.blob().then((blob) => {
      let aux_file = response.headers.get('content-disposition');
      let file = aux_file.split('/')[aux_file.split('/').length - 1];

      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', file);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();

      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      return {
        resultsets: [{ result: [], name: '' }],
        status: 200,
      };
    });
  });
}

export async function fetchApiDownloadStaticFile(fileName, api) {
  const url = api + fileName;

  const result = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${localStorage.getItem('token_access')}`
    },
  });
  const blob = await result.blob();
  if(blob){
    return {
      resultset: { result: blob, name: '' },
      status: 200,
    };
  }
}

export async function fetchApiRefreshToken(api) {
  var url = api;
  const token = localStorage.getItem("token_refresh");

  let { result, status } = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ refresh: token }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status >= 400) {
      return { status: response.status };
    }
    return { result: response.json(), status: response.status };
  });
  let res = await result;
  if (status >= 400) {
    // Foreced logout if refresh token is invalid
    const store = createStore(rootReducer)
    store.dispatch({
      type: LOGOUT,
    });
    window.location.href = "/login";
    // const history = useHistory();
    // let path = `newPath`; 
    // history.push(path);

    return null
  }

  localStorage.setItem("token_access", res.access);
}




export async function fetchApiLogout() {
  var url = APILOGOUT;
  const token = localStorage.getItem("token_access");
  const token_refresh = localStorage.getItem('token_refresh');
  let { status } = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ refresh: token_refresh }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    if (response.status >= 400) {
      return { status: response.status };
    }
    return { status: response.status };
  });
  // let res = await result;  
  if (status >= 400) {
    await fetchApiRefreshToken(APIREFRESH);
    return fetchApiLogout();
  }
  return status
}

export async function fetchApiMediaText(filepath) {
  var url = APIMEDIA + filepath;
  return fetch(url, {
    method: "GET",
  }).then((response) => {
    if (response.status >= 400) {
      return { placeholder: "Something went wrong!" };
    }
    return response.text();
  });
}

export async function fetchApiUpload(queries, files) {
  var url = APICREATE;
  const formData = new FormData();
  formData.append(
    "queries",
    new Blob([JSON.stringify(queries)], { type: "application/json" })
  );
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  const token = localStorage.getItem("token_access");

  return fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    if (response.status >= 400) {
      return { resultsets: [{ result: [], name: "" }], status: response.status };
    }
    return { resultsets: response.json(), status: response.status };
  });
}

export async function fetchApiUploadFile(queries, files) {
  var url = APICREATEFILE;
  const formData = new FormData();
  formData.append(
    "queries",
    new Blob([JSON.stringify(queries)], { type: "application/json" })
  );
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  const token = localStorage.getItem("token_access");

  return fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    if (response.status >= 400) {
      return { resultsets: [{ result: [], name: "" }], status: response.status };
    }
    return { resultsets: response.json(), status: response.status };
  });
}

export async function fetchApiRequestPassword(
  formSubmit
) {
  const url = APIREQUESTPASSWORD;
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(formSubmit),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.status >= 400) {
      return { result: {}, status: response.status };
    }
    return { result: response.json(), status: response.status };
  })
}

export async function fetchApiUserData() {
  const token = localStorage.getItem("token_access");
  const url = APIUSERDATA;
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `JWT ${token}`,
    },
  }).then((response) => {
    if (response.status >= 400) {
      return { result: {}, status: response.status };
    }
    return { result: response.json(), status: response.status };
  })
}

export async function fetchApiResetPassword(
  userID,
  activationToken,
  profileDetails
) {
  var url = APIRESESTPASSWORD + userID + "/" + activationToken;
  const formData = new FormData();
  formData.append(
    "profile_details",
    new Blob([JSON.stringify(profileDetails)], { type: "application/json" })
  );
  return fetch(url, {
    method: "POST",
    body: formData,
  }).then((response) => {
    if (response.status >= 400) {
      return { result: {}, status: response.status };
    }
    return { result: response.json(), status: response.status };
  });
}

export async function fetchApiRenewPassword(
  profileDetails
) {
  var url = APIRENEWPASSWORD;
  const formData = new FormData();
  formData.append(
    "profile_details",
    new Blob([JSON.stringify(profileDetails)], { type: "application/json" })
  );
  return fetch(url, {
    method: "POST",
    body: formData,
  }).then((response) => {
    if (response.status >= 400) {
      return { result: {}, status: response.status };
    }
    return { result: response.json(), status: response.status };
  })
    .catch((err) => {
      return { result: err.json(), status: err.status };
    })
}

export async function fetchApiActivate(
  userID,
  activationToken,
  profileDetails
) {
  var url = APIACTIVATE + "/" + userID + "/" + activationToken;
  const formData = new FormData();
  formData.append(
    "profile_details",
    new Blob([JSON.stringify(profileDetails)], { type: "application/json" })
  );
  return fetch(url, {
    method: "POST",
    body: formData,
  }).then((response) => {
    if (response.status >= 400) {
      return { placeholder: "Something went wrong!" };
    }
    return response.json();
  });
}

export async function getData(queries) {
  let { resultsets, status } = await fetchApi(queries, APICOMPUTE);
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { resultsets } = await fetchApi(queries, APICOMPUTE);
    return resultsets;
  }
  return resultsets;
}

 

export async function createData(queries) {
  let { resultsets, status } = await fetchApi(queries, APICREATE);
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { resultsets } = await fetchApi(queries, APICREATE);
    return resultsets;
  }
  return resultsets;
}

export async function sendWithdrawal(queries) {
    let { resultsets, status } = await fetchApi(queries, APIWITHDRAWAL);

    return status;
}

export async function getQuestionnaire(queries) {
  let { resultsets, status } = await fetchApi(queries, APIQUESTIONNAIRE);
  
  return resultsets;
}


export async function downloadFiles(queries) {
  let { resultsets, status } = await fetchApiDownloadFile(queries, APIDOWNLOAD);
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { resultsets } = await fetchApiDownloadFile(queries, APIDOWNLOAD);
    return resultsets;
  }
  return resultsets;
}

export async function apiDownloadStaticFile(fileName) {
  let { resultset, status } = await fetchApiDownloadStaticFile(fileName, APIDOWNLOADSTATICFILE);
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { resultset } = await fetchApiDownloadStaticFile(fileName, APIDOWNLOADSTATICFILE);
    return resultset;
  }
  return resultset;
}

export async function createDataUpload(queries, files) {
  const { resultsets, status } = await fetchApiUpload(queries, files);
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { resultsets } = await fetchApiUpload(queries, files);
    return resultsets;
  }
  return resultsets;
}
export async function createDataUploadFile(queries, files) {
  const { resultsets, status } = await fetchApiUploadFile(queries, files);
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { resultsets } = await fetchApiUploadFile(queries, files);
    return resultsets;
  }
  return resultsets;
}



export async function getUserData() {
  const { result, status } = await fetchApiUserData();
  if (status === 401) {
    await fetchApiRefreshToken(APIREFRESH);
    let { result } = await fetchApiUserData();
    return result;
  }
  return result;
}

export async function removeData(queries) {
  return fetchApi(queries, APIDELETE).then((resultset) => {
    return resultset;
  });
}
