import axios from "axios";
import { stopSubmit } from "redux-form";
import { API, fetchApiLogout, fetchApiRenewPassword, fetchApiRefreshToken, APIREFRESH } from "functions/fetchApi";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  LOGOUT,
  IS_LOGGED_IN,
  LOGIN_SUCCESS_OLD_PASSWORD,
  RENEWPASS_SUCCESS
} from "common/constants/auth";

import { tokenConfig } from "./helper";
import { browserHistory } from 'react-router'

// LOGIN USER
export const login = (loginDetails) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(loginDetails);
  try {
    const res = await axios.post(API + "api/auth/login", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response.status === 307) {
      dispatch({
        type: LOGIN_SUCCESS_OLD_PASSWORD,
        payload: { user: { email: loginDetails.email }, isPasswordTooOld: true },
      });
    }
    else {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
    dispatch(stopSubmit("loginForm", err.response.data));
  }
};

// LOGOUT USER
export const logout = () => async (dispatch) => {
  fetchApiLogout();
  dispatch({
    type: LOGOUT,
  });
};
export const refreshtoken = () => async (dispatch) => {
  fetchApiRefreshToken(APIREFRESH);
};

 

export const loggedIn = () => (dispatch) => {
  dispatch({
    type: IS_LOGGED_IN,
    payload: true,
  });
};

// UPDATE USER
export const updateUser = (userProfileInfo) => async (dispatch) => {
  const config = tokenConfig();

  const formData = new FormData();
  formData.append(
    "profile_details",
    new Blob([JSON.stringify(userProfileInfo)], { type: "application/json" })
  );

  try {
    const res = await axios.post(API + "api/auth/update", formData, config);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_USER_FAIL,
    });
    dispatch(stopSubmit("updateUserForm", err.response.data));
  }
};

export const renewPassword = (userInfo) => async (dispatch) => {
  
  try {
    const res = await fetchApiRenewPassword(userInfo);
    dispatch({
      type: RENEWPASS_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_USER_FAIL,
    });
  }
};