import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core";

const styles = {
  /* Styles applied to the root element. */
  root: {},
  characterLimit: {
    flex: 1,
    textAlign: "right",
    margin: "5px 15px 0 0"
  }
};

export const FormControlTextInput = ({ value, onChange, label, id_key, multiline, is_optional, characterLimit, input, meta: { touched, error }, type, inputProps, ...custom }) => {
  let initialNChars = characterLimit ? fancyCount(value || input.value) : 0

  const [nChars, setNChars] = useState(initialNChars)
  return (
    <FormControl style={{ minWidth: 150, width: '100%' }} error={!value && !is_optional && touched && error}>
      <TextField
        error={!value && !is_optional && touched && error}
        label={label}
        onChange={onChange}
        inputProps={{
          ...(type === 'password' ? { autoComplete: 'off' } : {}),
          ...inputProps
        }}
        value={value}
        id_key={id_key}
        multiline={multiline}
        {...input}
        {...custom}
        onInput={(e) => {
          if (characterLimit) {
            setNChars(fancyCount(e.target.value))
          }
        }}
      />
      {!value && !is_optional && touched && error && (
        <FormHelperText id={"form_select_helper_" + id_key} error={!value && !is_optional && touched && error}>
          {error}
        </FormHelperText>
      )}
      {characterLimit && (
        <FormHelperText
          style={styles.characterLimit}
        >
          Límite máximo de caracteres {nChars}/{characterLimit}
        </FormHelperText>)}
    </FormControl>
  );
};

const fancyCount = str => {
  str = str ? str.toString(16) : "";
  const joiner = "\u{200D}";
  const split = str.split(joiner);
  let count = 0;

  for (const s of split) {
    //removing the variation selectors
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join("")).length;
    count += num;
  }

  //assuming the joiners are used appropriately
  return count / split.length;
};