/* eslint-disable react/no-set-state */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Table from 'components/CompanyTable';
import CompanyDocsRevisionDialog from './dialog/CompanyDocsRevisionDialog';
import { hidehelp } from 'common/actions/components';
import { filterData } from 'functions/filterData';
import {
  loadcompanyarray, 
  loaddocumentoverviewdata,
  loaddocumentaggregateddata
} from "common/actions/data";
import { sliceDocuments } from 'common/actions/data';

class TableCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCompanyDocsDialog: false,
      columns: new Map([
        ['id', { name: 'Id', filter: 'default' }],
        ['superentityname', { name: 'Entidad', filter: 'default' }],
        ['name', { name: 'Nombre', filter: 'default' }],
        ['email', { name: 'Email', filter: 'default' }],
        ['verificated', 
        {
          name: 'Validados',
          filter: 'default',
          customRender: 'Tagg',
          tagStyle: {
            verificated: { text: 'Documentos validados', color: 'rgb(103, 237, 14)' },
            noVerificated: { text: 'Pendientes de validar', color: 'rgb(236, 240, 235)' } 
          },
        }],
        [
          'is_incidence_opened',
          {
            name: 'Revisar documentación',
            filter: 'default',
            customRender: 'Button',
            action: true,
            onChangeCallback: this.handleDocumentRevisionFeedbackCallback,
            textCallback: () => 'Revisar documentación',
          },
        ],
      ]),
      loaded: false,
      placeholder: 'Cargando datos',
      companyId: 0
    };
  }
  evaluateVerificated(row) {
    let verificated = 'verificated';
    if (!row.is_verified) {
      verificated = 'noVerificated';
    }
    else  {
      verificated = 'verificated';
    } 
    return verificated;
  }
  mapFetchResults = (results) => {
    console.log("entra");
  
    const data = [];
    for (const result of results) {
      if (result.is_closed) {
        let row = {
          id: result.id,
          superentityname: result.entity__superentity__name,
          name: result.value,
          is_valid_doc: true,
          is_incidence_opened: false,   
          comments: result.comments,
          is_verified:result.is_verified
        };
        
        row.verificated= this.evaluateVerificated(row);
        data.push(row);
      }
    }
    return data;
  };

  
  handleCancelCompanyDocsDialog = () => {
    this.props.loadcompanyarray();
   
    this.setState(() => {
      return {
        openCompanyDocsDialog: false,
      };
    });
  };

  handleDocumentRevisionFeedbackCallback = (row, value) => {
    var filters={};
    filters.delete_flag= false;
    filters.company=row.id;
    filters.document_species__value__in= ['Cuestionario', 'Resumen litros', 'Contrato', 'Documento adicional', 'Pago del Euro'];

     this.props.loadcompanyarray(row.id);  
     
     this.props.loaddocumentaggregateddata(filters);
     this.props.loaddocumentoverviewdata(filters);
    this.props.sliceDocuments({ companyIds: [row.id] });
    this.setState(() => {
      return {
        openCompanyDocsDialog: true,
        company: this.props.companyArray.find((company) => company.id === row.id)
      };
    });

    let resultMsg = { ok: true };

    return resultMsg;
  };

  handleOpenAddCompanyDialog = () => {
    this.setState(() => {
      return {
        openAddCompanyDialog: true,
      };
    });
  };

  render() {
    return (
      <Fragment>
        <div>
          <Table
            data={this.mapFetchResults(this.props.companyArray)}
            onClick={this.handleOpenAddCompanyDialog}
            columns={this.state.columns}
            title="Clientes"
            standardActions={[]}
            deleteAction={false}
            onDeleteRow={this.handleDeleteRow}
            titleConfirmDeleteRow={'Eliminar cliente'}
            textConfirmDeleteRow={
              '¿Está seguro de que desea eliminar el cliente?'
            }
            skipOtherColumns={true}
          />
        </div>

        <CompanyDocsRevisionDialog
          onCloseDialog={this.handleCancelCompanyDocsDialog}
          onCancel={this.handleCancelCompanyDocsDialog}
          open={this.state.openCompanyDocsDialog}
          data={filterData(
            this.props.documentOverviewArray,
            this.props.documentsSlicer
          )}
          initialValues={this.state.company}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyArray: state.data.companyArray,
    documentOverviewArray: state.data.documentOverviewArray,
    documentsSlicer: state.slicer.documentsSlicer,
  };
};

TableCompany = connect(mapStateToProps, {
  hidehelp,
  sliceDocuments,
  loadcompanyarray,
  loaddocumentoverviewdata,
  loaddocumentaggregateddata
  // softDelete
})(TableCompany);

export default TableCompany;
