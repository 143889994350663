import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@material-ui/core";

export function renderTextField({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  );
}

export function renderSelectField({
  label,
  data,
  input,
  key,
  value,
  defaultValue,
  handleChange,
  dataField = "value",
  disabled=false,
  meta: { touched, invalid, error },
  ...custom
}) {  
  return (
    <FormControl error={touched && invalid}>
      <InputLabel id={`form_label_${key}`}>{label}</InputLabel>
      <Select
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleChange}
      >
        {data.map((obj, index) => {
          return (
            <MenuItem
              id={"form_select_item_" + key + "_" + index}
              value={obj.id}
            >
              {obj[dataField]}
            </MenuItem>
          );
        })}
      </Select>
      {error && (
        <FormHelperText id={`form_helper_text_${key}`}>
          Seleccione una opción
        </FormHelperText>
      )}
    </FormControl>
  );
}
