import {    
    CHANGE_ADDITIONAL_DOCUMENT_DESCRIPTION
  } from 'common/constants/components';
   
  
  export const changeAdditionalDocumentDescription = (text) => async (dispatch) => {        
    dispatch({
      type: CHANGE_ADDITIONAL_DOCUMENT_DESCRIPTION,
      payload: { text },
    });
  };
  