export const createTextUploadQueries = (props) => {
  let {
    companyId,
    entityId,    
    documentFormatId,
    documentSpeciesId,
  } = props;
  return [
    {
      name: "DocumentUploadQueries",
      type: "multi_file_upload",
      table: "entityfile",
      file_field_name: "file",
      user_field_name: "upload_by",
      parents: [
        {
          table: "entity",
          fieldname: "entity",
          identifiers: {
            id: entityId,
          },
        },
        {
          table: "entitycompany",
          fieldname: "company",
          identifiers: {
            id: companyId,
          },
        },
        {
          table: "entityfilestatus",
          fieldname: "status",
          identifiers: {
            value: "Subido a la plataforma",
          },
        },
      ],
      identifiers: {
        document_species_id: documentSpeciesId,
        document_format_id: documentFormatId
      },
      defaults: {},
    },
  ];
};

export const createFileDetailsQueries = (entityId, fileId, modelName, details) => {    
  return [
    {
      name: "DocumentDetailsUploadQueries",
      type: "update",
      table: modelName,
      parents: [
        {
          table: "entityfile",
          fieldname: "file",
          identifiers: {
            id: fileId,
          },
        },
        {
          table: "entity",
          fieldname: "entity",
          identifiers: {
            id: entityId,
          },
        },
      ],
      identifiers: {},
      defaults: details,
    },
  ];
};



export const createFileDetailsQueries2 = (entityId, modelName, details) => {    
  return [
    {
      name: "DocumentDetailsUploadQueries",
      type: "update",
      table: modelName,
      parents: [
        {
          table: "entityfile",
          fieldname: "file",
          identifiers: { },
        },
        {
          table: "entity",
          fieldname: "entity",
          identifiers: {
            id: entityId,
          },
        },
      ],
      identifiers: {},
      defaults: details,
    },
  ];
};



export const createTextAndUploadFile = ( props, 
  query2) => {
    let {
      companyId,
      entityId,    
      documentFormatId,
      documentSpeciesId,
    } = props;
  return [
  { arg1: {
      name: "DocumentUploadQueries",
      type: "multi_file_upload",
      table: "entityfile",
      file_field_name: "file",
      user_field_name: "upload_by",
      parents: [
        {
          table: "entity",
          fieldname: "entity",
          identifiers: {
            id: entityId,
          },
        },
        {
          table: "entitycompany",
          fieldname: "company",
          identifiers: {
            id: companyId,
          },
        },
        {
          table: "entityfilestatus",
          fieldname: "status",
          identifiers: {
            value: "Subido a la plataforma",
          },
        },
      ],
      identifiers: {
        document_species_id: documentSpeciesId,
        document_format_id: documentFormatId
      },
      defaults: {},
    },
  arg2: query2
  }
  ];
};
