import React, { Component } from 'react'
import Chart from "chart.js";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }
  componentDidUpdate() {
    
    this.chart.data.labels = this.props.data.map((d) => d.label);
    this.chart.data.datasets[0].data = this.props.data.map((d) => d.value);

    for (let i = 0; i < this.chart.data.datasets[0].data.length; i++) {
      this.chart.data.datasets[0].backgroundColor[i] = this.props.colors[i]
    }

    this.chart.update();
  }
  componentDidMount() {
    this.chart = new Chart(this.chartRef.current, {
      type: 'pie',
      data: {
        labels: this.props.data.map(d => d.label),
        datasets: [{
          data: this.props.data.map(d => d.value),
          backgroundColor: this.props.colors
        }]
      }
    });
  }

  render() {
    return <canvas ref={this.chartRef} />;
  }
}

export default PieChart;