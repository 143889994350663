import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ArrowTooltip from "components/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Picture1_1 from "../../assets/images/faq/q1/Picture1-1.png";
import Picture1_2 from "../../assets/images/faq/q1/Picture1-2.png";

import Picture3_1 from "../../assets/images/faq/q3/Picture3-1.png";
import Picture3_2 from "../../assets/images/faq/q3/Picture3-2.png";
import Picture3_3 from "../../assets/images/faq/q3/Picture3-3.png";
import Picture3_4 from "../../assets/images/faq/q3/Picture3-4.png";
import Picture3_5 from "../../assets/images/faq/q3/Picture3-5.png";
import Picture3_6 from "../../assets/images/faq/q3/Picture3-6.png";

import Picture7_1 from "../../assets/images/faq/q7/Picture7-1.png";

import Picture8_1 from "../../assets/images/faq/q8/Picture8-1.png";
import Picture8_2 from "../../assets/images/faq/q8/Picture8-2.png";

import Picture9_2 from "../../assets/images/faq/q9/Picture9-2.png";

import Picture10_1 from "../../assets/images/faq/q10/Picture10-1.png";

import Picture12_1 from "../../assets/images/faq/q12/Picture12-1.png";

import Picture14_1 from "../../assets/images/faq/q14/Picture14-1.png";
import Picture14_2 from "../../assets/images/faq/q14/Picture14-2.png";
import Picture14_3 from "../../assets/images/faq/q14/Picture14-3.png";
import Picture14_4 from "../../assets/images/faq/q14/Picture14-4.png";

import Picture15_1 from "../../assets/images/faq/q15/Picture15-1.png";
import Picture15_2 from "../../assets/images/faq/q15/Picture15-2.png";
import Picture15_3 from "../../assets/images/faq/q15/Picture15-3.png";

import Picture16_1 from "../../assets/images/faq/q16/Picture16-1.png";

import Picture18_1 from "../../assets/images/faq/q18/Picture18-1.png";
import Picture18_2 from "../../assets/images/faq/q18/Picture18-2.png";


const useStyles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class FAQPage extends Component {
  componentDidMount() {
    // this.props.loadUserData();
  }

  renderExpandIcon = () => {
    return (
      <ArrowTooltip
        title="Mostrar respuesta"
        placement="top"
        open={this.props.ishelpdisplayed}
      >
        <ExpandMoreIcon />
      </ArrowTooltip>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Paper
          style={{ width: "90%", marginTop: 30, marginBottom: 30, padding: 15 }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                1 - ¿Cómo puedo activar mi cuenta?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Para activar la cuenta debemos seguir estos pasos:
                </p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <p>1 - Cuando la plataforma da de alta a un usuario envía un correo
                    electrónico a este usuario con un link en su contenido, que es
                    necesario para la activación de la cuenta. Este link contiene un
                    código temporal de activación con un tiempo de caducidad de 3
                    dias.
                    Con un "Click" en el link de activación, el usuario llega
                a la pagina de la activación de cuenta de la plataforma.</p>
                    <img alt=""
                      src={Picture1_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>2 - La
                    activación de la cuenta de usuario requiere una serie de datos,
                    como nombre, apellidos, NIF/NIE y la elección de una contraseña
                    para completar el perfil de usuario. La contraseña debe tener
                    como mínimo 8 caracteres y contener tanto números como
                    minúsculas y mayúsculas. El usuario también debe aceptar lo
                    Términos y Condiciones para la activación de su cuenta. Con un
                    "Click" en el botón de "ACTIVAR CUENTA" se activará la cuenta y
                    el usuario puede entrar en la plataforma a través de la pagina
                    de "Login" utilizando su usuario (email) y la contraseña
                  elegida.</p>
                    <img alt=""
                      src={Picture1_2}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                2 - ¿Cómo puedo entrar en la plataforma?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Para entrar en la plataforma el usuario tiene que introducir su
                usuario (E-Mail) y su contraseña. Con un "Click" en el botón de
                "INICIAR SESSIÓN" se inicia el "Login" y el usuario puede entrar
                en la plataforma. En caso de que el usuario no recuerde su
                contraseña, deberá ponerse en contacto con el administrador de
                la plataforma.
                {/* existe la posibilidad de hacer un ‘Reset’ y la
                plataforma enviará una nueva contraseña temporal con la cual el
                usuario puede (parecido al proceso de la activación de la
                cuenta) elegir una nueva contraseña. */}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                3 - ¿Cómo puedo subir facturas?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Para subir facturas debemos seguir estos pasos:
                </p>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <p>1 - Acceder a la vista "Documentos".</p>
                    <img alt=""
                      src={Picture3_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      2 - Hacer click en el icono "+", en la esquina superior
                      derecha de la tabla.
                    </p>
                    <img alt=""
                      src={Picture3_2}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      3 - Seleccionar una opción en todos los campos que aparecen en rojo.
                      En el campo "Tipo de fichero" debe elegir la opción "Factura".
                    </p>
                    <img alt=""
                      src={Picture3_3}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      4 - Al seleccionar todas las opciones marcadas en rojo se habilitará el botón
                      "Siguiente" que nos va a llever a la vista para subir documentos.
                    </p>
                    <img alt=""
                      src={Picture3_4}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      5 - Para subir documentos puede arrastrarlos a la zona indicada o hacer click sobre la misma.
                      *Si arrastra los documentos a la zona se subirán automáticamente, si hace click se mostrará el paso 6.
                    </p>
                    <img alt=""
                      src={Picture3_5}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      6 - En la nueva ventana debe seleccionar los ficheros que desea subir y hacer click en el botón indicado.
                    </p>
                    <img alt=""
                      src={Picture3_6}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                4 - ¿A qué se refieren los distintos tipos de documento?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Con el fin de permitir un punto centralizado y común de
                  recopilación de información, es posible subir a la plataforma
                  distintos tipos de documentos:
                </p>
                <div style={{ marginLeft: 20 }}>
                  <ul>
                    <li>
                      Facturas de carburantes de empresas adheridas a la
                      reclamación (todas las facturas han de ser del territorio
                      español)
                  </li>
                    <li>
                      Documento excel con un resumen de litros aportados en las
                      facturas subidas por transportista
                  </li>
                    <li>
                      Contrato del cliente (transportista) con el fondo de
                      inversión
                  </li>
                    <li>
                      Encuesta realizada por cada uno de los transportistas
                  </li>
                    <li>
                      Justificante del pago del Euro
                  </li>
                  </ul>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                5 - ¿Cómo puedo subir las encuestas que realizan los clientes?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Si, para subir las encuestas de los clientes debe seguir los pasos descritos en el apartado 3, seleccionando
                  en el paso 3 en el campo "Tipo de fichero" la opción "Cuestionario"
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                6 - ¿Qué es el documento de resumen de litros? ¿Es obligatorio?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Con el objetivo de permitir un mejor control y gestión de los
                  litros y facturas aportados por cada una de las empresas de
                  transporte, se permite en la plataforma la subida de un
                  documento en formato excel en el que se detalle el resumen de
                  litros enviados.
                </p>
                <p>Este documento, tiene la siguiente estructura:&nbsp;</p>
                <p>Resumen de litros para el transportista x.&nbsp;&nbsp;</p>
                <p>
                  <strong>AñO&nbsp;</strong> REPSOL CEPSA BP OTROS&nbsp;
                </p>
                <p>
                  <strong>2003</strong> xxxx&nbsp;litros xxxx&nbsp;litros
                  xxxx&nbsp;litros xxxx&nbsp;litros&nbsp;
                </p>
                <p>
                  <strong>2004 </strong> xxxx&nbsp;litros xxxx&nbsp;litros
                  xxxx&nbsp;litros xxxx&nbsp;litros&nbsp;
                </p>
                <p>
                  <strong>2005</strong> xxxx&nbsp;litros xxxx&nbsp;litros
                  xxxx&nbsp;litros xxxx&nbsp;litros&nbsp;
                </p>
                <p>
                  <strong>2006&nbsp;</strong> xxxx&nbsp;litros xxxx&nbsp;litros
                  xxxx&nbsp;litros xxxx&nbsp;litros&nbsp;
                </p>
                <p>&hellip;&nbsp;</p>
                <p>
                  <strong>2020</strong> xxxx&nbsp;litros xxxx&nbsp;litros
                  xxxx&nbsp;litros xxxx&nbsp;litros&nbsp;
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                7 - ¿Cómo puedo editar información de mi cuenta?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Puede ver los detalles de su cuenta en "Información de
                  interés". Para acceder a esta página, puede hacer clic en su
                  correo electrónico en la barra superior, o bien acceder al
                  enlace "Información de interés" dentro del menú de
                  configuración.
                </p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <img alt=""
                      src={Picture7_1}
                      style={{ width: "100%", height: 120 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                8 - ¿Qué hago si he olvidado mi contraseña?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  A - En caso de que haya olvidado su contraseña, puede
                  solicitar una nueva en la página de acceso haciendo clic en el
                  botón ¿Olvidó su contraseña?.
                </p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={2}>
                    <img alt=""
                      src={Picture8_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                </Grid>
                <p>
                  B - Si desea reestablecer la contraseña dentro de la
                  aplicación, puede hacerlo abriendo el menú de configuración en
                  la barra superior y haciendo clic en reestablecer la
                  contraseña.
                </p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <img alt=""
                      src={Picture8_2}
                      style={{ width: "100%", height: 120 }}
                    />
                  </Grid>
                </Grid>
                <p>
                  IMPORTANTE: Después de confirmar el restablecimiento de la
                  contraseña, recibirá un correo electrónico con instrucciones
                  sobre cómo reestablecer su contraseña. Completar estas
                  instrucciones es obligatorio para recibir de nuevo el acceso a
                  la plataforma.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                9 - ¿Dónde puedo consultar información sobre los documentos que
                ya he subido?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <p>Desde la página de inicio, puede ir a la página de resumen de
                  documentos, haciendo clic en "Documentos".</p>
                    <img alt=""
                      src={Picture3_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      *Para recibir el estado más reciente de la Carga de
                      Documentos, puede recargar los datos, haciendo clic en el
                      botón de recarga en la Barra Superior.
                    </p>
                    <img alt=""
                      src={Picture9_2}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                10 - ¿Cómo puedo cerrar sesión?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Para realizar un cierre de sesión limpio, por favor haga clic
                  en el botón de cierre de sesión. Después de que se realice el
                  cierre de sesión, todos los datos almacenados en la memoria
                  caché se borrarán automáticamente de su ordenador.
                </p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12}>
                    <img alt=""
                      src={Picture10_1}
                      style={{ width: "100%", height: 100 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                11 - Después de entrar en la plataforma he estado inactivo
                durante un tiempo. ¿Porqué tengo que hacer el login de nuevo?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Si no hay actividad en tu cuenta de OUTLIERS durante un
                  periodo de tiempo prolongado (15 minutos), deberás volver a
                  iniciar sesión con su email y contraseña.
                </p>
                <p>
                  Por política de seguridad, al ser una plataforma con
                  información privada debemos prevenir acceso no autorizado. Por
                  ejemplo, si fuese a dejar el ordenador encendido con la
                  página activa en un espacio público nadie podrá iniciar sesión
                  sin su información.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                12 - ¿Cómo puedo saber con que usuario me he conectado?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Podemos identificar el usuario conectado mediante el email
                  utilizado para acceder a la plataforma, este se mostrará en
                  todo momento en la parte superior derecha de la pantalla.
                </p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12}>
                    <img alt=""
                      src={Picture12_1}
                      style={{ width: "100%", height: 100 }}
                    />
                  </Grid>
                </Grid>
                <p>
                  *Puede consultar información mas detallada del usuario en
                  “Información de interés”
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                13 - ¿Cuántos documentos puedo subir al mismo tiempo?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                La plataforma permite subir 30 documentos a la vez.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                14 - ¿Cómo puedo dar de alta un cliente?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  Para dar de alta un nuevo cliente debe seguir estos pasos:
                </p>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <p>1 - Acceder a la vista “Gestión clientes”</p>
                    <img alt=""
                      src={Picture14_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      2 - Hacer click en el icono “+”, en la esquina superior
                      derecha de la tabla.
                    </p>
                    <img alt=""
                      src={Picture14_2}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      3 - Al terminar de completar los datos del nuevo cliente
                      hacemos click en “Añadir Cliente”
                    </p>
                    *NOTA: Debe rellenar oblitatoriamente todos los campos en
                    rojo
                    <img alt=""
                      src={Picture14_4}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      *Podemos dar de alta un nuevo cliente haciendo click en el icono “+” antes de subir documentos
                    </p>
                    <img alt=""
                      src={Picture14_3}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                15 - ¿Puedo borrar o actualizar etiquetas si me he equivocado al
                subir un documento?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>Para borrar un documento es necesario seguir estos pasos:</p>
                <p>
                  {" "}
                  *Para actualizar un documento se tiene que borrar dicho
                  documento y volver a subir con los datos corregidos.
                </p>

                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <p>
                      {"1 - Acceder a la vista “Documentos” (Página principal -> Documentos)"}
                    </p>
                    <img alt=""
                      src={Picture15_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      2 - Para eliminar un documento hacemos click en el botón
                      en forma de papelera y una X en medio.
                    </p>
                    <img alt=""
                      src={Picture15_2}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p>
                      3 - Si confirmamos en la nueva ventana se eliminará el
                      documento
                    </p>
                    <img alt=""
                      src={Picture15_3}
                      style={{ width: "100%", height: 200 }}
                    />
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                16 - ¿Cómo puedo volver a la pagina principal?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>
                  En la esquina superior izquierda se muestra una imagen en
                  forma de casa que al hacer click en ella nos llevará
                  directamente a la página principal{" "}
                </p>
                <img alt="" src={Picture16_1} style={{ width: "100%", height: 50 }} />
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                17 - ¿Cuál es el límite de tamaño de por fichero subido?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>El tamaño máximo del fichero es 100 MB</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                18 - ¿Cuál es el proceso para cerrar un cliente?¿Por qué es importante?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>NOTA: Antes de poder cerrar un cliente es necesario subir los siguientes documentos:</p>
                <div style={{ marginLeft: 20 }}>
                  <ul>
                    <li>Contrato</li>
                    <li>Todas las Facturas de ese cliente</li>
                    <li>Resumen de litros</li>
                    <li>Cuestionario</li>
                    <li>Documentación de identidad</li>
                  </ul>
                </div>
                <p style={{marginTop:10}}>Para cerrar un cliente en necesario seguir estos pasos:</p>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={1}
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={6}>
                    <p>
                      1 - Accedemos a la vista “Gestión clientes”
                    </p>
                    <img alt=""
                      src={Picture18_1}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>
                      2 - Para poder cerrar un cliente hacemos click en el botón "Cerrer cliente".
                    </p>

                    <img alt=""
                      src={Picture18_2}
                      style={{ width: "100%", height: 400 }}
                    />
                  </Grid>
                </Grid>
                <p>Al cerrar un cliente en la columna "Estado revisión de los documetos" aparecerá el estado "Revisión en curso". Si la revisión
                  de los documentos es positiva entonces se mostrará el estado "Documentos validados", en caso contrario aparecerá el estado 
                  "Documentos con incidencias" y se pondrán en contacto vía email con el usuario que ha creado ese cliente para resolver la incidencia.
                  Cuando el estado de la revisión de los documentos es "Documentos validados" se procesarán los datos de los documentos de dicho cliente
                </p>

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 30 }}>
            <AccordionSummary
              expandIcon={
                <ArrowTooltip
                  title="Mostrar respuesta"
                  placement="top"
                  open={this.props.ishelpdisplayed}
                >
                  <ExpandMoreIcon />
                </ArrowTooltip>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                19 - ¿Cuál es la resolución máxima permitida de los documentos subidos a la plataforma?{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <p>No existe una restricción de la resolución máxima de los documentos, cuanto mayor sea la resolución mejor</p>
                <p>NOTA: La única limitación de los documentos subidos es que su tamaño sea menor de 100 MB</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Paper >
      </Grid >
    );
  }
}

const mapStateToProps = (state) => ({
  ishelpdisplayed: state.valuesHelp.showhelp,
});

FAQPage = connect(mapStateToProps)(FAQPage);
export default withStyles(useStyles)(FAQPage);
