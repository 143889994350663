import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import validateUserForm from "./validator";
import { fetchApiResetPassword } from "functions/fetchApi";
import {
  Grid,
  TextField,
  Typography,
  Link,
  Button,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import { FormControlTextInput } from 'components';
import { notifySuccess } from "common/actions/notifications";
import { renewPassword } from "common/actions/auth";

class ResetPasswordaAge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RedirectUser: false,
    };
  }

  async handleSubmit(formValues) {
    if (this.props.auth.isPasswordTooOld) {
      let userInfo = {
        email: this.props.auth.user.email,
        password: formValues.password,
        securitypassword: formValues.securitypassword
      }
      await this.props.renewPassword(userInfo);
      this.setState({ RedirectUser: true });
    } else {
      await fetchApiResetPassword(
        this.props.match.params.uid,
        this.props.match.params.key,
        formValues
      );
    }

    //this.setState({ RedirectUser: true });
  }
  componentDidUpdate(prev_global_state, prev_local_state) {
    if (this.state.RedirectUser !== prev_local_state.RedirectUser) {
      if (this.props.redirectTo === 'login') {
        this.props.notifySuccess("Se ha actualizado la contraseña correctamente");
      }
    }
  }

  hiddenField = ({ type, meta: { error } }) => {
    return (
      <div className="field">
        <input type={type} />
        {error && <div className="ui red message">{error}</div>}
      </div>
    );
  };

  renderHelpText = () => {
    if (this.props.auth.isPasswordTooOld)
      return (
        <Typography
          variant="body2"
          gutterBottomhy
          display="block"
          align="center"
        >
          Con el fin de mejorar su seguridad es necesario cambiar la contraseña
          de forma periódica. Si tiene algún problema con este trámite
          por favor póngase en contacto con
          <Link href={"mailto:monolito.test@gmail.com"}>
            {" administrador "}
          </Link>
  para recibir ayuda.
        </Typography>
      )
    else
      return (
        <Typography
          variant="body2"
          gutterBottomhy
          display="block"
          align="center"
        >
          Puedes encontrar tu contraseña de seguridad en el correo
          electrónico que te enviamos para el restablecimiento de la
          contraseña. Por favor, póngase en contacto con el
          <Link href={"mailto:monolito.test@gmail.com"}>
            {" administrador "}
          </Link>
  para ayuda.
        </Typography>
      )

  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ padding: 96 }}
      >
        {this.state.RedirectUser && <Redirect to={`/${this.props.redirectTo}`} />}
        <Grid item>
          <Paper style={{ width: 300, minHeight: "48vh", padding: 16 }}>
            <form onSubmit={this.props.handleSubmit(this.handleSubmit.bind(this))} className="ui form">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid xs={12}>
                  <Typography
                    align="center"
                    variant="h3"
                    style={{ marginTop: 24 }}
                  >
                    Elige una nueva contraseña
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ marginTop: 24 }}
                  >
                    {this.props.auth.user.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="securitypassword"
                    type="password"
                    component={FormControlTextInput}
                    label="Contraseña actual"
                    style={{ width: 250, marginTop: 12 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="password"
                    type="password"
                    component={FormControlTextInput}
                    label="Contraseña nueva"
                    style={{ width: 250, marginTop: 12 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="corfirmpassword"
                    type="password"
                    component={FormControlTextInput}
                    label="Repetir Contraseña nueva"
                    style={{ width: 250, marginTop: 12 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{ marginTop: 20 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    enviar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <this.renderHelpText />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  redirectTo: state.auth.redirectTo
});
ResetPasswordaAge = connect(mapStateToProps, {
  renewPassword,
  notifySuccess,
})(ResetPasswordaAge);

export default reduxForm({
  form: "resetPasswordForm",
  validate: validateUserForm,
})(ResetPasswordaAge);
