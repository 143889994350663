import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowTooltip from "components/Tooltip";
import { connect } from "react-redux";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  }
}));

let SimpleTableToolbar = props => {
  const classes = useToolbarStyles();
  const { title, onClick, actionIcon, ishelpdisplayed,actionIconTooltip  } = props;
  const ActionIcon = actionIcon;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        component="div"
        id="tableTitle"
        variant="h6">
        {title}
      </Typography>
      {ActionIcon &&
        <ArrowTooltip title={actionIconTooltip} placement="bottom" open={ishelpdisplayed}>
          <IconButton aria-label="create project" onClick={onClick} data-testid={"add-button"}>
            <ActionIcon />
          </IconButton>
        </ArrowTooltip>
      }
    </Toolbar>
  );
};

SimpleTableToolbar.propTypes = {  
  onClick: PropTypes.func,
  title: PropTypes.any,
  actionIconTooltip: PropTypes.string
};

const mapStateToProps = (state) => ({
  ishelpdisplayed: state.valuesHelp.showhelp
});

SimpleTableToolbar = connect(mapStateToProps)(SimpleTableToolbar);

export default SimpleTableToolbar;