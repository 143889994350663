import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';

import {
  Grid,
  TextField,
  Typography,
// Link,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { login, loggedIn } from "common/actions/auth";
import {
  loadcompanyarray,
  loadcompanytypearray,
  loaddocumentaggregateddata,
  loaddocumentformatarray,
  loaddocumentoverviewdata,
  loaddocumentspeciesarray,
  loadentityarray,
  loadidtypearray,
  loadinvoicedetailsdata,
  loadinvoicetypearray,
  loadliterdetailsdata,
  loadsupplierarray,
  loadUserData,  
} from "common/actions/data";

import { FormControlTextInput } from 'components';

import validateLoginForm from "./validator";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RedirectUser: false,
      openLoadingScreen: false,
      error: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleData() {
    var filters={};
    filters.delete_flag= false; 
    await this.props.loadUserData();
    await this.props.loadcompanytypearray();
    await this.props.loadidtypearray();
    await this.props.loadentityarray();
    await this.props.loadcompanyarray();
    await this.props.loadinvoicetypearray();
    await this.props.loadsupplierarray();
    await this.props.loaddocumentformatarray();
    await this.props.loaddocumentspeciesarray();

    await this.props.loadinvoicedetailsdata();
    await this.props.loadliterdetailsdata();   
   
     
  }

  async handleSubmit(formValues) {
    formValues.preventDefault();
    var formSubmit = {
      email: formValues.target.email.value,
      password: formValues.target.password.value,
    };
    await this.props.login(formSubmit);
    if (this.props.isAuthenticated) {
      this.setState({ openLoadingScreen: true });
      await this.handleData();
      this.setState({ RedirectUser: true });
      this.setState({ openLoadingScreen: false });
      this.props.loggedIn();
    }
    else if (this.props.isPasswordTooOld) {
      this.setState({ RedirectUser: true });
    }

    else {
      this.setState({ error: true });
    }
  }

  hiddenField = ({ type, meta: { error } }) => {
    return (
      <div className="field">
        <input type={type} />
        {error && <div className="ui red message">{error}</div>}
      </div>
    );
  };

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ padding: 96 }}
      >
        {this.state.RedirectUser && <Redirect to={`/${this.props.redirectTo}`} />}
        <Grid item>
          {!this.state.openLoadingScreen && (
            <Paper style={{ width: 300, minHeight: "48vh", padding: 16 }}>
              <form
                onSubmit={this.handleSubmit}
                className="ui form"
                data-testid="login-form"
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid xs={12}>
                    <Typography
                      align="center"
                      variant="h3"
                      style={{ marginTop: 24 }}
                    >
                      Login
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      type="text"
                      component={FormControlTextInput}
                      label="E-Mail"
                      style={{ width: 200 }}
                      data_testid="email_form-input"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      type="password"
                      component={FormControlTextInput}
                      label="Password"
                      style={{ width: 200 }}
                      data_testid="password_form-input"
                      autocomplete="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="non_field_errors"
                      type="hidden"
                      component={this.hiddenField}
                      style={{ width: 200 }}
                    />
                  </Grid>
                  {this.state.error && 
                    <Grid item xs={12}>
                      <Alert variant="outlined" severity="error">
                        Contraseña incorrecta
                      </Alert>
                    </Grid>
                  }

                  <Grid item xs={12}>
                    <Button
                      disabled={
                        this.props.invalid ||
                        this.props.submitting ||
                        this.props.pristine
                      }
                      style={{ marginTop: 20 }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      data-testid="submit_form-button"
                    >
                      iniciar sesión
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Typography variant="body2" gutterBottomhy>
                      ¿Olvidó su{" "}
                      contraseña
                      <Link href="/request-password">contraseña</Link>?
                    </Typography>
                  </Grid> */}
                </Grid>
              </form>
            </Paper>
          )}
        </Grid>
        <Backdrop
          styles={{ color: "#fff" }}
          open={this.state.openLoadingScreen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPasswordTooOld: state.auth.isPasswordTooOld,
  redirectTo: state.auth.redirectTo,
});

Login = connect(mapStateToProps, {
  login,
  loggedIn,
  loadUserData,
  loadcompanyarray,
  loadcompanytypearray,
  loaddocumentaggregateddata,
  loaddocumentformatarray,
  loaddocumentoverviewdata,
  loaddocumentspeciesarray,
  loadentityarray,
  loadidtypearray,
  loadinvoicedetailsdata,
  loadinvoicetypearray,
  loadliterdetailsdata,
  loadsupplierarray,   
})(Login);

export default reduxForm({
  form: "loginForm",
  validate: validateLoginForm,
})(Login);
