import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteWithLayout } from '../../components';

const PrivateRoute = ({ component: Component, layout: Layout, auth, user_access_level, userinfo, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isLoading) {
        return <div>Loading...</div>;
      } 
      else if (!auth.isAuthenticated) {
        return <Redirect to='/login' />;
      }
      else if (!isAccessibleByUser(user_access_level, userinfo)) {
        return <Redirect to='/not-found' />;
      }
      else {
        if (Layout) {
          return <RouteWithLayout
            component={Component}
            exact
            layout={Layout}
            {...props}
          />
        }
        else {
          return <Component {...props} />;
        }
      }
    }}
  />
);

const isAccessibleByUser = (user_access_level, userinfo) => {



  if (!user_access_level)
    return true;

    if (userinfo.view_only && !userinfo.is_admin && user_access_level.view_only && !user_access_level.is_admin) {
      return true;
    }

  if (userinfo.view_only || user_access_level.view_only) {
    return false;
  }

  if (!userinfo.view_only && !user_access_level.view_only) {
    return true;
  }

  if (user_access_level.is_admin && userinfo.is_admin && true) {
    return true;
  }
  if (user_access_level.is_global && userinfo.is_global && true) {
    return true;
  }
  if (user_access_level.is_local && userinfo.is_local && true) {
    return true;
  }
  return false;
}

const mapStateToProps = state => ({
  auth: state.auth,
  userinfo: state.data.userDataArray,
});

export default connect(mapStateToProps)(PrivateRoute);