import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
} from 'common/constants/notifications';

export const notifySuccess = (message) => async (dispatch) => {  

  dispatch({
    type: ENQUEUE_SNACKBAR,
    notification: {
      message: message,
      options: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: 'success',
        key: new Date().getTime() + Math.random(),
      },
    },
    key: new Date().getTime() + Math.random(),
  });
};

export const notifyError = (message) => async (dispatch) => {  

    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        message: message,
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
          key: new Date().getTime() + Math.random(),
        },
      },
      key: new Date().getTime() + Math.random(),
    });
  };

export const enqueueSnackbar = (notification) => async (dispatch) => {
  const key = notification.options && notification.options.key;  
  dispatch({
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  });
};

export const closeSnackbar = (key) => async (dispatch) =>
  dispatch({
    type: CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
  });

export const removeSnackbar = (key) => async (dispatch) =>
  dispatch({
    type: REMOVE_SNACKBAR,
    key,
  });
