import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import {
  Grid,  
} from "@material-ui/core";
import {
  changeCompanyType,
  changeEntity,
  changeIdType,
} from "common/actions/components";
import validateCompanyForm from "../validator";
import { renderTextField, renderSelectField } from './helper'

export class CompanyDataSection extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      selectedCompanyType: "",
    };
  } 


  render() {
    return (
      <Fragment>
      <h1>{this.state.selectedCompanyType}</h1>
      <Grid item xs={12}>
        <Field
          name="entity"
          type="select"
          component={renderSelectField}
          label="Entidad"
          style={{ width: 250 }}
          defaultValue={this.props.initialValues.entity}
          handleChange=
          {(event)=> {
            return this.props.changeEntity(event.target.value)}}
          data={this.props.data.entityArray}
          disabled={this.props.mode === 'edit'}
          dataField={"value"}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="type"
          type="select"
          component={renderSelectField}
          label="Tipo cliente"
          style={{ width: 250 }}
          data={this.props.data.companyTypeArray}          
          defaultValue={this.props.initialValues.type}
          disabled={this.props.initialValues.is_closed}
          handleChange={(event)=> {
            return this.props.changeCompanyType(event.target.value)}}
        />
      </Grid>
      {this.props.valuesEntityCompany.companyTypeId ===1 && this.props.mode != 'edit' && <Grid item xs={12}>
        <Field
          name="name"
          type="text"
          component={renderTextField}
          label="Nombre Empresa"
          style={{ width: 250 }}
        />
      </Grid>}
      {this.props.initialValues.type ===1 && this.props.mode === 'edit' && <Grid item xs={12}>
        <Field
          name="name"
          type="text"
          component={renderTextField}
          label="Nombre Empresa"
          style={{ width: 250 }}
          disabled={this.props.initialValues.is_closed}
        />
      </Grid>}

      <Grid item xs={12}>
        <Field
          name="id_type"
          type="select"
          component={renderSelectField}
          disabled={this.props.initialValues.is_closed}
          label="Tipo de documento"
          data={this.props.data.idTypeArray}
          defaultValue={this.props.initialValues.id_type}
          handleChange=
          {(event)=> {
            return this.props.changeIdType(event.target.value)}}
          style={{ width: 250 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="id_number"
          type="text"
          disabled={this.props.initialValues.is_closed}
          component={renderTextField}
          label="Documento Cliente"
          style={{ width: 250 }}
        />
      </Grid>
      {/* TODO Case should be applied not on IDs, since they can change */}
      {this.props.valuesEntityCompany.companyTypeId === 2 &&  this.props.mode != 'edit' && (
        <Grid item xs={12}>
          <Field
            name="comercial_name"
            type="text"
            component={renderTextField}
            label="Nombre Comercial"
            style={{ width: 250 }}
          />
        </Grid>
      )}

{this.props.initialValues.type === 2 &&  this.props.mode === 'edit' && (
        <Grid item xs={12}>
          <Field
            name="comercial_name"
            type="text"
            disabled={this.props.initialValues.is_closed}
            component={renderTextField}
            label="Nombre Comercial"
            style={{ width: 250 }}
          />
        </Grid>
      )}
    </Fragment>
    );
  }
}

CompanyDataSection.propTypes = {  
  mode: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  data: state.data,  
  valuesEntityCompany: state.valuesEntityCompany,    
});

CompanyDataSection = connect(mapStateToProps, {  
  changeCompanyType,
  changeIdType,
  changeEntity,
})(CompanyDataSection);

export default reduxForm({
  form: "companyForm",
  validate: validateCompanyForm,
  enableReinitialize: true,
})(CompanyDataSection);
