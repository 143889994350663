import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(4),
    //textAlign:"center",
    //width: '100%',
    height: '24px',
    margin: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute', //Here is the trick
    //bottom: 40, //Here is the trick
  }
}));

const Footer = props => {
  const { className,} = props;
  const rest = {};
  for (const key of Object.keys(props)) {
    if (!['className'].includes(key)) {
      rest[key] = props[key];
    }
  }

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
        {/*<p>
          <small>El PODERDANTE, manifiesta que ha realizado compras de vehículos afectados por la infracción del derecho de la competencia a que se refieren la resolución de la CNMC julio de 2015 (expediente S/0482/13), y en consecuencia autoriza y apodera a CLASS ACCELERATOR, SL<sup>1</sup> para que (sin que CLASS ACCELERATOR asuma ninguna responsabilidad) reclame extrajudicialmente una indemnización a las marcas infractoras y a las demás entidades que puedan ser responsables, en representación y por cuenta del firmante.</small>
        </p>
        <br />
        <p>
          <small>De conformidad con las leyes y los reglamentos relativos a la protección y privacidad de los datos (las «Leyes de Protección de Datos»), incluyendo, sin 
            limitación, la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante, LOPDGDD), el Reglamento
             (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de
              datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (el «RGPD»); 
            le informamos que clicando en el botón SI AUTORIZO sus datos personales (NOMBRE Y APELLIDOS-DENOMINACIÓN SOCIAL, DNI/CIF, DOMICILIO COMPLETO, PROVINCIA)) serán 
            transferidos a la mercantil CLASS ACCELERATOR, SL y a sus empresas relacionadas, siendo ésta el responsable de los datos en relación con dichos datos personales.
          En consecuencia, CLASS ACCELERATOR y sus empresas relacionadas usarán los datos personales proporcionados por Vd. para: iniciar una reclamación extrajudicial en su 
          nombre contra las marcas infractoras y cualquier otra entidad relacionada con el denominado Cartel de los coches.
          Respecto a CLASS ACCELERATOR podrá ejercer en cualquier momento sus derechos reconocidos en las Leyes de Protección de Datos (acceso, rectificación,
           cancelación/supresión, oposición, limitación y portabilidad) en la siguiente dirección: Calle Islas de Cabo Verde número 90, Madrid (28035)
</small>
        </p>
        <br />
        <p>
          <small> <sup>1</sup> Inscrita en el Registro Mercantil de Madrid en el volumen 42.750, página 120, hoja M-755.976, con domicilio social en la calle Islas de Cabo 
                Verde número 90, Madrid (28035), y con CIF B67745778</small>
          </p>*/}
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
