/* eslint-disable react/no-set-state */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Table from 'components/SimpleTable';
// import { createData } from 'functions/fetchApi';
// import { setDeleteFlagQuery } from './queries';
import CompanyDialog from './dialog/CompanyDialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import { softDelete } from 'common/actions/documentData';
import { updateCompany } from 'common/actions/data';
import { hidehelp } from 'common/actions/components';

class TableCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openUploadDocumentDialog: false,
      columns: new Map([
        ['id', { name: 'ID', filter: 'default' }],
        ['entity_name', { name: 'Territorial', filter: 'default' }],
        ['name', { name: 'Nombre', filter: 'default' }],
        [
          'has_uploaded_all_invoices',
          { name: 'Facturas', filter: 'default', customRender: 'Checkbox' },
        ],
        [
          'has_contract',
          {
            name: 'Contrato',
            filter: 'default',
            customRender: 'Checkbox',
            disabled: true,
          },
        ],
        [
          'has_liter_summary',
          {
            name: 'Resumen de litros',
            filter: 'default',
            customRender: 'Checkbox',
            disabled: true,
          },
        ],
        [
          'has_questionnaire',
          {
            name: 'Cuestionario',
            filter: 'default',
            customRender: 'Checkbox',
            disabled: true,
          },
        ],

        [
          'has_paid',
          {
            name: 'Pago del euro',
            filter: 'default',
            customRender: 'Checkbox',
            disabled: true,
          },
        ],
        [
          'has_additional_doc',
          {
            name: 'Documentación adicional',
            filter: 'default',
            customRender: 'Checkbox',
            disabled: true,
            onChangeCallback: this.handleOnChangeCheckboxCallback,
          },
        ],
        [
          'is_closed',
          {
            name: 'Cerrar cliente',
            filter: 'default',
            customRender: 'Button',
            action: true,
            onChangeCallback: this.props.useActionIcon == true
                ? this.handleIsClosedChangeCallback
                : this.handleIsClosedChangeCallbackError,
            textCallback: this.handleTextChangeCallback,
          },
        ],
        [
          'status',
          {
            name: 'Estado de los documentos',
            filter: 'default',
            customRender: 'Tagg',
            tagStyle: {
              ok: { text: 'Documentos validados', color: 'rgb(103, 237, 14)' },
              fail: { text: 'Documentos con incidencias', color: 'rgb(237, 62, 14)' },
              toValidate: { text: 'Revisión en curso', color: 'rgb(237, 222, 14)' },
              open: { text: 'Pendiente del cierre del cliente', color: 'rgb(236, 240, 235)' },
            },
          },
        ],
      ]),
      loaded: false,
      placeholder: 'Cargando datos',
    };
  }

  evaluateStatus(row) {
    let status = ''
    if (!row.is_closed) {
      status = 'open';
    }
    else if (row.is_closed && !row.is_verified && !row.comments) {
      status = 'toValidate';
    } else if (row.is_verified) {
      status = 'ok';
    }
    else if (row.comments){
      status = 'fail';
    }
    return status;
  }

  mapFetchResults = (results) => {
    const data = [];
    for (const result of results) {
      let row = {
        id: result.id,
        name: result.value,
        entity_name: result.entity__name,
        has_uploaded_all_invoices: result.has_uploaded_all_invoices,
        has_contract: result.has_contract > 0,
        has_liter_summary: result.has_liter_summary > 0,
        has_questionnaire: result.has_questionnaire > 0,
        has_paid: result.has_paid > 0,
        has_additional_doc: result.has_additional_doc > 0,
        is_closed: result.is_closed,
        is_verified: result.is_verified,
        entity: result.entity,
        type: result.type,
        id_type: result.id_type,
        id_number: result.id_number,
        comercial_name: result.comercial_name,
        first_name: result.first_name,
        last_name_1: result.last_name_1,
        last_name_2: result.last_name_2,
        street_type: result.street_type,
        street_name: result.street_name,
        floor_apt_no: result.floor_apt_no,
        postal_code: result.postal_code,
        city: result.city,
        province: result.province,
        country: result.country,
        comments: result.comments,
      };

      row.has_invoices = false;
      row.status = this.evaluateStatus(row)
      row.name = row.name !==''?row.name:`${row.first_name} ${row.last_name_1} ${row.last_name_2}`
      data.push(row);
    }
    return data;
  };

  handleOnChangeCheckboxCallback = (row) => { };

  handleIsClosedChangeCallbackError = (row, value) => {
    let resultMsg = { title: 'Cerrar cliente' };
    resultMsg.ok = false;
    resultMsg.text = (
      <div>
        <div style={{ marginBottom: '10' }}>
          <p>
            Permisos insuficientes
          </p>
        </div>
      </div>
    );

    return resultMsg;
  }

  handleIsClosedChangeCallback = (row, value) => {
    let resultMsg = { title: 'Cerrar cliente' };
    //Client closed want to open
    // if (value === false) {
    //   resultMsg.ok = true;
    //   resultMsg.text = 'Se ha abierto el cliente';
    // } else
    

      const result = this.props.data.filter(dato => dato.id  == row.id);

   
      if( result[0].is_verified === true){
        let resultMsg = { title: 'Revisado' };
        resultMsg.ok = true;
        resultMsg.text = 'No se puede abrir un cliente revisado';
        return resultMsg

      }else{

        if (row.is_closed === false) {
          if (
            row.has_contract &&
            row.has_uploaded_all_invoices &&
            row.has_liter_summary &&
            row.has_paid 
            //row.has_questionnaire
          ) {
            row.is_closed = true;
            this.props.updateCompany(row);
            resultMsg.ok = true;
            resultMsg.text = 'Se ha cerrado el cliente';
          } else {
            resultMsg.ok = false;
            resultMsg.text = (
              <div>
                <div style={{ marginBottom: '10' }}>
                  <p>
                    Para poder cerrar un cliente debe marcar las casillas
                    correspondientes a:
                  </p>
                  <p>
                    Facturas, para indicar que se han subido todas las facturas del
                    cliente.
                  </p>
                  <p>Pago del euro</p>
                </div>
                <div>
                  <p>Subir los documentos siguientes:</p>
                  <p>Contrato</p>
                  <p>Resumen de litros</p>
                  <p>Cuestionario</p>
                  <p>Documentación adicional</p>
                  <p>Pago del Euro</p>
                </div>
              </div>
            );
          }
        } else {
          row.is_closed = false;
          this.props.updateCompany(row);
          resultMsg.ok = true;
          resultMsg.text = 'Se ha abierto el cliente';
        }
      }
  

    return resultMsg;
  };

  handleTextChangeCallback = (value) => {
    if (value === true) return 'Abrir cliente';
    else return 'Cerrar cliente';
  };

  handleOpenUploadFileDialog = () => {
    this.props.hidehelp();
    this.setState(() => {
      return {
        openUploadDocumentDialog: true,
      };
    });
  };

  handleCancelUploadFileDialog = () => {
    this.setState(() => {
      return {
        openUploadDocumentDialog: false,
      };
    });
  };

  handleSaveUploadFileDialog = (e) => {
    this.setState(() => {
      return {
        openUploadDocumentDialog: false,
      };
    });
  };

  handleDeleteRow = (row) => {
    // const query = setDeleteFlagQuery(row['ID archivo']);
    // createData(query);
    // this.props.softDelete(row['ID archivo']);
  };

  handleCancelAddCompanyDialog = () => {
    this.setState(() => {
      return {
        openAddCompanyDialog: false,
      };
    });
  };
  handleOpenAddCompanyDialog = () => {
    this.setState(() => {
      return {
        openAddCompanyDialog: true,
      };
    });
  };

  handleEditData = (data) => { };

  render() {
    return (
      <Fragment>
        <div>
          <Table
            actionIcon={this.props.useActionIcon == true ? AddCircleOutlineIcon:undefined}
            viewOnly = {!this.props.useActionIcon}
            actionIconTooltip="Añadir clientes"
            data={this.mapFetchResults(this.props.data)}
            onClick={this.handleOpenAddCompanyDialog}
            columns={this.state.columns}
            title="Clientes"
            standardActions={[{ name: 'edit' }]}
            editComponent={CompanyDialog}
            onEditRow={this.handleEditData}
            deleteAction={false}
            onDeleteRow={this.handleDeleteRow}
            titleConfirmDeleteRow={'Eliminar cliente'}
            textConfirmDeleteRow={
              '¿Está seguro de que desea eliminar el cliente?'
            }
            skipOtherColumns={true}
          />
        </div>
        <CompanyDialog
          onCloseDialog={this.handleCancelAddCompanyDialog}
          onCancel={this.handleCancelAddCompanyDialog}
          onSave={this.handleSaveUploadFileDialog}
          open={this.state.openAddCompanyDialog}
          initialValues={{}}
          mode={'create'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data.companyArray,
});

TableCompany = connect(mapStateToProps, {
  hidehelp,
  updateCompany,
  // softDelete
})(TableCompany);

export default TableCompany;
