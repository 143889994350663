const validateUserForm = (values) => {  
  const errors = {};  
  if (!values.id_number) {
    errors.id_number = 'Campo obligatorio';
  } else if (!isValidDNI(values.id_number)) {
    errors.id_number = 'Documento no válido';
  }
  const onlyLettersRexp = /^[A-Za-zÀ-ÖØ-öø-ÿ -]{1,50}$/i;
  const emailRexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const strongRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;

  if (!values.first_name) {
    errors.first_name = 'Campo obligatorio';
  } else if (!onlyLettersRexp.test(values.first_name)) {
    errors.first_name = 'El nombre no tiene un formato correcto';
  }
  if (!values.last_name_1) {
    errors.last_name_1 = 'Campo obligatorio';
  } else if (!onlyLettersRexp.test(values.last_name_1)) {
    errors.last_name_1 = 'El primer apellido no tiene un formato correcto';
  }
  if (!values.last_name_2) {
    errors.last_name_2 = 'Campo obligatorio';
  } else if (!onlyLettersRexp.test(values.last_name_2)) {
    errors.last_name_2 = 'El segundo apellido no tiene un formato correcto';
  }
  
  if (!values.id_type) {
    errors.id_type = 'Ups... Select please!!!';
  }
  
  if (!values.email) {
    errors.email = 'Campo obligatorio';
  } else if (!emailRexp.test(values.email)) {
    errors.email = 'La dirección de correo no tiene formato correcto';
  }

  if (!values.password) {
    errors.password = 'Campo obligatorio';
  } else if (!strongRegex.test(values.password)) {
    errors.password = 'Utilice una contraseña de al menos 8 simbolos [a-z A-Z 0-9]';
  }
  if (!values.confirmpassword) {
    errors.confirmpassword = 'Campo obligatorio';
  } else if (values.confirmpassword !== values.password) {
    errors.confirmpassword = 'Las contraseñas no coinciden';
  }
  
  if (!values.terms) {
    errors.terms = 'Debe aceptar los términos y condiciones';
  }

  return errors;
};

const isValidDNI = (dni) => {
  var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
  var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
  var str = dni.toString().toUpperCase();

  if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

  var nie = str
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2');

  var letter = str.substr(-1);
  var charIndex = parseInt(nie.substr(0, 8)) % 23;

  if (validChars.charAt(charIndex) === letter) {
    return true;
  }

  return false;
};

export default validateUserForm;
