import React, { Component, Fragment } from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,  
  Grid
} from "@material-ui/core";
import If, { Else } from 'if-else-react';
export default class KpiCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setNumberFormat(number) {
    if (!number) {
      number = 0;
    }
    var nround = Math.round(number);
    const ndigit = nround.toString().length;
    var res = null;
    var suffix = "";
    if (this.props.unittype) {
      suffix = this.props.unittype;
    }
    var digits = null;
    if (this.props.decimalScale) {
      digits = this.props.decimalScale;
    }

    // digits;
    if (ndigit > 9) {
      res = (nround / 1000000000).toFixed(digits).concat(" B ", suffix);
    }
    if (ndigit <= 9 && ndigit > 6) {
      res = (nround / 1000000).toFixed(digits).concat(" M ", suffix);
    }
    if (ndigit <= 6 && ndigit > 3) {
      res = (nround / 1000).toFixed(digits).concat(" K ", suffix);
    }
    if (ndigit <= 3) {
      res = number.toFixed(digits).concat(" ", suffix);
    }
    return res;
  }

  render() {
    return (
      <Fragment>
        <Card elevation={3} alignContent="center" style={{vw: 600, height:160}}>
          <CardHeader            
            title={
              <Typography variant="h3" style={{ textAlign: 'center'}} >
                {this.props.kpiTitle}
              </Typography>
            }
          />
          <CardContent style={{marginTop: -20}}>
              <Grid container alignContent="center" style={{ padding: 2 }}>
                <Grid item xs={12} >
                <Typography variant="h5" style={{ textAlign: 'center', color: '#7E79B0' }} >
                    {this.props.kpiMainTitle}
                </Typography>
                <Typography variant="h4" style={{ textAlign: 'center', color: '#7E79B0' }} >
                    {this.setNumberFormat(this.props.kpiMain)}
                </Typography>
                </Grid>
                {<Grid item xs={6}>
                 <Typography variant="h6" style={{ textAlign: 'center', color: '#7E79B0' }}>
                 <If condition={this.props.kpiSub1Title }>    
                    {this.props.kpiSub1Title}
                    </If>
                </Typography>
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                <If condition={this.props.kpiSub1Title }>    
                    {this.setNumberFormat(this.props.kpiSub1)}
                    </If>
                    
                </Typography>
                </Grid>}
                {<Grid item xs={6}>
                <Typography variant="h6" style={{ textAlign: 'center', color: '#7E79B0' }}>
                <If condition={this.props.kpiSub2Title }>    
                    {this.props.kpiSub2Title}
                    </If>
                </Typography>
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                <If condition={this.props.kpiSub2Title }>    
                    {this.setNumberFormat(this.props.kpiSub2)}
                    </If>
                </Typography>
                </Grid>}
              </Grid>            
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}