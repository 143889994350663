import React from 'react';
import {Card, CardContent, Grid, Box, CardHeader } from "@material-ui/core";
import { PieChart } from 'components/Charts';

const Litros = ({numeroLitros, datosSuper, datosProvider, colorsProvider}) => {
    return (
        <Box style = {{height: '100%'}}>
            <Grid container spacing={1}>
                <Grid item xs={6} >
                    <Card elevation={12} alignContent="center" style={{height: "inherit"}}>
                        <CardHeader title={"Litros declarados: " +  numeroLitros.toLocaleString('de-De', { minimumFractionDigits: 0 }) } />
                        <CardContent style={{marginTop: -20}}>
                            <PieChart
                                data={ datosSuper }
                                title=""
                                colors={['#7E79B0', '#b1aecf', '#4b4869', '#252434']}
                            />
                        </CardContent>
                    </Card>
                    
                </Grid>
                <Grid item xs={6} >
                    <Card elevation={12} alignContent="center" style={{height: "inherit"}}>
                        <CardHeader title={"Litros declarados por proveedor"} />
                        <CardContent style={{marginTop: -20}}>
                            <PieChart
                                data={ datosProvider }
                                title=""
                                colors={ colorsProvider }
                            /> 
                        </CardContent>
                    </Card>
                    
                </Grid>
            </Grid>
        </Box>
	);
};

export default Litros;