//import { ContactPhoneSharp } from '@material-ui/icons';
import {
  mapReduceChartDatasets,
  calcCount,
  calcSum,
} from 'functions/mapChartData';

export function composeFuelChartDatasets(dataSet, filter_field) {
  
  let filter_fields = [];

  if (filter_field === 'total') {
    filter_fields.push('sum_liter_own_deposits');
    filter_fields.push('sum_liter_service_station');
  } else {
    filter_fields.push(filter_field);
  }

  let data = mapReduceChartDatasets(
    dataSet,
    ['year', 'supplier__value'],
    filter_fields,
    'supplier__value',
    calcSum
  ); 

  let dataset_result = mapReduceChartDatasets(
    data,
    ['label'],
    ['value'],
    'label'
  ); 

  dataset_result = dataset_result.map((obj) => {
    return {
      label: obj.label,
      data: obj.value,
      color: getFuelChartSupplierColor(obj.label),
    };
  });

  return dataset_result;
}

export function mapSupplier(name) {
    let agrupado = '';

    if (name == 'REPSOL' || name == 'SOLRED') {
        agrupado = "REPSOL/SOLRED";
    } else if (name == 'CEPSA' || name == 'RESSA') {
        agrupado = "CEPSA/RESSA";
    } else if (name == 'BP') {
        agrupado = "BP";
    } else {
        agrupado = "OTROS";
    }

    return agrupado;
}

 

export function composeToltalpriceChartDatasetsAbby(dataSet, suppliers, years) { 
  let data=[];
  
  let valuesUsed = [];
  for (let supplier = 0; supplier < suppliers.length; supplier++) {
    var tempobjt=[]; 

    if (! valuesUsed.includes(mapSupplier(suppliers[supplier].vendor_alias))) {
        valuesUsed.push(mapSupplier(suppliers[supplier].vendor_alias));
        tempobjt =  dataSet.filter(function(data) { 
            return mapSupplier(data.vendor_alias) == mapSupplier(suppliers[supplier].vendor_alias);
        });

        var obj={};
        obj.label= mapSupplier(suppliers[supplier].vendor_alias);
        obj.data=[];
        var sum = 0;
        for (let y = 0; y < years.length; y++) { 
            var sum = 0; 
            for (let x = 0; x < tempobjt.length; x++) { 
            
            if(tempobjt[x].year == years[y] ){
                sum = parseFloat(sum) + parseFloat(tempobjt[x].total_price);  
            } 
            } 
        obj.data.push(sum.toFixed(0));
        }  
      
        obj.color=  getFuelChartSupplierColorAbbyy(obj.label);
      
        data.push(obj);
    }
  }

  return data; 
}


export function composeFuelChartDatasetsAbby(dataSet, suppliers, years) { 
  let data=[];
  
  let valuesUsed = [];
  for (let supplier = 0; supplier < suppliers.length; supplier++) {
    var tempobjt=[]; 

    if (! valuesUsed.includes(mapSupplier(suppliers[supplier].vendor_alias))) {
        valuesUsed.push(mapSupplier(suppliers[supplier].vendor_alias));
        tempobjt =  dataSet.filter(function(data) { 
            return mapSupplier(data.vendor_alias) == mapSupplier(suppliers[supplier].vendor_alias);
        });

        var obj={};
        obj.label= mapSupplier(suppliers[supplier].vendor_alias);
        obj.data=[];
        var sum = 0;
        for (let y = 0; y < years.length; y++) { 
            var sum = 0; 
            for (let x = 0; x < tempobjt.length; x++) { 
            
            if(tempobjt[x].year == years[y] ){
                sum = parseFloat(sum) + parseFloat(tempobjt[x].total_liters);  
            } 
            } 
        obj.data.push(sum.toFixed(0));
        }  
        obj.color=  getFuelChartSupplierColorAbbyy(obj.label);
        data.push(obj);
    }
  }
  return data; 
}






export function composelitersChartDatasetsAbby(dataSet, provinces, years) { 
  let data=[];
  console.log("hooooooooooola")
 
  for (let province = 0; province < provinces.length; province++) {
    var tempobjt=[]; 

        tempobjt =  dataSet.filter(function(data) { 
            return data.client_province == provinces[province].client_province;
        });

        var obj={};
        obj.label= provinces[province].client_province;
        obj.data=[];
        var sum = 0;
        for (let y = 0; y < years.length; y++) { 
            var sum = 0; 
            for (let x = 0; x < tempobjt.length; x++) { 
            
            if(tempobjt[x].client_province == years[y] ){
                sum = parseFloat(sum) + parseFloat(tempobjt[x].total_liters);  
            } 
            } 
        obj.data.push(sum.toFixed(0));
        }  
       
        data.push(obj);
    
  }
  return data; 
}

export function composeFuelChartDatasetsDateAbby(dataSet) { 

  const date = [... new Set( dataSet.map(x=>x.year))];
  date.sort(function(a, b){return a-b});
  return date; 
}
export function composeLiterProvinceDataAbby(dataSet) { 
  const date = [... new Set( dataSet.map(x=>x.client_province))];
  date.sort(function(a, b){return a-b});
  return date; 
}





function getFuelChartSupplierColor(supplier) {
  switch (supplier) {
    case 'Repsol/Solred':
      return 'rgb(126, 121, 176)';
    case 'Cepsa':
      return 'rgb(177, 174, 207)';
    case 'BP':
      return 'rgb(75, 72, 105)';
    default:
      return 'rgb(37, 36, 52)';
  }
}


function getFuelChartSupplierColorAbbyy(supplier) {
  switch (supplier) {
    case 'REPSOL/SOLRED':
      return 'rgb(255, 130, 0)';
    case 'CEPSA/RESSA':
      return 'rgb(213, 43, 30)';
    case 'BP':
      return 'rgb(0, 127, 0)';
    default:
      return 'rgb(37, 36, 52)';
  }
}
export function composeFuelPieChartData(summaryDetails) {
  let result = calculateLitersSummary(summaryDetails);
  let dataset = [
    {
      label: 'Depósitos propios',
      value: result.sum_liter_own_deposits,
      color: '#a8e0ff',
    },
    {
      label: 'Estaciones de servicio',
      value: result.sum_liter_service_station,
      color: '#8ee3f5',
    },
  ];

  return dataset;
}

export function composeSuppliersChartData(invoiceDetails) {
  //Group by supplier name
  let suppliersChartData = mapReduceChartDatasets(
    invoiceDetails,
    ['supplier__value'],
    ['count_invoice'],
    'supplier__value',
    calcSum
  );
  return suppliersChartData;
}
export function graphAbbyyCompose(data) {
  return data;
}
 
export function composeInvoicesChartDataAbbyy(dataSet ,provinceArrayAbbyy) {
 
  let data=[];
  
  for (let province = 0; province < provinceArrayAbbyy.length; province++) {
    var tempobjt=[]; 
      tempobjt =  dataSet.filter(function(data) { 
        return data.client_province ==provinceArrayAbbyy[province].client_province;
    });
    var obj={};
    obj.label= provinceArrayAbbyy[province].client_province;
    obj.value=[];
    var sum = 0;
    for (let y = 0; y < tempobjt.length; y++) { 
        
         
       sum = parseFloat(sum) + parseFloat(tempobjt[y].total_liters);  
        
     
     
    } 
    obj.value.push(sum.toFixed(0)); 
    data.push(obj);
  }
  return data;
   
}

export function composeLitreChartDataAbbyy(dataSet ,provinceArrayAbbyy) {
 
  let data=[];
  
  for (let province = 0; province < provinceArrayAbbyy.length; province++) {
    var tempobjt=[]; 
      tempobjt =  dataSet.filter(function(data) { 
        return data.client_province ==provinceArrayAbbyy[province].client_province;
    });
    var obj={};
    obj.label= provinceArrayAbbyy[province].client_province;
    obj.value=[];
    var sum = 0;
    for (let y = 0; y < tempobjt.length; y++) { 
        
         
       sum = parseFloat(sum) + parseFloat(tempobjt[y].total_price);  
        
     
     
    } 
    obj.value.push(sum.toFixed(0)); 
    data.push(obj);
  }
  return data;
   
}
export function composeStateDocumentstDataAbbyy(dataSet ,stateArrayAbbyy) {
 
  console.log("d")
  let datos=[];

  for (let data = 0; data < dataSet.length; data++) {
    
    if(dataSet[data].name == "Procesado inicial" || dataSet[data].name == "Subido a la plataforma" || dataSet[data].name == "Documentación validada" || dataSet[data].name == "Procesado por Abbyy"){
     
      var obj={};
      obj.label=dataSet[data].name;
      obj.value=[];
     
    
      obj.value.push(dataSet[data].result[0]); 
      datos.push(obj);
    }
    
  }
  return datos;
   
}

export function composeStateInvoiceAbbyy(dataSet ) {
  
  
  console.log("d")
  let datos=[];

  for (let data = 0; data < dataSet.length; data++) {
    
    if(dataSet[data].name == "total_inv_exported"){
     
      var obj={};
      obj.label= "Exportación automática";
      obj.value=[];
     
    
      obj.value.push(dataSet[data].result[0]); 
      datos.push(obj);
    }else if(dataSet[data].name == "total_inv_verification"){
      var obj={};
      obj.label= "En verificación";
      obj.value=[];
     
    
      obj.value.push(dataSet[data].result[0]); 
      datos.push(obj);
    }
    
  }
  return datos;
   
}





export function composeInvoicesChartDataset(invoiceDetails) {
  //Filter by factura
  let dataset = [
    {
      title: 'Facturas',
      data: mapReduceChartDatasets(
        invoiceDetails,
        ['year'],
        ['count_invoice'],
        'year',
        calcSum
      ),
      color: '#a8e0ff',
    },
  ];

  return dataset;
}

export function calculateLitersSummary(summaryDetails) {
  let summary = {
    sum_liter_own_deposits: 0,
    sum_liter_service_station: 0,
  };

  if (summaryDetails.length > 0) {
    summary = summaryDetails.reduce((total, obj) => {
      total['sum_liter_own_deposits'] =
        (total['sum_liter_own_deposits'] || 0) + obj.sum_liter_own_deposits;
      total['sum_liter_service_station'] =
        (total['sum_liter_service_station'] || 0) +
        obj.sum_liter_service_station;
      return total;
    }, {});
  }
  summary['total'] =
    summary.sum_liter_own_deposits + summary.sum_liter_service_station;
  return summary;
}
export function calculateSummaryDetailsAbby(graph, totaldocuments, funnel) {
 
  let summary = {
    total_pagesnumber_rejected: 0,
    total_pagesnumber_valid:0,
    total_invoices_rejected: 0,
    total_invoices_valid:0,
    total_documents: 0,
    total_liters: 0,
    total_price: 0,
    total_valid: 0,
    total_invalid: 0,
  };
  
 
  for (var i = 0, max = graph.length; i < max; i += 1) {
   
    
    summary.total_liters= summary.total_liters +  graph[i].total_liters;
    summary.total_price= summary.total_price +  graph[i].total_price;
    if(graph[i].invoice_status == "Valid"){
      summary.total_valid= summary.total_valid + 1;
    }else{
      summary.total_invalid= summary.total_invalid +  1;
    }
    
     
   
  } 
  console.log("adsfasdfasdf")
  summary.total_invoices_rejected= parseInt(funnel[11].result[0]);
  summary.total_invoices_valid= parseInt(funnel[12].result[0]);
  summary.total_pagesnumber_rejected=parseInt(funnel[11].result[1]);
  summary.total_pagesnumber_valid=parseInt(funnel[12].result[1]);
  summary.total_documents= parseInt(funnel[4].result[0])
  
  return summary;
} 


export function calculatePageNumber(summaryDetails) {
  let summary = {
    total_pagesnumber: 0,
    total_invoices: 0
  };
   

  if (summaryDetails.length > 0) {
    summary = summaryDetails.reduce((total, obj) => {
      total['pagesnumber'] = (total['pagesnumber'] || 0) + obj.pagesnumber;
  
      return total;
    }, {});
  }
  summary['total_pagesnumber'] = summary.pagesnumber;
  summary['total_invoices'] = summaryDetails.length;

  return summary;
}

export function calculateCompanysSummary(companysDetails) {
  let summary = companysDetails.reduce((total, obj) => {
    if (obj.is_closed) {
      total['total_closed'] = (total['total_closed'] || 0) + 1;
    } else {
      total['total_opened'] = (total['total_opened'] || 0) + 1;
    }
    return total;
  }, {});
  summary['total'] = (summary.total_closed || 0) + (summary.total_opened || 0);
  return summary;
}
//TODO
export function calculateInvoicesSummary(invoiceDetails){
  let summary = {
    total_inspected: 0,
    total_processed: 0,
    total: invoiceDetails.reduce((total, obj) => total += obj.count_invoice,0)
  }
 

  return summary;
}